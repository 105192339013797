import { SelectorNames } from "@/components/FiltersPanel/consts";
import { SelectorDef } from "@/components/FiltersPanel/types";
import { DataState } from "@/types/dataContext";

export const selectorsConfig: Array<SelectorDef> = [
    { name: SelectorNames.Merchant },
    { name: SelectorNames.Focus, dependentFilters: [SelectorNames.Products, SelectorNames.ParentBrand, SelectorNames.SubBrand], defaultValue: 0 },
    { name: SelectorNames.ParentBrand, dependentFilters: [SelectorNames.Products, SelectorNames.SubBrand]},
    { name: SelectorNames.SubBrand, dependentFilters: [SelectorNames.Products]},
    { name: SelectorNames.Products },
    { name: SelectorNames.RevStatus }
  ];
  
  export const dependentAPIs: (keyof DataState)[] = [
    "ratingsAndReviewsApi",
    "reviewSummaryApi",
  ];
