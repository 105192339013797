import { HeadCell } from "@/components/tables/AdvancedTable";
import { AnalyticsRequestBody } from "./types";
import { useTranslation } from "react-i18next";

export enum Breakpoints {
  XLarge = 1800,
  Large = 1700,
  MediumLarge = 1640,
  Medium = 1500,
  SmallMedium = 1360,
  Small = 1100,
}

export enum SelectorNames {
  Manufacturer = "manufacturer",
  Merchant = "merchant",
  Brand = "brand",
  Subbrand = "subbrand",
  Products = "products",
  Source = "source",
  Medium = "medium",
  Campaign = "campaign",
  Term = "term",
  Content = "content",
  Device = "device",
  Focus = "focus",
  Url = "url",
  Seller = "seller",
}

export enum AnalyticHeadCellIds {
  Leads = "LEADS",
  CR = "CR_numeric",
}

export const selectorCountBreakpoints = [
  { value: Breakpoints.XLarge, count: 8 },
  { value: Breakpoints.Large, count: 7 },
  { value: Breakpoints.MediumLarge, count: 7 },
  { value: Breakpoints.Medium, count: 6 },
  { value: Breakpoints.SmallMedium, count: 5 },
  { value: Breakpoints.Small, count: 4 },
];

export const useProductLeadsCells = (): Array<HeadCell> => {
  const { t } = useTranslation();

  return [
    { id: "LEADS", label: t("tableColumns.leads") },
    { id: "CR_numeric", label: t("tableColumns.CR") },
    { id: "CR", label: t("tableColumns.CR") },
    { id: "POS", label: t("tableColumns.POS") },
    { id: "PRODUCT", label: t("tableColumns.product") },
    { id: "PZN", label: t("tableColumns.PZN") },
  ];
};

export const useProductVisitsCells = (): Array<HeadCell> => {
  const { t } = useTranslation();

  return [
    { id: "visits", label: t("tableColumns.visits") },
    { id: "product", label: t("tableColumns.product") },
  ];
};

export const useBrandVisitsCells = (): Array<HeadCell> => {
  const { t } = useTranslation();

  return [
    { id: "Visits", label: t("tableColumns.visits") },
    { id: "Brand", label: t("tableColumns.brand") },
  ];
};

export const useMerchantsCells = (): Array<HeadCell> => {
  const { t } = useTranslation();

  return [
    { id: "leads", label: t("tableColumns.leads") },
    { id: "CR_numeric", label: t("tableColumns.CR") },
    { id: "conversion_rate", label: t("tableColumns.CR") },
    { id: "merchant", label: t("tableColumns.merchant") },
  ];
};

export const useMerchantsTransactionsCells = (): Array<HeadCell> => {
  const { t } = useTranslation();

  return [
    { id: "transactions", label: "Transactions" },
    { id: "CR_numeric", label: t("tableColumns.CR") },
    { id: "conversion_rate", label: t("tableColumns.CR") },
    { id: "merchant", label: t("tableColumns.merchant") },
  ];
};

export const useProductOpenedCells = (): Array<HeadCell> => {
  const { t } = useTranslation();

  return [
    { id: "LayerOpened", label: t("tableColumns.opened") },
    { id: "CR_numeric", label: t("tableColumns.CR") },
    { id: "CR", label: t("tableColumns.CR") },
    { id: "Brand", label: t("tableColumns.brand") },
  ];
};

export const defaultSelectors: AnalyticsRequestBody = {
  days: 30,
  manufacturer: 387,
  products: "ALL",
  brand: "ALL",
  subbrand: "ALL",
  focus: 0,
  url: "All",
  device: "ALL",
  term: "ALL",
  source: "ALL",
  medium: "ALL",
  campaign: "ALL",
  content: "ALL",
  merchant: "ALL",
  brandname: "",
};

export const GraphTooltip = () => {
  const { t } = useTranslation();
  return (
    <div>
      <p>{t("pages.analytics.tooltip.graphP1")}</p>
      <p>{t("pages.analytics.tooltip.graphP2")}</p>
      <p>{t("pages.analytics.tooltip.graphP3")}</p>
      <p>{t("pages.analytics.tooltip.graphP4")}</p>
      <p>{t("pages.analytics.tooltip.graphP5")}</p>
    </div>
  );
};

export const VisitsTooltip = () => {
  const { t } = useTranslation();
  return (
    <div>
      <p>{t("pages.analytics.tooltip.visitsP1")}</p>
      <p>{t("pages.analytics.tooltip.visitsP2")}</p>
      <p>{t("pages.analytics.tooltip.visitsP3")}</p>
      <p>{t("pages.analytics.tooltip.visitsP4")}</p>
      <p>{t("pages.analytics.tooltip.visitsP5")}</p>
      <p>{t("pages.analytics.tooltip.visitsP6")}</p>
    </div>
  );
};

export const LeadsTooltip = () => {
  const { t } = useTranslation();
  return (
    <div>
      <p>{t("pages.analytics.tooltip.leadsP1")}</p>
      <p>{t("pages.analytics.tooltip.leadsP2")}</p>
      <p>{t("pages.analytics.tooltip.leadsP3")}</p>
      <p>{t("pages.analytics.tooltip.leadsP4")}</p>
      <p>{t("pages.analytics.tooltip.leadsP5")}</p>
      <p>{t("pages.analytics.tooltip.leadsP6")}</p>
      <p>{t("pages.analytics.tooltip.leadsP7")}</p>
      <p>{t("pages.analytics.tooltip.leadsP8")}</p>
      <p>{t("pages.analytics.tooltip.leadsP9")}</p>
    </div>
  );
};
