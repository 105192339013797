import { FC, useEffect, useState } from "react";
import {
  Box,
  Button,
} from "@mui/material";
import CustomAutocomplete from "../CustomAutocomplete";
import { useTranslation } from "react-i18next";
import { AnalyticsRequestBody, FiltersPanelProps, SelectorConfig } from "./types";
import * as styles from "./styles";
import { SelectorNames } from "./consts";
import { useFiltersData } from "@/hooks/useAnalyticsData";
import useData from "@/hooks/auth/useData";
import { FilterList } from "@mui/icons-material";
import { Option } from "@/types/selectorOption";

const FiltersPanel: FC<FiltersPanelProps> = ({
  selectorsConfig,
  dependentAPIs,
  hasMoreButton = true,
  onMoreSelectors = [],
  actionButton = null,
  setValuesForParent,
}) => {
  const { t } = useTranslation();
  const { selectedManufacturers } = useData();

  const { commonSelectorsConfig, currentSelectors } =
    useFiltersData(selectorsConfig, dependentAPIs);

  const visibleSelectors = commonSelectorsConfig.filter(
    (selector) => !onMoreSelectors.includes(selector.name as SelectorNames)
  );

  const moreSelectors = commonSelectorsConfig.filter((selector) =>
    onMoreSelectors.includes(selector.name as SelectorNames)
  );

  const [isMoreVisible, setIsMoreVisible] = useState(false);
  
  const getSelectedOption = (field: string, optionList: Option[]) =>
    optionList.filter(
      (option) =>
        (currentSelectors[field as keyof AnalyticsRequestBody] as (string| number)[])?.includes(option.value)
  );

  useEffect(() => {
    if(setValuesForParent) {
      const isLoading = commonSelectorsConfig.some((csc) => csc.isLoading);
      setValuesForParent(isLoading ? undefined : currentSelectors);
    }
  }, [currentSelectors, commonSelectorsConfig]);
  
  const toggleMore = () => {
    setIsMoreVisible(!isMoreVisible);
  };

  const renderAutocompleteComponent = (
    selector: SelectorConfig,
    index: number,
    selectorsGroupName: string
  ) => {
    const isMerchantSelector = selector.name === SelectorNames.Merchant;
    const isManufacturerSelector = selector.name === SelectorNames.Manufacturer;
    const selectedValue = isManufacturerSelector
      ? [{
          value: selectedManufacturers[0]?.ID.toString() ?? "",
          label: selectedManufacturers[0]?.NAME ?? "",
        }]
      : getSelectedOption(selector.name, selector.options);

    return (
      <CustomAutocomplete
        key={`${selectorsGroupName}-${index}`}
        label={selector.label}
        disabled={selector.disabled}
        labelColor={selector.labelColor}
        defaultValue={selector.defaultValue}
        hasAllOption={selector.hasAllOption}
        allValue={selector.allValue}
        multiSelect={selector.multiSelect}
        isLoading={selector.isLoading}
        value={selectedValue}
        onChange={(value) => selector.handleChange(value)}
        options={selector.options}
        disableClearable={false}
        {...(isMerchantSelector && {
          sorting: (a: Option, b: Option) => {
            if (a.property === "primary" && b.property !== "primary") return -1;
            if (a.property !== "primary" && b.property === "primary") return 1;
            return a.label.localeCompare(b.label);
          },
          groupBy: (option: Option) => {
            if (option.property === "primary") return t("filters.primaryShops");
            if (option.property === "secondary") return t("filters.secondaryShops");
            return "";
          },
          additionalOptions: [{ value: 'allPrimary', label: t('filters.allPrimaryShops'), key: -2 }],
          additionalSelectionOptions: (value, options) => {
            value = Array.isArray(value) ? value : [value];
            if (value.some((v) => v.value === 'allPrimary')) {
              const primaries = options.filter((v) => v.property === 'primary');
              value = Array.from(
                new Map(
                  [
                    ...value.filter((v) => v.value !== 'allPrimary'),
                    ...primaries,
                  ].map((item) => [item.key, item])
                ).values()
              );
            }
            return value;
          }
        })}
      />
    );
  };

  return (
    <Box sx={styles.containerStyles}>
      <Box>
        <Box sx={styles.innerContainerStyles}>
          <Box sx={styles.selectorsBoxStyles}>
            {visibleSelectors.map((selector, index) =>
              renderAutocompleteComponent(selector, index, "mainSelectors")
            )}
          </Box>
          
          <Box sx={styles.moreButtonContainerStyles}>
            {hasMoreButton && moreSelectors.length > 0 && (
              <Button sx={styles.moreButtonStyles} onClick={toggleMore}>
                <Box sx={styles.moreButtonStylesContent}>
                  <FilterList sx={{ height: 20, width: 20 }} />
                  {isMoreVisible
                    ? t("pages.analytics.selectors.less")
                    : t("pages.analytics.selectors.more")}
                </Box>
              </Button>
            )}
            {actionButton}
          </Box>
        </Box>
        {isMoreVisible && (
          <Box sx={{ ...styles.selectorsBoxStyles, paddingTop: "16px" }}>
            {moreSelectors.map((selector, index) =>
              renderAutocompleteComponent(selector, index, "otherSelectors")
            )}
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default FiltersPanel;
