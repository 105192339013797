import { SetupApiType } from "./types";
import { useKeywords } from "./useKeywords";
import { createLabel, updateLabel, useLabels } from "./useLabels";
import { useMarkets } from "./useMarkets";
import { useSetupProduct as useProducts } from "./useProducts";
import {useCategories} from "@/api/setup/useCategories";

const useSetup = (): SetupApiType => {
    const setupLabelsApi = useLabels();
    const setupKeywordsApi = useKeywords();
    const setupCategoriesApi = useCategories();
    const setupMarketsApi = useMarkets();
    const setupProductsApi = useProducts();
    const setupLabelsUpdateApi = updateLabel();
    const setupLabelsCreateApi = createLabel();
    return {
      setupLabelsApi,
      setupKeywordsApi,
      setupCategoriesApi,
      setupMarketsApi,
      setupProductsApi,
      setupLabelsUpdateApi,
      setupLabelsCreateApi,
    }
};

export default useSetup;
