import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import enTranslations from "./locales/en/translation.json";
import esTranslations from "./locales/es/translation.json";
import deTranslations from "./locales/de/translation.json";

const resources = {
  en: {
    translation: enTranslations,
  },
  de: {
    translation: deTranslations,
  },
  es: {
    translation: esTranslations,
  },
};

const language = localStorage.getItem("language") ?? "en";

i18n.use(initReactI18next).init({
  resources,
  lng: language,
  fallbackLng: language,
  interpolation: {
    escapeValue: false,
  },
});
