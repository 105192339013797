import styled from "@emotion/styled";
import React from "react";
import { Helmet } from "react-helmet-async";
import SignInComponent from "../../components/auth/SignIn";
import { ReactComponent as MavenLogo } from "../../assets/svg/logo-simple.svg";

const LogoWrapper = styled.div`
  display: flex;
  width: 125px;
  height: auto;
  margin-bottom: ${(props) => props.theme.spacing(5)};
`;

function SignIn() {
  return (
    <React.Fragment>
      <Helmet title="Sign In" />
      <LogoWrapper>
        <MavenLogo />
      </LogoWrapper>
      <SignInComponent />
    </React.Fragment>
  );
}

export default SignIn;
