import React from "react";

import SidebarNavList from "./SidebarNavList";
import { SidebarItemsType } from "@/types/sidebar";
import SidebarNavListItem from "./SidebarNavListItem";
import { useTranslation } from "react-i18next";

type ReduceChildRoutesProps = {
  depth: number;
  page: SidebarItemsType;
  items: JSX.Element[];
  navPath: string;
  setNavPath:  React.Dispatch<React.SetStateAction<string>>;
};

const reduceChildRoutes = (props: ReduceChildRoutesProps) => {
  const { items, page, depth, navPath, setNavPath } = props;
  const { t } = useTranslation();

  if (page.children) {
    const open = navPath.startsWith(page.href);
    
    items.push(
      <SidebarNavListItem
        depth={depth}
        icon={page.icon || (() => null)}
        key={page.title}
        badge={page.badge}
        open={!!open}
        title={t(page.title)}
        href={page.href}
        setNavPath={setNavPath}
      >
        <SidebarNavList depth={depth + 1} pages={page.children} navPath={navPath} setNavPath={setNavPath}/>
      </SidebarNavListItem>
    );
  } else {
    items.push(
      <SidebarNavListItem
        depth={depth}
        href={page.href}
        icon={page.icon || (() => null)}
        key={page.title}
        badge={page.badge}
        title={t(page.title)}
        setNavPath={setNavPath}
      />
    );
  }

  return items;
};

export default reduceChildRoutes;
