import { SelectorDef } from "@/components/FiltersPanel/types";
import { SelectorNames } from "@/components/FiltersPanel/consts";
import { DataState } from "@/types/dataContext";
  
export const selectorsConfig: SelectorDef[] = [
  { name: SelectorNames.Merchant, multiSelect: false },
  { name: SelectorNames.Products, multiSelect: false },
  { name: SelectorNames.Label },
];
  
export const dependentAPIs: (keyof DataState)[] = [
    "merchantProductContentApi"
];