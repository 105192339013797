import { useApiQuery, ApiQuery } from "../useApiQuery";
import { createQueryFunction } from "../queries/createQueryFunction";
import { BrandsApiType, BrandsResponse, BrandsParams } from "./types";

const keyAllBrands = "allBrands";

const keyParentBrands = "parentBrands";

const keySubbrandBrands = "subBrands";


export const useBrands = (): BrandsApiType => {
    const allBrandsApi = useApiQuery<BrandsResponse[], BrandsParams>({
      key: keyAllBrands,
      queryFn: createQueryFunction(`brands?mode=0`),
    });
  
    const parentBrandsApi = useApiQuery<BrandsResponse[], BrandsParams>({
      key: keyParentBrands,
      queryFn: createQueryFunction(`brands?mode=1`),
    });

    const subBrandsApi = useApiQuery<BrandsResponse[], BrandsParams>({
      key: keySubbrandBrands,
      queryFn: createQueryFunction(`brands?mode=2`),
    });
  
    return { allBrandsApi, parentBrandsApi, subBrandsApi };
};