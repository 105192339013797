import { createQueryFunction } from "../queries/createQueryFunction";
import { useApiQuery } from "../useApiQuery";
import { DefJsonResponse } from "./types";

const basePath = "bn-content/default_json.json"

export const useDefContentJson = () => {
  const defContentJsonApi = useApiQuery<DefJsonResponse>({
    key: "content-json",
    queryFn: createQueryFunction(basePath),
  });
  return defContentJsonApi;
};