import { Manufacturer, ManufacturersResponse } from "@/api/useManufacturers";

export const isValidManufacturer = ({
  manufacturer,
  possibleManufacturers,
}: {
  manufacturer: Manufacturer[];
  possibleManufacturers: ManufacturersResponse;
}) => {
  if (manufacturer.length === 0) {
    return false;
  }

  const filteredManufacturers = possibleManufacturers.filter(
    (m: Manufacturer) =>
      manufacturer.find((m2) => m.ID === m2.ID && m.NAME === m2.NAME)
  );

  return filteredManufacturers.length === manufacturer.length;
};
