import axios from "axios";
import {refresh} from "../refresh/refreshFunction";

const apiUrl = import.meta.env.VITE_BASE_SERVER_URL;

export enum ContentType {
  JSON = "application/json",
  URLENCODED = "application/x-www-form-urlencoded",
}

/**
 * Function for reusable post requests
 *
 * @template TResponse - The type representing the response data.
 * @template TParams - The type representing the response params.
 * @param {string} url - target for request
 * @param {TParams} variables  - body params
 * @param {ContentType = ContentType.JSON} contentType - content type for request
 * @param {AbortSignal | undefined} signal - abort signal
 * @returns {Promise<TResponse>} response data
 */
const createRequest = async <TResponse, TParams = undefined>(
  url: string,
  variables: TParams,
  contentType: ContentType = ContentType.JSON,
  signal: AbortSignal | undefined,
  method: 'POST' | 'PUT' | 'DELETE' | 'PATCH' = 'POST' // Add method parameter, default to 'POST'
): Promise<TResponse> => {
  const requestOptions = {
    headers: { "Content-Type": contentType },
    withCredentials: true,
    signal,
  };

  // Use axios to make the request based on the method
  const response = await axios({
    url,
    method,
    data: variables,
    ...requestOptions,
  });

  if (!response.data) {
    throw new Error("No data found");
  }

  return response.data as TResponse;
};

/**
 * Function to create a mutation function for POST, PUT, DELETE, and other non-GET requests.
 * This function is used for sending data to an API endpoint, typically to create, update, or delete resources.
 *
 * @template TResponse - The type representing the response data.
 * @template TParams - The type representing the request body parameters (optional).
 * @param {string} endpoint - The specific API endpoint to send the request to.
 * @returns {function} - A function that performs the specified mutation request.
 */
export const createMutationFunction = <TResponse, TParams = undefined>(
  endpoint: string,
  contentType: ContentType = ContentType.JSON,
  method: 'POST' | 'PUT' | 'DELETE' | 'PATCH' = 'POST'
) => {
  return async (variables?: TParams, signal?: AbortSignal) => {
    const url = `${apiUrl}/${endpoint}`;

    try {
      return await createRequest<TResponse, TParams | undefined>(
          url,
          variables,
          contentType,
          signal,
          method // Pass the method to createRequest
      );
    } catch (error: unknown) {
      if (axios.isAxiosError(error) && error.response?.status === 401) {
        try {
          await refresh(signal);
          return await createRequest<TResponse, TParams | undefined>(
              url,
              variables,
              contentType,
              signal,
              method
          );
        } catch (retryError: unknown) {
          throw retryError;
        }
      }
      throw error;
    }
  };
};
