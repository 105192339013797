export function transformParameter(param: string): string {
    // Define the mapping between full names and their short forms
    const mappings: { [key: string]: string } = {
        manufacturers: 'm',
        brands: 'b',
        subbrands: 'sb',
        focus: 'f',
        products: 'p',
        merchants: 'pos',
        utm_sources: 'us',
        utm_mediums: 'um',
        utm_campaigns: 'uca',
        utm_contents: 'uco',
        utm_terms: 'ut',
        devices: 'd',
        urls: 'u',
        labels: 'l',
        markets: 'ma',
        sellers: 's',
        rev_status: 'rs',
        availabilities: 'a',
        avStatus: 'avs',
    };

    // Return the corresponding short form if found, otherwise return the original string
    return mappings[param] || param;
}