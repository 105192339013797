type EventCallback = () => void;

class EventEmitter {
  private events: { [key: string]: EventCallback[] } = {};

  on(event: string, listener: EventCallback) {
    if (!this.events[event]) {
      this.events[event] = [];
    }
    this.events[event].push(listener);
  }

  off(event: string, listener: EventCallback) {
    if (!this.events[event]) return;

    this.events[event] = this.events[event].filter(l => l !== listener);
  }

  emit(event: string) {
    if (!this.events[event]) return;

    this.events[event].forEach(listener => listener());
  }
}

const globalEventEmitter = new EventEmitter();

export default globalEventEmitter;