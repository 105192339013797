import { deleteMutationFunction } from "./mutations/deleteMutationFunction";
import { createQueryFunction } from "./queries/createQueryFunction";
import { ApiMutation, useApiMutation } from "./useApiMutation";
import { ApiQuery, useApiQuery } from "./useApiQuery";

/**
 * Interface representing a Manufacturer.
 */
export interface Manufacturer {
  ID: number;
  NAME: string;
  NAME_COMPANY: string;
  GROUP: string;
  access: string;
}

/**
 * Type representing the response for fetching manufacturers.
 * This is an array of Manufacturer objects.
 */
export type ManufacturersResponse = Manufacturer[];

/**
 * Interface representing the parameters for deleting a manufacturer.
 */
export interface DeleteManufacturerParams {
  manufacturerId: number;
}

/**
 * Response type for delete manufacturer operation.
 */
export type DeleteManufacturersResponse = {};

/**
 * Type representing the API query for manufacturers.
 *
 * @template TResponse - The type of the response data.
 * @template TQueryParam - The type of the query parameter.
 * @template TMutationResponse - The type of the mutation response data.
 * @template TMutationParam - The type of the mutation parameter.
 */
export type ManufacturersApiType = {
  isLoading: boolean;
  manufacturers: ApiQuery<ManufacturersResponse>;
  deleteManufacturer: ApiMutation<
    DeleteManufacturersResponse,
    DeleteManufacturerParams
  >;
};

const key = "manufacturers";

/**
 * Custom hook to handle API queries for manufacturers.
 *
 * This hook manages fetching manufacturers data and deleting a manufacturer.
 * It uses the `useApiQuery` hook for data fetching and `useApiMutation` hook for deletion.
 *
 * @returns {ManufacturersApiType} - An object containing the loading state, manufacturers data query,
 * and the delete manufacturer mutation.
 *
 * @example
 * const { isLoading, manufacturers, deleteManufacturer } = useManufacturers();
 *
 * // Fetch manufacturers data
 * const { data, error, isLoading: isLoadingManufacturers } = manufacturers;
 * manufacturers.execute();
 *
 * // Delete a manufacturer
 * deleteManufacturer.execute({ manufacturerId: 1 });
 */
export const useManufacturers = (): ManufacturersApiType => {
  const manufacturers = useApiQuery<ManufacturersResponse>({
    key,
    queryFn: createQueryFunction(key),
  });

  /**
   * Only an example
   */
  const deleteManufacturer = useApiMutation<
    DeleteManufacturersResponse,
    DeleteManufacturerParams
  >({
    mutationFn: deleteMutationFunction<
      DeleteManufacturersResponse,
      DeleteManufacturerParams
    >(`${key}/delete`),
  });

  return {
    isLoading: manufacturers.isLoading || deleteManufacturer.isLoading,
    manufacturers,
    deleteManufacturer,
  };
};
