import { SelectorDef } from "@/components/FiltersPanel/types";
import { SelectorNames } from "@/components/FiltersPanel/consts";
import { DataState } from "@/types/dataContext";
  
export const selectorsConfig: SelectorDef[] = [
  { name: SelectorNames.Merchant, dependentFilters: [SelectorNames.Seller]},
  { name: SelectorNames.Seller },
  { name: SelectorNames.Market, disabled: true},
  { name: SelectorNames.Focus, dependentFilters: [SelectorNames.Products, SelectorNames.ParentBrand, SelectorNames.SubBrand], defaultValue: 0 },
  { name: SelectorNames.ParentBrand, dependentFilters: [SelectorNames.Products, SelectorNames.SubBrand]},
  { name: SelectorNames.SubBrand, dependentFilters: [SelectorNames.Products]},
  { name: SelectorNames.Products },
];
  
export const dependentAPIs: (keyof DataState)[] = [
  "contentTableApi",
];

export const hiddenTableColumns = [
  'CANONICAL',
  'DESC_LENGTH',
  'NORM',
  'UPDATED_PP',
  'UPDATED',
  'VIDEO_DESC',
  'PACKSHOTS_DESC',
  'STORE',
  'SELLER',
  'STATUS'
]