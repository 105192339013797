import { Manufacturer, useManufacturers } from "@/api/useManufacturers";
import { useMerchants } from "@/api/useMerchants";
import useAuth from "@/hooks/auth/useAuth";
import React, {
  useCallback,
  useEffect,
  useState,
  useMemo,
  useRef,
} from "react";
import { isValidManufacturer } from "./DataContext.utils";
import {
  useBuyNowTransactions,
  BuyNowTransactionsApiType,
} from "@/api/useBuyNowTransactions";
import {
  AnalyticsGraphApiType,
  BrandPagePerformanceApiType,
  BrandsApiType,
  KpiBrandPerformanceApiType,
  KpiMerchantsLeadsApiType,
  KpiTransactionsApiType,
  MerchantsLeadsApiType,
  MerchantsTransactionsApiType,
  ProductLeadsApiType,
  ProductsApiType,
  ProductsVisitsApiType,
  UtmParamsApiType,
  useAnalyticsQuery,
} from "@/api/useAnalytics";
import {
  BrandsFilterApiType,
  LabelsFilterApiType,
  SellerFilterApiType,
} from "@/api/filters/types";
import useFilters from "@/api/filters";
import {
  AiReviewSummaryApiType,
  ContentTableApiType,
  PackshotsAndVideosApiType,
  RatingsAndReviewsTableApiType,
} from "@/api/content/types";
import useContent from "@/api/content";
import { DataState, DataProviderProps } from "@/types/dataContext";
import { useUpdateUser } from "@/api/useUserUpdate";
import { useAiOptimizer } from "@/api/useAiOptimizer";
import { useMerchantProductContent } from "@/api/useMerchantProductContent";
import { UserResponse, Settings } from "@/types/user";
import i18next from "i18next";
import { isSameArray } from "@/utils/isSameArray";
import dayjs from "dayjs";
import { TimeRange } from "@/components/TimeRangePicker/types";
import useBuynow from "@/api/buynow";
import useVisibility from "@/api/visibility";
import { ShareOfSearchApiType } from "@/api/visibility/types";
import {
  SetupCategoriesApi,
  SetupKeywordsApi,
  SetupLabelsApi,
  SetupLabelsUpdateApi,
  SetupMarketsApi,
  SetupProductsApi,
} from "@/api/setup/types";
import {
  AllPropertiesApiType,
  AssignConfigComponentApi,
  ComponentsApiType,
  CreateConfigApi,
  DefJsonApiType,
  UpdateAssignmentConfigComponentApi,
  UpdateComponentsApiType,
} from "@/api/buynow/types";

import { AvailabilityTableApiType, ProductListingsTableApiType } from "@/api/availability/types";
import { useAvailability } from "@/api/availability";
import useSetup from "@/api/setup";
import useAppTheme from "@/hooks/useTheme";
import { uiModes, currencies, numbersFormat, languageOptions } from "@/consts/settings";
import {PricingTableApiType} from "@/api/pricing/types";
import usePricing from "@/api/pricing";

const initialState: DataState = {
  isLoading: false,
  user: undefined,
  merchantsApi: undefined,
  manufacturersApi: undefined,
  setManufacturer: (data: Manufacturer[]) => {},
  setSettings: (data: Settings) => {},
  brandsApi: {} as BrandsApiType,
  productsApi: {} as ProductsApiType,
  utmParamsApi: {} as UtmParamsApiType,
  analyticsGraphApi: {} as AnalyticsGraphApiType,
  productLeadsApi: {} as ProductLeadsApiType,
  productsVisitsApi: {} as ProductsVisitsApiType,
  merchantsLeadsApi: {} as MerchantsLeadsApiType,
  merchantsTransactionsApi: {} as MerchantsTransactionsApiType,
  brandPagePerformanceApi: {} as BrandPagePerformanceApiType,
  kpiBrandPerformanceApi: {} as KpiBrandPerformanceApiType,
  kpiTransactionsApi: {} as KpiTransactionsApiType,
  kpiMerchantsLeadsApi: {} as KpiMerchantsLeadsApiType,
  updateUserApi: undefined,
  aiOptimizerApi: undefined,
  merchantProductContentApi: undefined,
  allBrandsApi: {} as BrandsFilterApiType,
  parentBrandsApi: {} as BrandsFilterApiType,
  subBrandsApi: {} as BrandsFilterApiType,
  sellerApi: {} as SellerFilterApiType,
  labelsApi: {} as LabelsFilterApiType,
  contentTableApi: {} as ContentTableApiType,
  pricingTableApi: {} as PricingTableApiType,
  buyNowTransactionsApi: {} as BuyNowTransactionsApiType,
  shareOfSearchApi: {} as ShareOfSearchApiType,
  ratingsAndReviewsApi: {} as RatingsAndReviewsTableApiType,
  reviewSummaryApi: {} as AiReviewSummaryApiType,
  componentsApi: {} as ComponentsApiType,
  defSocialJsonApi: {} as DefJsonApiType,
  defContentJsonApi: {} as DefJsonApiType,
  updateComponentsApi: {} as UpdateComponentsApiType,
  assignConfigComponentApi: {} as AssignConfigComponentApi,
  updateAssignmentConfigComponentApi: {} as UpdateAssignmentConfigComponentApi,
  createConfigApi: {} as CreateConfigApi,
  allManufacturerIsSelected: false,
  dateRange: {} as TimeRange,
  setDateRange: () => {},
  setAllManufacturerIsSelected: (isSelected: boolean) => {},
  selectedManufacturers: [],
  allPropertiesApi: {} as AllPropertiesApiType,
  setupKeywordsApi: {} as SetupKeywordsApi,
  setupCategoriesApi: {} as SetupCategoriesApi,
  setupLabelsApi: {} as SetupLabelsApi,
  setupProductsApi: {} as SetupProductsApi,
  setupMarketsApi: {} as SetupMarketsApi,
  daysChanged: { current: false },
  availabilityTableApi: {} as AvailabilityTableApiType,
  setupLabelsUpdateApi: {} as SetupLabelsUpdateApi,
  productListingsTableApi: {} as ProductListingsTableApiType,
  packshotsAndVideosTableApi: {} as PackshotsAndVideosApiType,
  setFiltersLoading: () => {},
};

const DataContext = React.createContext(initialState);

/**
 * DataProvider component
 *
 * This component serves as a context provider for data related to the authenticated user, manufacturers, and merchants.
 * It fetches and manages data from various APIs and provides functions to handle manufacturer data in local storage.
 *
 * @param {DataProviderProps} props - The properties for the DataProvider component, including its children nodes.
 *
 * @returns {JSX.Element} A context provider component that wraps its children and provides various data and functions.
 */
const DataProvider = ({ children }: DataProviderProps) => {
  const { isAuthenticated, signInApi, meApi } = useAuth();
  const [user, setUser] = useState<UserResponse | undefined>(undefined);
  const { setTheme } = useAppTheme();
  const [filtersLoading, setFiltersLoading] = useState<boolean>(false);

  const [selectedManufacturers, setSelectedManufacturers] = useState<
    Manufacturer[]
  >([]);
  const manufacturersApi = useManufacturers();
  const merchantsApi = useMerchants();
  const updateUserApi = useUpdateUser();
  const aiOptimizerApi = useAiOptimizer();
  const merchantProductContentApi = useMerchantProductContent();
  const visibilityApi = useVisibility();
  const analyticsApis = useAnalyticsQuery();
  const filtersApi = useFilters();
  const merchantContentApi = useContent();
  const pricingApi = usePricing();
  const setupApi = useSetup();
  const buyNowTransactionsApi = useBuyNowTransactions();
  const buynowApi = useBuynow();
  const availabilityApi = useAvailability();

  const [allManufacturerIsSelected, setAllManufacturerIsSelected] =
    useState(false);
  const [dateRange, setDateRange] = useState<TimeRange>({
    from_date: dayjs().subtract(30, "day").unix(),
    to_date: dayjs().unix(),
  });
  const daysChanged = useRef(false);

  const { manufacturers } = manufacturersApi;
  const { merchants } = merchantsApi;

  const mergeManufacturerInUser = useCallback((data: Manufacturer[]) => {
    setUser((prev) => (prev ? { ...prev, manufacturer: data } : prev));
  }, []);

  const setSettings = useCallback((data: Settings) => {
    setUser((prev) => (prev ? { ...prev, settings: data } : prev));
  }, []);

  const setManufacturerInLocalStorage = useCallback(
    (data: Manufacturer[]) => {
      localStorage.setItem("manufacturer", JSON.stringify(data));
      mergeManufacturerInUser(data);
    },
    [mergeManufacturerInUser]
  );

  const loadManufacturerFromStorage = useCallback(() => {
    const storedManufacturer = localStorage.getItem("manufacturer");

    if (!manufacturers.data) return;

    const possibleManufacturers = manufacturers.data.filter(
      (m) => m.access !== "see"
    );
    if (!storedManufacturer) {
      //set the dafault value
      localStorage.setItem(
        "manufacturer",
        JSON.stringify(possibleManufacturers)
      );
      mergeManufacturerInUser(possibleManufacturers);
      return;
    }

    let parsedManufacturer: Manufacturer[] | Manufacturer =
      JSON.parse(storedManufacturer);
    if (parsedManufacturer && !Array.isArray(parsedManufacturer)) {
      parsedManufacturer = [parsedManufacturer];
    }

    if (
      !isValidManufacturer({
        manufacturer: parsedManufacturer,
        possibleManufacturers: possibleManufacturers,
      })
    ) {
      parsedManufacturer = possibleManufacturers;
    }

    localStorage.setItem("manufacturer", JSON.stringify(parsedManufacturer));
    mergeManufacturerInUser(parsedManufacturer);
  }, [manufacturers.data]);

  useEffect(() => {
    const newArr =
      user && user?.manufacturer
        ? Array.isArray(user?.manufacturer)
          ? user?.manufacturer
          : [user?.manufacturer]
        : [];
    if (!isSameArray(selectedManufacturers, newArr)) {
      setSelectedManufacturers(newArr);
    }
  }, [user?.manufacturer, selectedManufacturers]);

  useEffect(() => {
    const fetchData = async () => {
      if (isAuthenticated) {
        // shouldn't be cached as issues with log in and  log out arise
        await manufacturers.execute(undefined, false);
        await merchants.execute();
      }
    };

    fetchData();
  }, [isAuthenticated]);

  useEffect(() => {
    if (isAuthenticated && (signInApi?.data || meApi?.data)) {
      const data = (signInApi?.data || meApi?.data) as UserResponse;

      if(data && data.settings) {
        data.settings.currency = 
          data.settings.currency && currencies.includes(data.settings.currency) ?
          data.settings.currency :
          "euro";

        data.settings.language = 
          data.settings.language && data.settings.language in languageOptions ?
          data.settings.language :
          "en";

        data.settings.numberFormat = 
          data.settings.numberFormat && data.settings.numberFormat in numbersFormat ?
          data.settings.numberFormat :
          "en-US";

        data.settings.uiMode = 
          data.settings.uiMode && uiModes.includes(data.settings.uiMode) ?
          data.settings.uiMode :
          "light";
      } else {
        data.settings = {
          uiMode: "light",
          currency: "euro",
          language: "en",
          numberFormat: "en-US",
        };
      }
      
      setUser(data);
      // setting the current language and mode always based on the db
      const language = data.settings.language;
      const uiMode = data.settings.uiMode;

      localStorage.setItem(
        "language",
        language,
      );
      i18next.changeLanguage(language);
      setTheme(uiMode);

      if (manufacturers.data) {
        loadManufacturerFromStorage();
      }
    }
  }, [
    isAuthenticated,
    signInApi?.data,
    meApi?.data,
    manufacturers.data,
    loadManufacturerFromStorage,
  ]);

  const isAnalyticsLoading = Object.values(analyticsApis).some(
    (api) => api?.isLoading
  );

  const isFiltersLoading = Object.values(filtersApi).some(
    (api) => api?.isLoading
  );

  const merchantsContentLoading = Object.values(merchantContentApi).some(
    (api) => api?.isLoading
  );

  const pricingContentLoading = Object.values(pricingApi).some(
    (api) => api?.isLoading
  );

  const buynowLoading = Object.values(buynowApi).some((api) => api?.isLoading);
  const visibilityLoading = Object.values(visibilityApi).some(
    (api) => api?.isLoading
  );

  const availabilityLoading = Object.values(availabilityApi).some(
    (api) => api?.isLoading
  );

  const setupLoading = Object.values(setupApi).some((api) => api?.isLoading);
  return (
    <DataContext.Provider
      value={{
        isLoading:
          manufacturersApi.isLoading ||
          merchantsApi.isLoading ||
          isAnalyticsLoading ||
          isFiltersLoading ||
          merchantsContentLoading ||
          pricingContentLoading ||
          buynowLoading ||
          visibilityLoading ||
          availabilityLoading ||
          setupLoading ||
          filtersLoading,
        user,
        setSettings,
        manufacturersApi,
        merchantsApi,
        setManufacturer: setManufacturerInLocalStorage,
        ...analyticsApis,
        updateUserApi,
        aiOptimizerApi,
        merchantProductContentApi,
        ...filtersApi,
        ...merchantContentApi,
        ...pricingApi,
        ...visibilityApi,
        ...setupApi,
        buyNowTransactionsApi,
        setAllManufacturerIsSelected,
        allManufacturerIsSelected,
        selectedManufacturers,
        dateRange,
        setDateRange,
        ...buynowApi,
        daysChanged,
        ...availabilityApi,
        setFiltersLoading,
      }}
    >
      {children}
    </DataContext.Provider>
  );
};

export { DataContext, DataProvider };
