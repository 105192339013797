import { usePricingTable } from "./usePricingTable";
import { PricingApiType } from "./types";

const usePricing = (): PricingApiType => {
    const pricingTableApi = usePricingTable();

    return {
        pricingTableApi,
    }
};

export default usePricing;