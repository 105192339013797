'use client'

import React from 'react'
import { motion } from 'framer-motion'
import Box from '@mui/material/Box';

const AiThinkingLoader = () => {
  const circleStyle = {
    display: 'block',
    width: 8,  // Smaller circle size
    height: 8,
    backgroundColor: 'currentColor',
    borderRadius: '50%',
  }

  const containerVariants = {
    start: {
      transition: {
        staggerChildren: 0.2,  // Staggering creates wave effect
      },
    },
    end: {
      transition: {
        staggerChildren: 0.2,
      },
    },
  }

  const circleVariants = {
    start: {
      y: '0%',
    },
    end: {
      y: '100%',
    },
  }

  const circleTransition = {
    duration: 0.5,
    repeat: Infinity,
    repeatType: 'reverse' as const,
    ease: 'easeInOut' as const,
  }

  return (
    <Box display="flex" justifyContent="flex-start" alignItems="center" height={8}> {/* Ensuring left alignment */}
      <motion.div
        className="flex space-x-1"
        variants={containerVariants}
        initial="start"
        animate="end"
        transition={{
          repeat: Infinity,        // Repeat the whole container animation
          repeatType: 'loop',      // Loop the wave effect
          repeatDelay: 1,        // Adds a 0.5s delay after the entire wave finishes
        }}
        style={{ display: 'flex', alignItems: 'center' }}
      >
        {[0, 1, 2].map((index) => (
          <motion.span
            key={index}
            style={circleStyle}
            variants={circleVariants}
            transition={circleTransition}
          />
        ))}
      </motion.div>
    </Box>
  )
}

export default AiThinkingLoader;
