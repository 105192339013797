import React, { useState, useEffect } from "react";
import {
  Button,
  Checkbox,
  FormControlLabel,
  TextField,
  Typography,
  Divider,
  Box,
  InputAdornment,
  useTheme,
} from "@mui/material";
import { useClickOutside } from "@/hooks/useClickOutside";
import SearchIcon from "@mui/icons-material/Search";
import TextFieldsIcon from "@mui/icons-material/TextFields";
import { useTranslation } from "react-i18next";

interface FilterComponentProps {
  elements: string[];
  selectedElements: string[];
  onApplyFilter: (selected: string[]) => void;
  onClose: () => void;
  filterColumn: string;
}

const FilterComponent: React.FC<FilterComponentProps> = ({
  elements,
  selectedElements: initialSelectedElements,
  onApplyFilter,
  onClose,
  filterColumn,
}) => {
  const [filterText, setFilterText] = useState<string>("");
  const [selectedElements, setSelectedElements] = useState<string[]>(
    initialSelectedElements
  );
  const [selectAll, setSelectAll] = useState<boolean>(false);
  const { ref } = useClickOutside(onClose);
  const theme = useTheme();

  const { t } = useTranslation();

  useEffect(() => {
    setSelectedElements(initialSelectedElements);
    setSelectAll(false);
    if (elements.length === initialSelectedElements.length) {
      setSelectAll(true);
    }
  }, [initialSelectedElements]);

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilterText(event.target.value);
  };

  const handleCheckboxChange = (element: string) => {
    setSelectedElements((prev) =>
      prev.includes(element)
        ? prev.filter((item) => item !== element)
        : [...prev, element]
    );
  };

  const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectAll(event.target.checked);
    setSelectedElements(event.target.checked ? elements : []);
  };

  const handleApply = () => {
    onApplyFilter(selectedElements);
    onClose();
  };

  const handleCancel = () => {
    onClose();
  };

  const filteredElements = elements.filter((element) =>
    String(element).toLowerCase().includes(filterText.toLowerCase())
  );

  return (
    <Box
      sx={{
        padding: 2,
        width: 300,
        position: "absolute",
        right: 0,
        top: 85,
        zIndex: 11,
        boxShadow: theme.shadows[3],
        backgroundColor: theme.palette.background.paper,
      }}
      ref={ref}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography variant="h6" fontWeight="700">{`${t(
          "general.filter.filterBy"
        )}  ${filterColumn.toLowerCase()}`}</Typography>
        <TextFieldsIcon sx={{ color: theme.palette.text.secondary }} />
      </Box>

      <Divider sx={{ marginY: 2 }} />

      <TextField
        variant="outlined"
        size="small"
        placeholder={t("general.filter.search") as string}
        fullWidth
        value={filterText}
        onChange={handleSearch}
        sx={{ marginY: 1 }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon sx={{ color: theme.palette.text.primary }} />
            </InputAdornment>
          ),
        }}
      />

      <Box sx={{ maxHeight: 200, overflowY: "auto" }}>
        {filteredElements.map((element) => (
          <FormControlLabel
            key={element}
            sx={{ display: "block" }}
            control={
              <Checkbox
                checked={selectedElements.includes(element)}
                onChange={() => handleCheckboxChange(element)}
              />
            }
            label={element}
            componentsProps={{
              typography: {
                sx: {
                  fontSize: "14px",
                  fontWeight: "600",
                },
              },
            }}
          />
        ))}
      </Box>

      <Divider sx={{ marginY: 2 }} />

      <FormControlLabel
        control={<Checkbox checked={selectAll} onChange={handleSelectAll} />}
        label={
          <Box>
            <Typography
              sx={{
                fontSize: "14px",
                fontWeight: "600",
              }}
            >
              {t("general.filter.selectAll")}
            </Typography>
            <Typography variant="body2" sx={{ display: "block" }}>
              {t("general.filter.helpText")}
            </Typography>
          </Box>
        }
        componentsProps={{
          typography: {
            sx: {
              fontSize: "14px",
              fontWeight: "600",
            },
          },
        }}
      />

      <Box
        sx={{
          display: "flex",

          marginTop: "20px",
          gap: "10px",
        }}
      >
        <Button
          variant="contained"
          color="info"
          onClick={handleApply}
          sx={{
            minWidth: "50px",
          }}
        >
          {t("general.OK")}
        </Button>
        <Button
        variant="contained"
        onClick={handleCancel}
        sx={{
          bgcolor: theme.palette.grey[500],
          color: theme.palette.text.primary,
          ":hover": {
            bgcolor: theme.palette.grey[600],
            opacity: 0.8,
          },
        }}
      >
          {t("general.cancel")}
        </Button>
      </Box>
    </Box>
  );
};

export default FilterComponent;
