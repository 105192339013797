
import { AvailabilityApiType } from "./types";
import { useAvailabilityTable } from "./useAvailabilityTable";
import { useProductListings } from "./useProductListings";

export const useAvailability = (): AvailabilityApiType => {
  const availabilityTableApi = useAvailabilityTable();
  const productListingsApi = useProductListings();
  
  return {
    availabilityTableApi,
    ...productListingsApi,
  };
};
