export const titleStyles = {
    fontSize: '24px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '32px',  // equivalent to 133.4% of 24px font size
};

export const textStyles = {
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '24px',
    letterSpacing: '0.5px'
};