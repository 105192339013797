import { useBrands } from "./useBrands";
import { useSeller } from "./useSeller";
import { FilterApiType } from "./types";
import { useLabels } from "./useLabels";

const useFilters = (): FilterApiType => {
    const brandsApi = useBrands();
    const { sellerApi } = useSeller();
    const { labelsApi } = useLabels();

    return {
        ...brandsApi,
        sellerApi,
        labelsApi,
    }
};

export default useFilters;