import React, {useMemo} from "react";
import FiltersPanel from "@/components/FiltersPanel";
import {dependentAPIs, selectorsConfig} from "./consts";
import {GridColDef} from "@mui/x-data-grid-pro";
import useData from "@/hooks/auth/useData";
import {styled, Typography} from "@mui/material";
import {Box, spacing} from "@mui/system";
import {Paper as MuiPaper} from "@mui/material";
import {useColumns} from "@/hooks/useColumnsData";
import ContentContainer from "@/components/contentContainer";
import {t} from "i18next";
import MuiGridTable from "@/components/tables/DataGridTable";
import AiThinkingLoader from "@/components/loading/ai-thinking-loader";

const Paper = styled(MuiPaper)(spacing);

const RatingsAndReviews = () => {
    const {ratingsAndReviewsApi, reviewSummaryApi, isLoading} = useData();

    const rows = useMemo(() => {
        return (ratingsAndReviewsApi.data || []).map(
            (row: { DATE: string }, index: number) => ({
                ...row,
                id: `${row.DATE}-${index}`,
            })
        );
    }, [ratingsAndReviewsApi?.data]);

    const summary = useMemo(() => {
        return reviewSummaryApi.data?.summary || ""
    }, [reviewSummaryApi?.data]);

    const allColumns = useColumns();

    const columns: GridColDef[] = [
        allColumns.posColumn,
        allColumns.statusColumn,
        {...allColumns.brandNameColumn, width: 150},
        {...allColumns.productLinkColumn, flex: 1, minWidth: 200},
        {...allColumns.reviewColumn, flex: 2, minWidth: 350},
        allColumns.ratingStarsColumn,
        allColumns.avgColumn,
        allColumns.ratingsColumn,
        allColumns.impactColumn,
        allColumns.sizeColumn,
        allColumns.pznColumn,
        allColumns.authorColumn,
        allColumns.titleColumn,
        allColumns.dateColumn,
    ];

    return (
        <ContentContainer>
            <FiltersPanel
                hasDaysFilter={false}
                selectorsConfig={selectorsConfig}
                dependentAPIs={dependentAPIs}
            />
            <Paper>
                <Box style={{padding: "16px"}}>
                    <Typography variant="h6" sx={{marginBottom: 2}}>
                        {t("pages.ratingsAndReviews.customerOpinion")}
                    </Typography>
                    {reviewSummaryApi.isLoading ? (
                        <AiThinkingLoader/>
                    ) : reviewSummaryApi.isError ? (
                        <Typography variant="body1" color="error">
                            {reviewSummaryApi.error}
                        </Typography>
                    ) : (
                        <>
                            <Typography variant="body1">
                                {summary}
                            </Typography>
                            <Typography variant="body2"
                                        sx={{color: 'text.secondary', fontSize: '0.875rem'}}>
                                {t("pages.ratingsAndReviews.aiDisclaimer")}
                            </Typography>
                        </>
                    )}

                </Box>

                <MuiGridTable
                    rows={rows}
                    initialPageSize={100}
                    columns={columns}
                    loading={isLoading}
                    filterBarType={2}
                    hiddenFields={[
                        "BRAND",
                        "STATUS",
                        "AUTHOR",
                        "SIZE",
                        "PZN",
                        "AUTHOR",
                        "TITLE",
                    ]}
                    excludedRowsOnSearch={["URL"]}
                    searchPlaceholder={
                        t("tableColumns.searchPlaceholder") || "SKU, Products, ..."
                    }
                    isError={ratingsAndReviewsApi.isError}
                    error={ratingsAndReviewsApi.error}
                />
            </Paper>
        </ContentContainer>
    );
};


export default RatingsAndReviews;
