import { Dialog, DialogTitle, DialogContent, DialogActions, Grid, Button, TextField, Alert } from '@mui/material';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import useData from "@/hooks/auth/useData";
import { CollaborationSelect } from "@/components/CollaborationSelect";
import { SetupLabelsApi } from '@/api/setup/types';

interface CreateLabelModalProps {
  open: boolean;
  onClose: () => void;
  manufacturerIds: number[];  // TODO: Eventually the owner will depend on the manufacturer
  onSuccess: () => void;
  listApi: SetupLabelsApi;
}

const CreateLabelModal = ({ open, onClose, manufacturerIds, onSuccess, listApi }: CreateLabelModalProps) => {
  const { t } = useTranslation();
  const { user, setupLabelsCreateApi } = useData();
  const [label, setLabel] = useState('');
  const [comment, setComment] = useState('');
  const [owner, setOwner] = useState('');
  const [error, setError] = useState<string | null>(null);
  const [labelError, setLabelError] = useState(false);

  useEffect(() => {
    if (user?.username) {
      setOwner(user.username);
    }
  }, [user]);

  const handleClose = () => {
    setLabel('');
    setComment('');
    setError(null);
    setLabelError(false);
    onClose();
  };

  const handleSubmit = async () => {
    if (!label.trim()) {
      setLabelError(true);
      return;
    }
    
    try {
      setError(null);
      setLabelError(false);
      // Default color is white.
      await setupLabelsCreateApi.execute({ name: label, comment: comment, color: '#FFFFFF'});
      onSuccess();
      onClose();
      setLabel('');
      setComment('');
      listApi.execute();
    } catch (error) {
      setError(t('pages.labels.alerts.createFailed'));
    }
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle>{t('pages.labels.create.title')}</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <TextField
              variant="outlined"
              margin="dense"
              size="small"
              label={t('pages.labels.create.labelName')}
              fullWidth
              required
              value={label}
              onChange={(e) => {
                setLabel(e.target.value);
                setLabelError(false);
              }}
              error={labelError}
              helperText={labelError ? t('general.required') : ''}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              size="small"
              variant="outlined"
              margin="dense"
              label={t('pages.labels.create.owner')}
              fullWidth
              value={owner}
              disabled
              onChange={(e) => setOwner(e.target.value)}
              InputProps={{readOnly: true}}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <CollaborationSelect disabled/>
          </Grid>
        </Grid>
        <TextField
          variant="outlined"
          size="small"
          margin="dense"
          label={t('pages.labels.create.comment')}
          fullWidth
          multiline
          rows={4}
          value={comment}
          onChange={(e) => setComment(e.target.value)}
        />
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'flex-start', paddingLeft: 6, paddingRight: 6 }}>
        <Button onClick={handleClose} size="large">{t('general.cancel')}</Button>
        <Button onClick={handleSubmit} variant="contained" size="large">
          {t('general.create')}
        </Button>
        {error && (
          <Alert variant="filled" severity="error" sx={{ width: '100%', paddingTop: 1, paddingBottom: 1 }}>
            {error}
          </Alert>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default CreateLabelModal;