import { createQueryFunction } from "../queries/createQueryFunction";
import { useApiQuery } from "../useApiQuery";
import { MarketsResponse, SetupParams } from "./types";

const key = "markets";

export const useMarkets = () => useApiQuery<MarketsResponse[], SetupParams>({
    key: key,
    queryFn: createQueryFunction<MarketsResponse[], SetupParams>(
      "app/setup/get_markets"
    ),
  });
