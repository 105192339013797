import ContentContainer from "@/components/contentContainer";
import { MuiGridTable } from "@/components/tables/DataGridTable";
import { Paper, Button } from "@mui/material";
import { GridColDef, GridRowSelectionModel, GridToolbarContainer } from "@mui/x-data-grid-pro";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { LabelsResponse } from "../../../api/setup/types";
import { useColumns } from "@/hooks/useColumnsData";
import useData from "@/hooks/auth/useData";
import Snackbar, { SnackbarCloseReason } from '@mui/material/Snackbar';
import { Alert } from '@mui/material';
import CreateLabelModal from "./CreateLabelModal";

const Labels = () => {
  const { t } = useTranslation();
  const [selectedRows, setSelectedRows] = useState<GridRowSelectionModel>([]);
  const { selectedManufacturers, setupLabelsApi, setupLabelsUpdateApi } = useData();
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const manufacturerIds = useMemo(
    () => selectedManufacturers.map((m) => m.ID),
    [selectedManufacturers]
  );

  const rows = useMemo(() => {
    return (setupLabelsApi.data || []).map((row) => ({
      ...row,
      UPDATED: row.updated,
      id: row.ID,
    }));
  }, [setupLabelsApi.data]);

  useEffect(() => {
    if (!manufacturerIds.length) return;

    setupLabelsApi.execute({
      m: manufacturerIds,
    });
  }, [manufacturerIds]);

  const allColumns = useColumns();

  const columns: GridColDef<LabelsResponse>[] = [
    allColumns.labelColumn,
    allColumns.commentColumn,
    allColumns.itemsColumn,
    allColumns.typeColumn,
    allColumns.collaborationColumn,
    allColumns.ownerLabelsColumn,
    allColumns.updatedDateColumn,
    // allColumns.actionsColumn,
  ];

  const processRowUpdate = (newRow: LabelsResponse, oldRow: LabelsResponse) => {
    setupLabelsUpdateApi.execute({
      id: newRow.ID,
      name: newRow.name,
      comment: newRow.comment,
    }).catch((error) => {
      handleProcessRowUpdateError(error);
    });
    return newRow;
  };

  const handleCloseSnackbar = (event: React.SyntheticEvent | Event, reason?: SnackbarCloseReason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false);
  };

  const handleProcessRowUpdateError = useCallback((error: Error) => {
    setOpenSnackbar(true);
  }, []);

  const handleClose = () => {
    setOpenModal(false);
  };

  return (
    <ContentContainer>
      <Paper>
        <MuiGridTable
          rows={rows}
          columns={columns}
          onRowSelectionModelChange={(ids) => setSelectedRows(ids)}
          selectedRows={selectedRows}
          loading={setupLabelsApi?.isLoading}
          filterBarType={2}
          searchPlaceholder={t("pages.labels.search") as string}
          hasCheckboxes={false}
          title={t("pages.labels.title") as string}
          isError={setupLabelsApi.isError}
          error={setupLabelsApi.error}
          hasControls={true}
          processRowUpdate={processRowUpdate}
          onProcessRowUpdateError={handleProcessRowUpdateError}
          onCreateClick={() => setOpenModal(true)}
        />
      </Paper>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert onClose={handleCloseSnackbar} severity="error" sx={{ width: '100%' }}>
          {t("pages.labels.alerts.updateFailed")}
        </Alert>
      </Snackbar>
      
      <CreateLabelModal 
        open={openModal}
        listApi={setupLabelsApi}
        onClose={handleClose}
        manufacturerIds={manufacturerIds}
        onSuccess={ async () => {
          await setupLabelsApi.execute({ m: manufacturerIds });
          handleClose();
        }}
      />
    </ContentContainer>
  );
};

export default Labels;
