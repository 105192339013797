import React, { useEffect, useState, useMemo } from "react";
import { Box, FormControl, FormControlLabel, InputLabel, MenuItem, Select, Switch, Typography } from "@mui/material";
import { useTabsContext } from "@/contexts/TabsContext";
import useData from "@/hooks/auth/useData";
import { DefJsonResponse } from "@/api/buynow/types";
import { DefLocalConfig, defLocalContentConfig, defLocalSocialConfig } from "../consts";
import { t } from "i18next";

const ListingTab: React.FC = () => {
  const { defContentJsonApi, defSocialJsonApi } = useData();
  const { configs, setConfigs, setupProps, defaultConfigIndex } = useTabsContext();
  const currentConfig = configs[defaultConfigIndex].config.config as DefJsonResponse;
  const currentType = useMemo(() => setupProps?.type || 0, [setupProps]);
  const initialDefaultConfig = currentType === 0 ? defLocalContentConfig : defLocalSocialConfig;

  const [apiDefaultConfig, setApiDefaultConfig] = useState<DefJsonResponse | null>(null);
  const [isPrefilled, setIsPrefilled] = useState(false);

  const [defLocalConfig, setDefLocalConfig] = useState<DefLocalConfig>(initialDefaultConfig);

  const handleCerSorting = (cerSorting: number | Array<number>) => (Array.isArray(cerSorting) ? cerSorting : [cerSorting]);

  useEffect(() => {
    if (setupProps) {
      if (setupProps.type === 0) {
        defContentJsonApi.execute();
      } else {
        defSocialJsonApi.execute();
      }
    }
  }, [setupProps]);

  useEffect(() => {
    const contentJson = defContentJsonApi?.data ? defContentJsonApi.data : initialDefaultConfig;
    const socialJson = defSocialJsonApi?.data ? defSocialJsonApi?.data : initialDefaultConfig;
    const fetchedConfig = setupProps?.type === 0 ? contentJson : socialJson;
    if (fetchedConfig) {
      setApiDefaultConfig(fetchedConfig as DefJsonResponse);
      setIsPrefilled(false);
    }
  }, [defContentJsonApi.data, defSocialJsonApi.data]);

  // Prefill defLocalConfig based on currentConfig, apiDefaultConfig, or initialDefaultConfig
  useEffect(() => {
    if (!isPrefilled && (currentConfig || apiDefaultConfig)) {
      const fetchedSorting = currentConfig?.sorting
        ? handleCerSorting(currentConfig?.sorting)
          : apiDefaultConfig?.sorting ? handleCerSorting(apiDefaultConfig?.sorting) : initialDefaultConfig.sorting;

      console.log('fetchedSorting', fetchedSorting);
      
      setDefLocalConfig(() => ({
        layoutOffline: currentConfig?.layoutOffline ?? apiDefaultConfig?.layoutOffline ?? initialDefaultConfig.layoutOffline,
        label: {
          grouping: currentConfig?.label?.grouping ?? apiDefaultConfig?.label?.grouping ?? initialDefaultConfig.label.grouping,
        },
        sortingPreferred: currentConfig?.sortingPreferred ?? apiDefaultConfig?.sortingPreferred ?? initialDefaultConfig.sortingPreferred,
        sorting: fetchedSorting,
        posBranding: currentConfig?.posBranding ?? apiDefaultConfig?.posBranding ?? initialDefaultConfig.posBranding,
        price: currentConfig?.price ?? apiDefaultConfig?.price ?? initialDefaultConfig.price,
        ratings: currentConfig?.ratings ?? apiDefaultConfig?.ratings ?? initialDefaultConfig.ratings,
        stock: currentConfig?.stock ?? apiDefaultConfig?.stock ?? initialDefaultConfig.stock,
      }));

      console.log('currentConfig', currentConfig);
      console.log('apiDefaultConfig', apiDefaultConfig);
      console.log('defLocalConfig', defLocalConfig);
      apiDefaultConfig && setIsPrefilled(true);
    }
  }, [currentConfig, apiDefaultConfig, isPrefilled]);

  const handleSelectChange = (key: string, value: number) => {
    if (key === "sortingPreferred") {
      // Directly set sortingPreferred without impacting other criteria
      setDefLocalConfig((prev) => {
        const updatedState = {
          ...prev,
          sortingPreferred: value,
        };
        updateConfigsWithState(updatedState);
        return updatedState;
      });
    } else if (key.startsWith("sorting")) {
      const index = parseInt(key.replace("sorting", "")) - 1;
      const newSorting = [...handleCerSorting(defLocalConfig.sorting)];
      newSorting[index] = value;
  
      // Reset duplicates to 'None' for criteria other than the current index
      if (value !== 0) {
        newSorting.forEach((item, i) => {
          if (i !== index && item === value) {
            newSorting[i] = 0; // Reset duplicates to `None`
          }
        });
      }
  
      // If the current selection is 'None' or not the last criterion, reset following criteria
      if (value === 0 || (value !== 0 && index < newSorting.length - 1)) {
        for (let i = index + 1; i < newSorting.length; i++) {
          newSorting[i] = 0; // Set following criteria to "None"
        }
      }
  
      setDefLocalConfig((prev) => {
        const updatedState = { ...prev, sorting: newSorting };
        updateConfigsWithState(updatedState);
        return updatedState;
      });
    } else if (key.includes(".")) {
      const [parentKey, childKey] = key.split(".");
      setDefLocalConfig((prev) => {
        const updatedState = {
          ...prev,
          // @ts-ignore
          [parentKey]: { ...prev[parentKey], [childKey]: value },
        };
        updateConfigsWithState(updatedState);
        return updatedState;
      });
    } else {
      setDefLocalConfig((prev) => {
        const updatedState = { ...prev, [key]: value };
        updateConfigsWithState(updatedState);
        return updatedState;
      });
    }
  };
  

  const handleSwitchChange = (key: string, value: boolean) => {
    setDefLocalConfig((prev) => {
      const updatedState = { ...prev, [key]: value };
      updateConfigsWithState(updatedState);
      return updatedState;
    });
  };

  const updateConfigsWithState = (updatedState: typeof defLocalConfig) => {
    const filteredSorting = handleCerSorting(updatedState.sorting).filter(
      (criterion) => typeof criterion === 'number' && criterion !== 0
    );
  
    setConfigs((prevConfigs) => {
      const newConfigs = [...prevConfigs];
      // if (newConfigs[defaultConfigIndex]?.config?.config?.config) {
      //   delete newConfigs[defaultConfigIndex]?.config?.config?.config; // temporary to avoid false config structure
      // }
      newConfigs[defaultConfigIndex] = {
        // variants level
        ...newConfigs[defaultConfigIndex],
        config: {
          // comments level
          ...newConfigs[defaultConfigIndex].config,
          config: {
            // config details level
            ...newConfigs[defaultConfigIndex].config.config,
            ...updatedState,
            sorting: filteredSorting,
          },
        },
      };
      console.log(newConfigs[defaultConfigIndex]);
      
      return newConfigs;
    });
  };

  const isCriterionSelected = (criterion: number) => handleCerSorting(defLocalConfig.sorting).includes(criterion);

  return (
    <>
      <Typography variant="h6" sx={{ padding: "5px 0" }} gutterBottom>
        {t('pages.buyNowComponent.editModal.listing.tabs')}
      </Typography>
      <Box sx={{ display: "flex", justifyContent: "space-between", gap: 3 }}>
        <FormControl fullWidth margin="dense">
          <InputLabel>{t('pages.buyNowComponent.editModal.listing.offlineMerchants')}</InputLabel>
          <Select
            value={defLocalConfig.layoutOffline ?? 1}
            label={t('pages.buyNowComponent.editModal.listing.offlineMerchants')}
            onChange={(e) => handleSelectChange("layoutOffline", e.target.value as number)}
          >
            <MenuItem value={1}>{t('pages.buyNowComponent.editModal.listing.noSeparation')}</MenuItem>
            <MenuItem value={2}>{t('pages.buyNowComponent.editModal.listing.tabbedOnlineOffline')}</MenuItem>
            <MenuItem value={3}>{t('pages.buyNowComponent.editModal.listing.tabbedAiPharmaFinder')}</MenuItem>
          </Select>
        </FormControl>
        <FormControl fullWidth margin="dense">
          <InputLabel>{t('pages.buyNowComponent.editModal.listing.merchantListTitles')}</InputLabel>
          <Select
            value={defLocalConfig.label.grouping}
            label={t('pages.buyNowComponent.editModal.listing.merchantListTitles')}
            onChange={(e) => handleSelectChange("label.grouping", e.target.value as number)}
          >
            <MenuItem value={1}>Online/Offline</MenuItem>
            <MenuItem value={2}>{t('pages.buyNowComponent.editModal.listing.onlineAndLocalPharma')}</MenuItem>
          </Select>
        </FormControl>
      </Box>

      <Typography variant="h6" sx={{ padding: "5px 0" }} gutterBottom>
        {t("pages.buyNowComponent.editModal.listing.listSorting")}
      </Typography>
      <FormControl fullWidth margin="dense" sx={{ paddingBottom: 3 }}>
        <InputLabel>{t("pages.buyNowComponent.editModal.listing.sortingPreference")}</InputLabel>
        <Select
          value={defLocalConfig.sortingPreferred}
          label={t("pages.buyNowComponent.editModal.listing.sortingPreference")}
          onChange={(e) => handleSelectChange("sortingPreferred", e.target.value as number)}
        >
          <MenuItem value={1}>{t("pages.buyNowComponent.editModal.listing.none")}</MenuItem>
          <MenuItem value={2}>{t("pages.buyNowComponent.editModal.listing.partner")}</MenuItem>
          <MenuItem value={3}>{t("pages.buyNowComponent.editModal.listing.preferredMerchants")}</MenuItem>
        </Select>
      </FormControl>

      {[1, 2, 3, 4].map((num) => (
        <FormControl fullWidth margin="dense" sx={{ paddingBottom: 3 }} key={num} disabled={num > 1 && !defLocalConfig.sorting[num - 2]} >
          <InputLabel>{`${num}. ${t("pages.buyNowComponent.editModal.listing.sortingCriteria")}`}</InputLabel>
          <Select
            value={(handleCerSorting(defLocalConfig.sorting)[num - 1] || 0)}
            label={`${num}. ${t("pages.buyNowComponent.editModal.listing.sortingCriteria")}`}
            onChange={(e) => handleSelectChange(`sorting${num}`, e.target.value as number)}
          >
            {num !== 1 && <MenuItem value={0}>{t("pages.buyNowComponent.editModal.listing.none")}</MenuItem>}
            <MenuItem
              value={1}
              disabled={num !== 1 && isCriterionSelected(1)}
            >
              {t("pages.buyNowComponent.editModal.listing.priceAscending")}
            </MenuItem>
            <MenuItem
              value={2}
              disabled={num !== 1 && isCriterionSelected(2)}
            >
              {t("pages.buyNowComponent.editModal.listing.ratingsDescending")}
            </MenuItem>
            <MenuItem
              value={3}
              disabled={num !== 1 && isCriterionSelected(3)}
            >
              {t("pages.buyNowComponent.editModal.listing.ratingsAmount")}
            </MenuItem>
            <MenuItem
              value={4}
              disabled={num !== 1 && isCriterionSelected(4)}
            >
              {t("pages.buyNowComponent.editModal.listing.random")}
            </MenuItem>
          </Select>
        </FormControl>
      ))}


      <Typography variant="h6" sx={{ padding: "5px 0" }} gutterBottom>
        {t('pages.buyNowComponent.editModal.listing.posItems')}
      </Typography>
      <Box sx={{ display: "flex", gap: 3 }}>
        <FormControl sx={{ width: "50%" }} margin="dense">
          <InputLabel>{t('pages.buyNowComponent.editModal.listing.logoForm')}</InputLabel>
          <Select
            value={defLocalConfig.posBranding}
            label={t('pages.buyNowComponent.editModal.listing.logoForm')}
            onChange={(e) => handleSelectChange("posBranding", e.target.value as number)}
          >
            <MenuItem value={1}>{t('pages.buyNowComponent.editModal.listing.roundIcons')}</MenuItem>
            <MenuItem value={2}>{t('pages.buyNowComponent.editModal.listing.logos')}</MenuItem>
          </Select>
        </FormControl>
        <FormControlLabel
          sx={{ width: "50%" }}
          control={<Switch checked={defLocalConfig.price || false} onChange={(e) => handleSwitchChange("price", e.target.checked)} />}
          label={t('pages.buyNowComponent.editModal.listing.price')}
        />
      </Box>

      <Box sx={{ display: "flex", width: "100%", paddingLeft: "7px" }}>
        <FormControlLabel
          sx={{ width: "50%" }}
          control={<Switch checked={defLocalConfig?.ratings || false} onChange={(e) => handleSwitchChange("ratings", e.target.checked)} />}
          label={t('pages.buyNowComponent.editModal.listing.ratings')}
        />
        <FormControlLabel
          sx={{ width: "50%" }}
          control={<Switch checked={defLocalConfig.stock || false} onChange={(e) => handleSwitchChange("stock", e.target.checked)} />}
          label={t('pages.buyNowComponent.editModal.listing.available')}
        />
      </Box>
    </>
  );
};

export default ListingTab;
