import styled from "@emotion/styled";
import React, { useState } from "react";
import { NavLink, useLocation } from "react-router-dom";

import { ListItemButton, Drawer as MuiDrawer } from "@mui/material";

import SidebarNav from "./SidebarNav";
import { ReactComponent as MavenLogoIcon } from "../../assets/svg/maven360-icon.svg";
import { ReactComponent as MavenLogoText } from "../../assets/svg/maven360-text.svg";
import { SidebarFooter } from "./SidebarFooter";
import { SidebarItemsType } from "@/types/sidebar";
import { Routes } from "@/consts/routes";

const Drawer = styled(MuiDrawer)`
  border-right: 0;

  > div {
    border-right: 0;
    scrollbar-width: none;
  }
`;

const Brand = styled(ListItemButton)<{
  component?: React.ReactNode;
  to?: string;
}>`
  display: flex;
  flex-grow: 0;
  background-color: ${(props) => props.theme.sidebar.header.background};
  justify-content: flex-start;
  padding: 20px 24px;
  height: 64px;
  gap: 12px;

  svg {
    
    g:first-of-type {
      fill: white;
    }
  }

  &:hover {
    background-color: ${(props) => props.theme.sidebar.header.background};
  }
`;

export type SidebarProps = {
  PaperProps: {
    style: {
      width: number;
    };
  };
  variant?: "permanent" | "persistent" | "temporary";
  open?: boolean;
  onClose?: () => void;
  items: {
    title: string;
    pages: SidebarItemsType[];
  }[];
  showFooter?: boolean;
};

const Sidebar: React.FC<SidebarProps> = ({
  items,
  showFooter = true,
  ...rest
}) => {
  const location = useLocation();
  const [navPath, setNavPath] = useState<string>(location.pathname);

  return (
    <Drawer variant="permanent" {...rest}>
      <Brand component={NavLink as any} to={Routes.startPage} onClick={() => setNavPath(Routes.startPage)}>
        <MavenLogoIcon />
        <MavenLogoText />
      </Brand>
      <SidebarNav items={items} navPath={navPath} setNavPath={setNavPath}/>
      {!!showFooter && <SidebarFooter />}
    </Drawer>
  );
};

export default Sidebar;
