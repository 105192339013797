import React, { useEffect, useState, useMemo } from "react";
import { BuyNowTransactionsParams } from "@/api/useBuyNowTransactions";
import useData from "@/hooks/auth/useData";
import ContentContainer from "@/components/contentContainer";
import MuiGridTable from "@/components/tables/DataGridTable";
import { Paper } from "@mui/material";
import { useColumns } from "@/hooks/useColumnsData";
import { GridColDef } from "@mui/x-data-grid-pro";
import { t } from "i18next";

export const BuyNowTransactions = () => {
  const {
    buyNowTransactionsApi,
    selectedManufacturers,
    dateRange,
    daysChanged,
    isLoading,
  } = useData();
  const columns = useColumns();

  const executeApiCall = () => {
    const id = selectedManufacturers.map((m) => m?.ID);
    if (buyNowTransactionsApi && id) {
      const params: BuyNowTransactionsParams = {
        manufacturers: id,
        from_date: dateRange.from_date,
        to_date: dateRange.to_date,
      };
      buyNowTransactionsApi.execute(params);
    }
  };

  useEffect(() => {
    executeApiCall();
  }, [selectedManufacturers, dateRange, daysChanged]);

  const nodes = useMemo(() => {
    return buyNowTransactionsApi?.data || [];
  }, [buyNowTransactionsApi?.data]);

  const myDataGridColumns: Array<GridColDef> = [
    { ...columns.posTransColumn, flex: 0.5 },
    columns.pznTransColumn,
    columns.productTransColumn,
    {
      ...columns.itemsColumn,
      width: 50,
      align: "right",
    },
    columns.priceValueColumn,
    columns.timeLeadTransColumn,
    { ...columns.propertyTransColumn, flex: 0.5 },
  ];

  return (
    <ContentContainer>
      <Paper>
        <MuiGridTable
          filterBarType={2}
          rows={nodes}
          columns={myDataGridColumns}
          loading={buyNowTransactionsApi?.isLoading || !buyNowTransactionsApi?.isExecuted} // for data grid pro it does not work
          getTreeDataPath={(row) => row.hierarchy || []}
          treeData
          groupingColDef={columns.groupingTransColDef}
          getRowClassName={(params) => {
            if (params.row.type === "product" || params.row.keyword) {
              return "gray-50-row";
            }
            return "";
          }}
          initialPageSize={100}
          tableInfo={t("tableInfo.buyNowTransactions") || ""}
          searchPlaceholder={
            t("tableColumns.searchPlaceholder") || "SKU, Products, ..."
          }
          isError={buyNowTransactionsApi?.isError || false}
          error={buyNowTransactionsApi?.error || ""}
        />
      </Paper>
    </ContentContainer>
  );
};
