import React from "react";
import { useTranslation } from "react-i18next";

export const VisitsTooltip = () => {
  const { t } = useTranslation();
  return (
    <div>
      <p>{t("pages.analytics.tooltip.visitsP1")}</p>
      <p>{t("pages.analytics.tooltip.visitsP2")}</p>
      <p>{t("pages.analytics.tooltip.visitsP3")}</p>
      <p>{t("pages.analytics.tooltip.visitsP4")}</p>
      <p>{t("pages.analytics.tooltip.visitsP5")}</p>
      <p>{t("pages.analytics.tooltip.visitsP6")}</p>
    </div>
  );
};
