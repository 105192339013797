import { DataContext } from "@/contexts/DataContext";
import { useContext } from "react";

const useData = () => {
  const context = useContext(DataContext);

  if (!context)
    throw new Error("AuthContext must be placed within AuthProvider");

  return context;
};

export default useData;
