import axios from "axios";
import { UserResponse } from "@/types/user";
import { triggerGlobalLogout } from '@/utils/globalLogout';

const apiUrl = import.meta.env.VITE_BASE_SERVER_URL;

/**
 * Function for refreshing the access token
 * 
 * @param {AbortSignal} signal - abort signal
 * @returns {Promise<UserResponse>} - refresh response
 */
export const refresh = async (signal?: AbortSignal): Promise<UserResponse> => {
  const requestOptions = {
    withCredentials: true,
    signal,
  };
  try {
    const response = await axios.post<UserResponse>(
        `${apiUrl}/refresh`,
        null, // no body necessary
        requestOptions
      );
      
      if (!response.data) {
        throw new Error("No data found");
      }
    
      return response.data;
  } catch(error: unknown) {
    triggerGlobalLogout();
    throw error;
  }
};
