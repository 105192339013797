import React from "react";
import styled from "@emotion/styled";
import { List } from "@mui/material";

import SidebarNavSection from "./SidebarNavSection";
import { SidebarItemsType } from "@/types/sidebar";

const Wrapper = styled.div`
  background-color: ${(props) => props.theme.sidebar.background};
  border-right: 1px solid rgba(0, 0, 0, 0.12);
  flex-grow: 1;
`;

type SidebarNavProps = {
  items: {
    title: string;
    pages: SidebarItemsType[];
  }[];
  navPath: string;
  setNavPath:  React.Dispatch<React.SetStateAction<string>>;
};

const SidebarNav: React.FC<SidebarNavProps> = ({ items, navPath, setNavPath }) => {

  return (
    <Wrapper>
      <List disablePadding>
        <div>
          {items &&
            items.map((item) => (
              <SidebarNavSection
                component="div"
                key={item.title}
                pages={item.pages}
                title={item.title}
                navPath={navPath}
                setNavPath={setNavPath}
              />
            ))}
        </div>
      </List>
    </Wrapper>
  );
};

export default SidebarNav;
