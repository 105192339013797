import { useState, FC } from 'react';
import { Box } from '@mui/material';
import { ImageCellProps } from './types';
import NoPhotographyIcon from '@mui/icons-material/NoPhotography';

const ImageCell: FC<ImageCellProps>  = ({ id, imageUrl }) => {
    const [isError, setIsError] = useState<boolean>(false);
  
    return (
        isError ?
            (<Box sx={{
                width: "100%",
                height: "100%", 
                display: "flex", 
                justifyContent: "center",
                alignItems: "center",
                }}>
                <NoPhotographyIcon fontSize='large'/>
            </Box>) :
            (<img
                src={imageUrl}
                alt={`Product image ${id}`}
                width="200px"
                height="auto"
                onError={() => setIsError(true)}
            />)
    );
};

export default ImageCell;

  