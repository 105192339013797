import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import {
  Box,
  Grid,
  Paper,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography,
  useTheme,
} from "@mui/material";
import ProgressBar from "@/pages/analytics/components/ProgressBar";
import { AnalyticHeadCellIds } from "../constants";
import FilterComponent from "./Filter";
import { useTranslation } from "react-i18next";
import { getErrorMessage } from "@/utils/getErrorMassge";
import BaseError from "@/components/errors/baseError";

interface Props {
  initialSortBy: string;
  headCells: HeadCell[];
  rows: RowType[];
  itemCount?: number;
  progressBar?: string;
  simplePagination?: boolean;
  isLoading?: boolean;
  filterOpen?: boolean;
  filterColumn?: string;
  setFilterOpen?: (value: boolean) => void;
  isError?: boolean;
  error: string | null;
}

const TableContainerStyled = styled(TableContainer)(({ theme }) => ({
  maxHeight: "100%",
  height: "100%",
  overflowX: "scroll",
  overflowY: "auto",

  "::-webkit-scrollbar": {
    width: "0px",
    height: "4px",
  },
  "::-webkit-scrollbar-thumb": {
    backgroundColor: theme.palette.grey[400],
    borderRadius: "4px",
  },
  "::-webkit-scrollbar-track": {
    backgroundColor: theme.palette.background.paper,
  },
  "::-webkit-scrollbar-thumb:hover": {
    backgroundColor: theme.palette.grey[600],
  },
}));

const TableHeadStyled = styled(TableHead)(({ theme }) => ({
  position: "sticky",
  top: 0,
  zIndex: 1,
  backgroundColor: theme.palette.background.paper,
}));

const CustomTableCell = styled(TableCell)<{
  columnIndex: number;
  totalColumns: number;
  isCrHead?: boolean;
}>`
  ${(props) => {
    const { columnIndex, totalColumns } = props;

    if (totalColumns === 2) {
      return columnIndex === 0 ? "width: 41%;" : "width: 59%;";
    }
  }}
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 5px 7px !important;

  ${({ isCrHead }) => {
    if (isCrHead) {
      return `
        width: 50px;
        padding-left: 15px !important;
      `;
    }
  }}
`;

export type RowType = {
  [key: string]: any;
};

export type HeadCell = {
  id: string;
  disablePadding?: boolean;
  label: string;
};

function descendingComparator(a: RowType, b: RowType, orderBy: string) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order: "desc" | "asc", orderBy: string) {
  return order === "desc"
    ? (a: RowType, b: RowType) => descendingComparator(a, b, orderBy)
    : (a: RowType, b: RowType) => -descendingComparator(a, b, orderBy);
}

function stableSort(
  array: Array<RowType>,
  comparator: (a: RowType, b: RowType) => number
) {
  const stabilizedThis = array.map((el: RowType, index: number) => ({
    el,
    index,
  }));
  stabilizedThis.sort((a, b) => {
    const order = comparator(a.el, b.el);
    if (order !== 0) return order;
    return a.index - b.index;
  });
  return stabilizedThis.map((element) => element.el);
}

type EnhancedTableHeadProps = {
  order: "desc" | "asc";
  orderBy: string;
  onRequestSort: (e: any, property: string) => void;
  headCells: HeadCell[];
};

const EnhancedTableHead: React.FC<EnhancedTableHeadProps> = (props) => {
  const { headCells, order, orderBy, onRequestSort } = props;
  const createSortHandler = (property: string) => (event: any) => {
    onRequestSort(event, property);
  };

  return (
    <TableHeadStyled>
      <TableRow>
        {headCells?.map((headCell, index) => (
          <CustomTableCell
            key={headCell.id}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
            columnIndex={index}
            totalColumns={headCells.length}
            isCrHead={headCell.id === AnalyticHeadCellIds.CR}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
            </TableSortLabel>
          </CustomTableCell>
        ))}
      </TableRow>
    </TableHeadStyled>
  );
};

const EnhancedTable: React.FC<Props> = (props) => {
  const {
    initialSortBy,
    headCells,
    rows,
    itemCount,
    progressBar,
    simplePagination,
    isLoading,
    filterOpen,
    filterColumn,
    setFilterOpen,
    isError,
    error,
  } = props;

  const [order, setOrder] = useState<"desc" | "asc">("desc");
  const [orderBy, setOrderBy] = useState(initialSortBy);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(itemCount || 10);
  const [maxValue, setMaxValue] = useState(0);
  const [filteredRows, setFilteredRows] = useState<RowType[]>(rows);
  const [selectedElements, setSelectedElements] = useState<string[]>([]);
  const theme = useTheme();

  const { t } = useTranslation();

  useEffect(() => {
    setFilteredRows(rows);
  }, [rows]);

  const handleFilterClose = () => {
    setFilterOpen && setFilterOpen(false);
  };

  const handleRequestSort = (event: any, property: string) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows =
    rowsPerPage -
    Math.min(rowsPerPage, filteredRows.length - page * rowsPerPage);

  useEffect(() => {
    if (progressBar) {
      const maxValue = Math.max(...filteredRows.map((row) => row[progressBar]));
      setMaxValue(maxValue);
    }
  }, [filteredRows]);

  const handleFilter = (selected: string[]) => {
    setSelectedElements(selected);
    setFilteredRows(
      rows.filter((row) => selected.includes(row[filterColumn ?? ""]))
    );
  };

  const uniqueElements = Array.from(
    new Set(rows.map((row) => row[filterColumn ?? ""]))
  );

  const translatedValue = headCells.find((cell) => cell.id === filterColumn);

  return (
    <Paper
      sx={{
        height: simplePagination ? 300 : "auto",
        overflow: "auto",
      }}
    >
      {filterOpen && (
        <FilterComponent
          elements={uniqueElements}
          selectedElements={selectedElements}
          onApplyFilter={handleFilter}
          onClose={handleFilterClose}
          filterColumn={translatedValue?.label ?? t("general.values")}
        />
      )}
      <TableContainerStyled>
        {isError ? (
          <BaseError text={error || ""} />
        ) : (
          <Table
            aria-labelledby="tableTitle"
            aria-label="enhanced table"
            style={{ tableLayout: "fixed" }}
          >
            <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              headCells={headCells}
            />
            <TableBody>
              {isLoading
                ? Array.from(new Array(rowsPerPage)).map((_, index) => (
                    <TableRow key={index}>
                      {headCells.map((headCell, columnIndex) => (
                        <CustomTableCell
                          key={headCell.id}
                          columnIndex={columnIndex}
                          totalColumns={headCells.length}
                        >
                          <Skeleton variant="rectangular" height={27} />
                        </CustomTableCell>
                      ))}
                    </TableRow>
                  ))
                : stableSort(filteredRows, getComparator(order, orderBy))
                    .slice(
                      simplePagination ? 0 : page * rowsPerPage,
                      simplePagination
                        ? filteredRows.length
                        : page * rowsPerPage + rowsPerPage
                    )
                    .map((row, index) => {
                      const labelId = `enhanced-table-checkbox-${index}`;

                      return (
                        <TableRow hover tabIndex={-1} key={row.id}>
                          {headCells.map((headCell, columnIndex) => (
                            <CustomTableCell
                              key={headCell.id}
                              id={labelId}
                              columnIndex={columnIndex}
                              totalColumns={headCells.length}
                              align={
                                headCell.id === AnalyticHeadCellIds.CR
                                  ? "right"
                                  : "left"
                              }
                            >
                              {headCell.id === AnalyticHeadCellIds.CR ? (
                                row.CR
                              ) : headCell.id === progressBar ? (
                                <ProgressBar
                                  maxValue={maxValue}
                                  value={row[headCell.id]}
                                  color={theme.palette.primary.main}
                                />
                              ) : (
                                row[headCell.id]
                              )}
                            </CustomTableCell>
                          ))}
                        </TableRow>
                      );
                    })}
              {!simplePagination && emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={headCells.length} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        )}
      </TableContainerStyled>
      {!simplePagination && (
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={filteredRows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
    </Paper>
  );
};

const AnalyticsTable: React.FC<Props> = (props) => {
  return (
    <React.Fragment>
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <EnhancedTable {...props} />
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default AnalyticsTable;
