import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import { useTabsContext } from "@/contexts/TabsContext";
import { validationRules } from "../consts";
import { useTranslation } from "react-i18next";

const defaultConfigState = {
  style: {
    btnColor: "#CCCCCC",
    btnTextColor: "#CCCCCC",
    primaryColor: "#CCCCCC",
    textColor: "#CCCCCC",
    headerTextColor: "#CCCCCC",
  },
  brand: {
    logoUrl: "",
    logoFormat: {
      width: 0,
      height: 0,
      align: "",
    },
  },
  avgRating: false,
  label: {
    headerIcon: false,
    headerBadge: false,
  },
};

const LookTab: React.FC = () => {
  const {
    configs,
    setConfigs,
    defaultConfigIndex,
    setValidationErrors,
    setWarnings,
    warnings,
  } = useTabsContext();
  const currentConfig = configs[defaultConfigIndex].config.config;

  console.log(currentConfig, "configs");

  const { t } = useTranslation();

  const [configState, setConfigState] = useState(defaultConfigState);

  const handleReset = () => {
    const lookTabDefaultConfig = {
      style: defaultConfigState.style,
      brand: defaultConfigState.brand,
      avgRating: defaultConfigState.avgRating,
      label: defaultConfigState.label,
    };

    setConfigState((prevState) => ({
      ...prevState,
      ...lookTabDefaultConfig,
    }));

    setConfigs((prevConfigs) =>
      prevConfigs.map((config, idx) =>
        idx === defaultConfigIndex
          ? {
              ...config,
              config: {
                ...config.config,
                config: {
                  ...config.config.config,
                  ...lookTabDefaultConfig,
                },
              },
            }
          : config
      )
    );

    setWarnings({});
    setValidationErrors((prevErrors) => ({
      ...prevErrors,
      lookTab: {},
    }));
  };

  const validateField = (field: string, value: string) => {
    const updatedWarnings = { ...warnings };

    if (field === "logoUrl") {
      if (value.trim() === "") {
        delete updatedWarnings[field];
      } else if (!validationRules.url.pattern.test(value)) {
        updatedWarnings[field] = validationRules.url.message;
      } else {
        delete updatedWarnings[field];
      }
    } else if (
      [
        "btnColor",
        "btnTextColor",
        "primaryColor",
        "textColor",
        "headerTextColor",
      ].includes(field)
    ) {
      if (!validationRules.color.pattern.test(value)) {
        updatedWarnings[field] = validationRules.color.message;
      } else {
        delete updatedWarnings[field];
      }
    } else if (field === "width" || field === "height") {
      const rule = validationRules[field];
      if (!rule.pattern.test(value)) {
        updatedWarnings[field] = rule.message;
      } else {
        delete updatedWarnings[field];
      }
    }

    setWarnings(updatedWarnings);
    setValidationErrors((prevErrors) => ({
      ...prevErrors,
      lookTab: { ...updatedWarnings },
    }));
  };

  const updateConfigStyle = (config: any, field: string, value: any) => ({
    ...config,
    config: {
      ...config.config,
      config: {
        ...config.config?.config,
        style: {
          ...config.config?.config?.style,
          [field]: value,
        },
      },
    },
  });

  const updateConfigBrand = (config: any, field: string, value: any) => ({
    ...config,
    config: {
      ...config.config,
      config: {
        ...config.config?.config,
        brand: {
          ...config.config?.config?.brand,
          [field]: value,
        },
      },
    },
  });

  const updateConfigLabel = (config: any, field: string, value: any) => ({
    ...config,
    config: {
      ...config.config,
      config: {
        ...config.config?.config,
        label: {
          ...config.config?.config?.label,
          [field]: value,
        },
      },
    },
  });

  const updateConfigAvgRating = (config: any, value: boolean) => ({
    ...config,
    config: {
      ...config.config,
      config: {
        ...config.config?.config,
        avgRating: value,
      },
    },
  });

  const updateConfigLogoFormat = (config: any, field: string, value: any) => ({
    ...config,
    config: {
      ...config.config,
      config: {
        ...config.config?.config,
        brand: {
          ...config.config?.config?.brand,
          logoFormat: {
            ...config.config?.config?.brand?.logoFormat,
            [field]: value,
          },
        },
      },
    },
  });

  useEffect(() => {
    if (currentConfig) {
      setConfigState({
        style: {
          btnColor: currentConfig.style?.btnColor ?? "#CCCCCC",
          btnTextColor: currentConfig.style?.btnTextColor ?? "#CCCCCC",
          primaryColor: currentConfig.style?.primaryColor ?? "#CCCCCC",
          textColor: currentConfig.style?.textColor ?? "#CCCCCC",
          headerTextColor: currentConfig.style?.headerTextColor ?? "#CCCCCC",
        },
        brand: {
          logoUrl: currentConfig.brand?.logoUrl ?? "",
          logoFormat: {
            width: currentConfig.brand?.logoFormat?.width ?? 0,
            height: currentConfig.brand?.logoFormat?.height ?? 0,
            align: currentConfig.brand?.logoFormat?.align ?? "",
          },
        },
        avgRating: currentConfig?.avgRating ?? false,
        label: {
          headerIcon: currentConfig.label?.headerIcon ?? false,
          headerBadge: currentConfig.label?.headerBadge ?? false,
        },
      });
    }
  }, [defaultConfigIndex]);

  const alignmentOptions = [
    { label: t("pages.buyNowComponent.editModal.look.left"), name: "left" },
    { label: t("pages.buyNowComponent.editModal.look.center"), name: "center" },
    { label: t("pages.buyNowComponent.editModal.look.right"), name: "right" },
  ];

  const handleChange = (field: string, value: any) => {
    setConfigState((prevState) => ({
      ...prevState,
      [field]: value,
    }));

    setConfigs((prevConfigs) =>
      prevConfigs.map((config, idx) =>
        idx === defaultConfigIndex
          ? updateConfigAvgRating(config, value)
          : config
      )
    );
  };

  const handleStyleChange = (styleField: string, value: any) => {
    setConfigState((prevState) => ({
      ...prevState,
      style: {
        ...prevState.style,
        [styleField]: value,
      },
    }));

    validateField(styleField, value);

    setConfigs((prevConfigs) =>
      prevConfigs.map((config, idx) =>
        idx === defaultConfigIndex
          ? updateConfigStyle(config, styleField, value)
          : config
      )
    );
  };

  const handleLabelChange = (labelField: string, value: boolean) => {
    setConfigState((prevState) => ({
      ...prevState,
      label: {
        ...prevState.label,
        [labelField]: value,
      },
    }));
    setConfigs((prevConfigs) =>
      prevConfigs.map((config, idx) =>
        idx === defaultConfigIndex
          ? updateConfigLabel(config, labelField, value)
          : config
      )
    );
  };

  const handleBrandChange = (brandField: string, value: any) => {
    setConfigState((prevState) => ({
      ...prevState,
      brand: {
        ...prevState.brand,
        [brandField]: value,
      },
    }));

    validateField(brandField, value);

    setConfigs((prevConfigs) =>
      prevConfigs.map((config, idx) =>
        idx === defaultConfigIndex
          ? updateConfigBrand(config, brandField, value)
          : config
      )
    );
  };

  const handleLogoFormatChange = (formatField: string, value: any) => {
    setConfigState((prevState) => ({
      ...prevState,
      brand: {
        ...prevState.brand,
        logoFormat: {
          ...prevState.brand.logoFormat,
          [formatField]: value,
        },
      },
    }));

    validateField(formatField, value);

    setConfigs((prevConfigs) =>
      prevConfigs.map((config, idx) =>
        idx === defaultConfigIndex
          ? updateConfigLogoFormat(config, formatField, value)
          : config
      )
    );
  };

  return (
    <Box sx={{ p: 2 }}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="flex-start"
      >
        <Typography variant="subtitle1">
          {" "}
          {t("pages.buyNowComponent.editModal.look.colorSettings")}
        </Typography>
        <Button variant="text" color="primary" onClick={handleReset}>
          {t("pages.buyNowComponent.editModal.look.reset")}
        </Button>
      </Box>

      <Box display="flex" alignItems="flex-start" sx={{ mb: 2 }}>
        <input
          type="color"
          value={configState.style.primaryColor}
          onChange={(e) => handleStyleChange("primaryColor", e.target.value)}
          style={{
            width: "51px",
            height: "55px",
            border: "none",
            padding: 0,
          }}
        />
        <TextField
          label={t("pages.buyNowComponent.editModal.look.primaryColor")}
          value={configState.style.primaryColor}
          onChange={(e) => handleStyleChange("primaryColor", e.target.value)}
          fullWidth
          sx={{ flex: 1, ml: 2 }}
          error={!!warnings["primaryColor"]}
          helperText={warnings["primaryColor"] || ""}
        />
      </Box>

      <Divider sx={{ my: 2 }} />

      <Typography variant="subtitle1" mb={2}>
        {t("pages.buyNowComponent.editModal.look.header")}
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Box display="flex" alignItems="flex-start">
            <input
              type="color"
              value={configState.style.headerTextColor}
              onChange={(e) =>
                handleStyleChange("headerTextColor", e.target.value)
              }
              style={{
                width: "51px",
                height: "55px",
                border: "none",
                padding: 0,
              }}
            />
            <TextField
              label={t("pages.buyNowComponent.editModal.look.textColor")}
              value={configState.style.headerTextColor}
              onChange={(e) =>
                handleStyleChange("headerTextColor", e.target.value)
              }
              fullWidth
              sx={{ flex: 1, ml: 2 }}
              error={!!warnings["headerTextColor"]}
              helperText={warnings["headerTextColor"] || ""}
            />
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box display="flex" alignItems="flex-start">
            <input
              type="color"
              value={configState.style.textColor}
              onChange={(e) => handleStyleChange("textColor", e.target.value)}
              style={{
                width: "51px",
                height: "55px",
                border: "none",
                padding: 0,
              }}
            />
            <TextField
              label={t("pages.buyNowComponent.editModal.look.color")}
              value={configState.style.textColor}
              onChange={(e) => handleStyleChange("textColor", e.target.value)}
              fullWidth
              sx={{ flex: 1, ml: 2 }}
              error={!!warnings["textColor"]}
              helperText={warnings["textColor"] || ""}
            />
          </Box>
        </Grid>
      </Grid>

      <Divider sx={{ my: 2 }} />

      <Typography variant="subtitle1" mb={2}>
        {t("pages.buyNowComponent.editModal.look.button")}
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Box display="flex" alignItems="flex-start">
            <input
              type="color"
              value={configState.style.btnTextColor}
              onChange={(e) =>
                handleStyleChange("btnTextColor", e.target.value)
              }
              style={{
                width: "51px",
                height: "55px",
                border: "none",
                padding: 0,
              }}
            />
            <TextField
              label={t("pages.buyNowComponent.editModal.look.textColor")}
              value={configState.style.btnTextColor}
              onChange={(e) =>
                handleStyleChange("btnTextColor", e.target.value)
              }
              fullWidth
              sx={{ flex: 1, ml: 2 }}
              error={!!warnings["btnTextColor"]}
              helperText={warnings["btnTextColor"] || ""}
            />
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box display="flex" alignItems="flex-start">
            <input
              type="color"
              value={configState.style.btnColor}
              onChange={(e) => handleStyleChange("btnColor", e.target.value)}
              style={{
                width: "51px",
                height: "55px",
                border: "none",
                padding: 0,
              }}
            />
            <TextField
              label={t("pages.buyNowComponent.editModal.look.color")}
              value={configState.style.btnColor}
              onChange={(e) => handleStyleChange("btnColor", e.target.value)}
              fullWidth
              sx={{ flex: 1, ml: 2 }}
              error={!!warnings["btnColor"]}
              helperText={warnings["btnColor"] || ""}
            />
          </Box>
        </Grid>
      </Grid>

      <Divider sx={{ my: 2 }} />

      <Typography variant="subtitle1" mb={2}>
        {t("pages.buyNowComponent.editModal.look.brandLogo")}
      </Typography>
      <TextField
        label={t("pages.buyNowComponent.editModal.look.url")}
        placeholder="http"
        fullWidth
        sx={{ mb: 2 }}
        value={configState.brand.logoUrl}
        onChange={(e) => handleBrandChange("logoUrl", e.target.value)}
        error={!!warnings["logoUrl"]}
        helperText={warnings["logoUrl"] || ""}
      />
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TextField
            label={t("pages.buyNowComponent.editModal.look.width")}
            fullWidth
            value={configState.brand.logoFormat.width}
            onChange={(e) =>
              handleLogoFormatChange("width", parseInt(e.target.value, 10))
            }
            error={!!warnings["width"]}
            helperText={warnings["width"] || ""}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label={t("pages.buyNowComponent.editModal.look.height")}
            fullWidth
            value={configState.brand.logoFormat.height}
            onChange={(e) =>
              handleLogoFormatChange("height", parseInt(e.target.value, 10))
            }
            error={!!warnings["height"]}
            helperText={warnings["height"] || ""}
          />
        </Grid>
      </Grid>

      <Typography variant="subtitle1" mt={2}>
        {t("pages.buyNowComponent.editModal.look.alignment")}
      </Typography>
      <Box display="flex" justifyContent="space-between" sx={{ my: 2 }}>
        {alignmentOptions.map((option) => (
          <FormControlLabel
            key={option.name}
            control={
              <Checkbox
                checked={configState.brand.logoFormat.align === option.name}
                onChange={() => handleLogoFormatChange("align", option.name)}
                icon={<RadioButtonUncheckedIcon />}
                checkedIcon={<RadioButtonCheckedIcon />}
                sx={{
                  color: "primary.main",
                  "&.Mui-checked": {
                    color: "primary.main",
                  },
                  borderRadius: "100%",
                }}
              />
            }
            label={option.label}
          />
        ))}
      </Box>

      <Divider sx={{ my: 2 }} />

      <Typography variant="subtitle1">
        {" "}
        {t("pages.buyNowComponent.editModal.look.headerSettings")}
      </Typography>
      <Box display="flex" flexWrap="wrap">
        <Box display="flex" alignItems="center">
          <Switch
            checked={configState.label.headerBadge}
            onChange={(e) => handleLabelChange("headerBadge", e.target.checked)}
          />
          <Typography>
            ‘BUY NOW’ {t("pages.buyNowComponent.editModal.look.badge")}
          </Typography>
        </Box>
        <Box display="flex" alignItems="center">
          <Switch
            checked={configState.label.headerIcon}
            onChange={(e) => handleLabelChange("headerIcon", e.target.checked)}
          />
          <Typography>
            {t("pages.buyNowComponent.editModal.look.showIcon")}
          </Typography>
        </Box>
        <Box display="flex" alignItems="center">
          <Switch
            checked={configState.avgRating}
            onChange={(e) => handleChange("avgRating", e.target.checked)}
          />
          <Typography>
            {t("pages.buyNowComponent.editModal.look.showAverageRating")}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default LookTab;
