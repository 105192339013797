import React from "react";
import { useTranslation } from "react-i18next";

export const LeadsTooltip = () => {
  const { t } = useTranslation();
  return (
    <div>
      <p>{t("pages.analytics.tooltip.leadsP1")}</p>
      <p>{t("pages.analytics.tooltip.leadsP2")}</p>
      <p>{t("pages.analytics.tooltip.leadsP3")}</p>
      <p>{t("pages.analytics.tooltip.leadsP4")}</p>
      <p>{t("pages.analytics.tooltip.leadsP5")}</p>
      <p>{t("pages.analytics.tooltip.leadsP6")}</p>
      <p>{t("pages.analytics.tooltip.leadsP7")}</p>
      <p>{t("pages.analytics.tooltip.leadsP8")}</p>
      <p>{t("pages.analytics.tooltip.leadsP9")}</p>
    </div>
  );
};
