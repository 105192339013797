// Layouts
import ErrorLayout from "@mira/layouts/Error";
import AuthLayout from "../layouts/Auth";
import AuthCoverLayout from "../layouts/AuthCover";
import DashboardLayout from "../layouts/Dashboard";

// Auth components
import Page404 from "@mira/pages/auth/Page404";
import AuthGuard from "../components/guards/AuthGuard";
import SignIn from "../pages/auth/SignIn";

import Analytics from "@/pages/analytics/Analytics";
import { VisibilityShareOfSearch } from "@/pages/visibility/ShareOfVoice";
import AiOptimizer from "@/pages/content/AiOptimizer";
import Content from "@/pages/content/contentBoard";
import { BuyNowTransactions } from "@/pages/buynow/transactions/index";
import RatingsAndReviews from "@/pages/content/RatingsAndReviews";
import Labels from "@/pages/setup/Labels";
import BuyNowProperties from "@/pages/buynow/properties";
import Keywords from "@/pages/setup/Keywords";
import BuyNowComponents from "@/pages/buynow/components";
import Availability from "@/pages/availability/main";
import Products from "@/pages/setup/Products";
import { Markets } from "@/pages/setup/Markets";
import Categories from "@/pages/setup/Categories";
import ProductListings from "@/pages/availability/productListings";
import PricingOverview from "@/pages/pricing/overview";
import PackshotsAndVideos from "@/pages/content/PackshotsAndVideos";
import Default from "@/pages/default";

export enum Routes {
  home = "/",
  signIn = "/auth-cover/sign-in",
  startPage = "/",
}

const routes = [
  {
    path: Routes.home,
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "/",
        element: <Default />,
      },
      {
        path: "/pricing/overview",
        element: <PricingOverview />,
      },
      {
        path: "/buy-now/dashboard",
        element: <Analytics />,
      },
      {
        path: "/buy-now/transactions",
        element: <BuyNowTransactions />,
      },
      {
        path: "/buy-now/properties",
        element: <BuyNowProperties />,
      },
      {
        path: "/buy-now/components",
        element: <BuyNowComponents />,
      },
      {
        path: "/visibility/share-of-search",
        element: <VisibilityShareOfSearch />,
      },
      {
        path: "/content/merchants-product-catalog",
        element: <Content />,
      },
      {
        path: "/content/ai-optimizer",
        element: <AiOptimizer />,
      },
      {
        path: "/content/ratings-and-reviews",
        element: <RatingsAndReviews />,
      },
      {
        path: "/content/packshots-and-images",
        element: <PackshotsAndVideos />,
      },
      {
        path: "/setup/labels",
        element: <Labels />,
      },
      {
        path: "/setup/keywords",
        element: <Keywords />,
      },
      {
        path: "/setup/categories",
        element: <Categories />,
      },
      {
        path: "/setup/products",
        element: <Products />,
      },
      {
        path: "/setup/markets",
        element: <Markets />,
      },
      {
        path: "/availability/overview",
        element: <Availability />,
      },
      {
        path: "/availability/product-listings",
        element: <ProductListings />,
      },
    ],
  },
  {
    path: "auth",
    element: <AuthLayout />,
    children: [
      {
        path: "sign-in",
        element: <SignIn />,
      },
    ],
  },
  {
    path: "auth-cover",
    element: <AuthCoverLayout />,
    children: [
      {
        path: "sign-in",
        element: <SignIn />,
      },
    ],
  },
  {
    path: "*",
    element: <ErrorLayout />,
    children: [
      {
        path: "*",
        element: <Page404 />,
      },
    ],
  },
];

export default routes;
