import React, { useMemo, useState, useRef, useEffect, useCallback } from "react";
import {
  Grid,
  Typography,
  Checkbox,
  FormControlLabel,
  Box,
  Divider,
  Tooltip,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import useData from "@/hooks/auth/useData";
import { MerchantsResponse } from "@/api/useMerchants";
import { useTabsContext } from "@/contexts/TabsContext";

interface PosTabState {
  restrict: boolean;
  excl: boolean;
  promote: boolean;
  prefer: boolean;
}

interface PosOption {
  id: number;
  label: string;
  category: string;
}

type MerchantsState = {
  prefer: Set<number>;
  promote: Set<number>;
  restrict: Set<number>;
  excl: Set<number>;
};


const PosTab: React.FC = () => {
  const { t } = useTranslation();
  const { merchantsApi } = useData();
  const { configs, setConfigs, defaultConfigIndex } = useTabsContext();
  const currentConfig = configs[defaultConfigIndex];
  const currentConfigDetails = currentConfig.config.config || {};

  const [merchantsState, setMerchantsState] = useState<MerchantsState>(() => ({
    prefer: new Set(currentConfigDetails.merchantsPreferred || []),
    promote: new Set(currentConfigDetails.merchantsSponsored || []),
    restrict: new Set((currentConfigDetails.merchants || []).filter((id: number) => id > 0)),
    excl: new Set((currentConfigDetails.merchants || []).filter((id: number) => id < 0)),
  }));

  useEffect(() => {
    const updatedConfigDetails = { ...currentConfigDetails };

    const updateOrDeleteConfig = (key: keyof MerchantsState | number[], configKey: string) => {
      const valuesArray = Array.isArray(key) ? key : Array.from(merchantsState[key]);
      if (valuesArray.length > 0) {
        updatedConfigDetails[configKey] = valuesArray;
      } else {
        delete updatedConfigDetails[configKey];
      }
    };
    updateOrDeleteConfig("prefer", "merchantsPreferred");
    updateOrDeleteConfig("promote", "merchantsSponsored");

    // Combine restrict and excl arrays into a single array
    const combinedArray = [
      ...Array.from(merchantsState.restrict).map(Math.abs),
      ...Array.from(merchantsState.excl).map((id) => -Math.abs(id)),
    ];
    updateOrDeleteConfig(combinedArray, "merchants");

    setConfigs((prevConfigs) => {
      const newConfigs = [...prevConfigs];
      newConfigs[defaultConfigIndex] = {
        ...newConfigs[defaultConfigIndex],
        config: {
          ...newConfigs[defaultConfigIndex].config,
          config: updatedConfigDetails,
        },
      };
      return newConfigs;
    });
  }, [merchantsState]);

  const merchants = useMemo(() => {
    return (merchantsApi?.merchants.data as unknown as Array<MerchantsResponse> || []).map((row: MerchantsResponse) => {
      const category = row.BT ? "basketLevelTracking" : row.CT ? "transactionTracking" : "noTracking";
      return { ...row, category };
    });
  }, [merchantsApi?.merchants]);

  const posOptions: PosOption[] = useMemo(() => {
    const options: PosOption[] = [{ id: 0, label: "All", category: "All" }];
    merchants.forEach((merchant: { ID: any; NAME: any; category: any; }) => {
      options.push({ id: merchant.ID, label: merchant.NAME, category: merchant.category });
    });

    const categoryOrder: Record<string, number> = {
      basketLevelTracking: 1,
      transactionTracking: 2,
      noTracking: 3,
      All: 0,
    };

    return options.sort((a, b) => {
      const categoryComparison = categoryOrder[a.category] - categoryOrder[b.category];
      if (categoryComparison !== 0) {
        return categoryComparison;
      }
      return a.label.localeCompare(b.label);
    });
  }, [merchants]);

  const initialPosState = useMemo(() => {
    return posOptions.reduce((acc, option) => {
      const id = option.id;
      acc[id] = {
        restrict: merchantsState.restrict.has(id),
        excl: merchantsState.excl.has(-id),
        promote: merchantsState.promote.has(id),
        prefer: merchantsState.prefer.has(id),
      };
      return acc;
    }, {} as Record<number, PosTabState>);
  }, [posOptions, merchantsState]);

  const [posState, setPosState] = useState(initialPosState);

  const handleCheckboxChange = (id: number, field: keyof PosTabState) => {
    setPosState((prevState) => {
      const updatedState = { ...prevState };

      if (field === "restrict" || field === "excl") {
        updatedState[id] = {
          ...prevState[id],
          restrict: field === "restrict" ? !prevState[id].restrict : false,
          excl: field === "excl" ? !prevState[id].excl : false,
        };
      } else {
        updatedState[id] = {
          ...prevState[id],
          [field]: !prevState[id][field],
        };
      }

      if (id === 0) {
        Object.keys(updatedState).forEach((key) => {
          const numericKey = Number(key);
          if (numericKey !== 0) {
            if (field === "restrict" || field === "excl") {
              updatedState[numericKey].restrict = updatedState[0].restrict;
              updatedState[numericKey].excl = updatedState[0].excl;
            } else {
              updatedState[numericKey][field] = updatedState[0][field];
            }
          }
        });
      }

      // Create a new merchantsState object to update
      const newMerchantsState = {
        ...merchantsState,
      };

      Object.keys(updatedState).forEach((key) => {
        const numericKey = Number(key);
        if (numericKey !== 0) {
          if (field === "restrict" || field === "excl") {
            if (field === "restrict") {
              if (updatedState[numericKey].restrict) {
                newMerchantsState.restrict.add(numericKey);
                newMerchantsState.excl.delete(numericKey);
              } else {
                newMerchantsState.restrict.delete(numericKey);
              }
            } else {
              if (updatedState[numericKey].excl) {
                newMerchantsState.excl.add(numericKey);
                newMerchantsState.restrict.delete(numericKey);
              } else {
                newMerchantsState.excl.delete(numericKey);
              }
            }
          } else {
            if (updatedState[numericKey][field]) {
              newMerchantsState[field].add(numericKey);
            } else {
              newMerchantsState[field].delete(numericKey);
            }
          }
        }
      });

      // Use setMerchantsState to update merchantsState
      setMerchantsState(newMerchantsState);

      return updatedState;
    });
  };

  const renderRow = (id: number, label: string) => {
    const textRef = useRef<HTMLElement>(null);
    const [isTruncated, setIsTruncated] = useState(false);

    const checkTruncation = useCallback(() => {
      if (textRef.current) {
        setIsTruncated(textRef.current.scrollWidth > textRef.current.clientWidth);
      }
    }, []);

    useEffect(() => {
      checkTruncation();
      window.addEventListener("resize", checkTruncation);
      return () => window.removeEventListener("resize", checkTruncation);
    }, [checkTruncation]);

    return (
      <Grid container spacing={2} alignItems="center" key={label} sx={{ paddingY: 0.5 }}>
        <Grid item xs={4}>
          {isTruncated ? (
            <Tooltip title={label} arrow>
              <Typography
                ref={textRef}
                variant="body2"
                sx={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", maxWidth: "100%" }}
              >
                {label}
              </Typography>
            </Tooltip>
          ) : (
            <Typography
              ref={textRef}
              variant="body2"
              sx={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", maxWidth: "100%" }}
            >
              {label}
            </Typography>
          )}
        </Grid>
        {["restrict", "excl", "promote", "prefer"].map((field) => (
          <Grid item xs={2} key={field} sx={{ textAlign: "center" }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={posState[id][field as keyof PosTabState]}
                  onChange={() => handleCheckboxChange(id, field as keyof PosTabState)}
                  size="small"
                />
              }
              label=""
              sx={{ margin: 0 }}
            />
          </Grid>
        ))}
      </Grid>
    );
  };

  const categories = Array.from(new Set(posOptions.map((option) => option.category)));

  return (
    <Box>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={4}>
            <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>{t("pages.buyNowComponent.editModal.pos.title")}</Typography>
        </Grid>
        <Grid item xs={2} sx={{ textAlign: "center" }}>
          <Tooltip title={t("pages.buyNowComponent.editModal.pos.restrictTooltip")} arrow>
            <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>{t("pages.buyNowComponent.editModal.pos.restrict")}</Typography>
          </Tooltip>
        </Grid>
        <Grid item xs={2} sx={{ textAlign: "center" }}>
          <Tooltip title={t("pages.buyNowComponent.editModal.pos.excludeTooltip")} arrow>
            <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>{t("pages.buyNowComponent.editModal.pos.exclude")}</Typography>
          </Tooltip>
        </Grid>
        <Grid item xs={2} sx={{ textAlign: "center" }}>
          <Tooltip title={t("pages.buyNowComponent.editModal.pos.promoteTooltip")} arrow>
            <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>{t("pages.buyNowComponent.editModal.pos.promote")}</Typography>
          </Tooltip>
        </Grid>
        <Grid item xs={2} sx={{ textAlign: "center" }}>
          <Tooltip title={t("pages.buyNowComponent.editModal.pos.preferTooltip")} arrow>
            <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>{t("pages.buyNowComponent.editModal.pos.prefer")}</Typography>
          </Tooltip>
        </Grid>
      </Grid>

      <Divider sx={{ marginY: 2 }} />

      {/* "All" Row Outside Scrollable Area */}
      <Box sx={{ paddingY: 0.5 }}>
        {renderRow(0, "All")}
      </Box>


      {/* Scrollable Area for Other Rows */}
      <Box sx={{ maxHeight: "485px", overflowY: "auto" }}>
        {categories
          .filter((category) => category !== "All")
          .map((category) => (
            <Box key={category} sx={{ marginBottom: 2 }}>
              <Divider sx={{ marginY: 2 }} />
              <Typography variant="subtitle1" gutterBottom sx={{ fontWeight: 'bold' }}>
                {t(`pages.buyNowComponent.editModal.pos.${category}`)}
              </Typography>
              {posOptions
                .filter((option) => option.category === category)
                .map((option) => renderRow(option.id, option.label))}
            </Box>
          ))}
      </Box>
    </Box>
  );
};

export default PosTab;
