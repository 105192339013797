import React, {
  createContext,
  useState,
  useContext,
  ReactNode,
  useEffect,
  useCallback,
} from "react";
import { ComponentsResponse, Config } from "@/api/buynow/types";
import { v4 as uuidv4 } from "uuid";

interface SetupGeneralProps {
  id: string;
  product: number;
  product_name: string;
  demo: boolean | number;
  install: number;
  property: string;
  restriction: string;
  type: number;
  configs: Array<Config>;
}

interface UnifiedImageEntry {
  id: string;
  sku: string;
  image: string;
  width: number | string;
  height: number | string;
  description: string;
}

interface ValidationErrors {
  [tabName: string]: {
    [fieldId: string]: string;
  };
}

interface TabsContextType {
  setupProps: SetupGeneralProps;
  setSetupProps: React.Dispatch<React.SetStateAction<SetupGeneralProps>>;
  configs: Config[];
  setConfigs: React.Dispatch<React.SetStateAction<Config[]>>;
  defaultConfigIndex: number;
  setDefaultConfigIndex: React.Dispatch<React.SetStateAction<number>>;
  setTabValue: React.Dispatch<React.SetStateAction<number>>;
  tabValue: number;
  initialSetupProps: SetupGeneralProps;
  initialConfigs: Array<Config>;
  componentData: ComponentsResponse | null;
  revertToDefaultValue: () => void;
  setCurrentVariants: React.Dispatch<React.SetStateAction<Array<string>>>;
  currentVariants: Array<string>;
  validationErrors: ValidationErrors;
  setValidationErrors: React.Dispatch<React.SetStateAction<ValidationErrors>>;
  warnings: { [key: string]: string };
  setWarnings: React.Dispatch<React.SetStateAction<{ [key: string]: string }>>;
  imageEntries: UnifiedImageEntry[];
  setImageEntries: React.Dispatch<React.SetStateAction<UnifiedImageEntry[]>>;
  isItemsTabInitialized: boolean;
  setIsItemsTabInitialized: React.Dispatch<React.SetStateAction<boolean>>;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  isLoading: boolean;
}

const TabsContext = createContext<TabsContextType | undefined>(undefined);

export const useTabsContext = () => {
  const context = useContext(TabsContext);
  if (!context) {
    throw new Error("useTabsContext must be used within a TabsProvider");
  }
  return context;
};

export const TabsProvider: React.FC<{
  children: ReactNode;
  rowData: ComponentsResponse;
}> = ({ children, rowData }) => {
  const [configs, setConfigs] = useState(rowData?.configs || []);
  const [defaultConfigIndex, setDefaultConfigIndex] = useState<number>(
    configs.findIndex((config) => config.type === 0) || 0
  );
  const [tabValue, setTabValue] = useState(0);
  const [currentVariants, setCurrentVariants] = useState<Array<string>>([]);
  const [componentData, setComponentData] = useState<ComponentsResponse | null>(
    null
  );
  const [setupProps, setSetupProps] = useState<SetupGeneralProps>({
    id: rowData?.id || "",
    product: rowData?.PID,
    product_name: rowData?.product_name || "",
    demo: rowData?.demo || 0,
    install: rowData?.install.id,
    property: rowData?.install.url || "",
    restriction: rowData?.install.domain || "",
    type: rowData?.install.type,
    configs: rowData?.configs,
  });
  const [initialSetupProps, setInitialSetupProps] = useState(setupProps);
  const [initialConfigs, setInitialConfigs] = useState(configs);
  const [validationErrors, setValidationErrors] = useState<ValidationErrors>(
    {}
  );

  const [warnings, setWarnings] = useState<{ [key: string]: string }>({});
  const [imageEntries, setImageEntries] = useState<UnifiedImageEntry[]>([]);
  const [isItemsTabInitialized, setIsItemsTabInitialized] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  console.log(validationErrors, "validationErrors");
  console.log(warnings, "warnings");

  const getSetupProps = () => ({
    id: rowData.id || "",
    product: rowData.PID,
    product_name: rowData.product_name || "",
    demo: rowData.demo || 0,
    install: rowData.install.id,
    property: rowData.install.url || "",
    restriction: rowData.install.domain || "",
    type: rowData.install.type,
    configs: rowData?.configs,
  });

  const setupRowData = (setupPropsData: SetupGeneralProps) => {
    setSetupProps(setupPropsData);
    setInitialSetupProps(setupPropsData);
    setConfigs(rowData.configs || []);
    setInitialConfigs(rowData.configs || []);
  };

  useEffect(() => {
    if (rowData) {
      setComponentData(rowData);

      const setupPropsData = getSetupProps();
      setupRowData(setupPropsData);
      const currentConfigIndex =
        rowData.configs?.findIndex((config) => config.type === 0) || 0;
      setDefaultConfigIndex(currentConfigIndex);

      const currentConfig = rowData.configs[currentConfigIndex];
      setCurrentVariants(currentConfig?.variants || []);
    }
  }, [rowData]);

  const revertToDefaultValue = useCallback(() => {
    if (!rowData) return;
    setComponentData(rowData);

    const setupPropsData = getSetupProps();
    setupRowData(setupPropsData);
    setCurrentVariants(rowData.configs[defaultConfigIndex]?.variants || []);
    setValidationErrors({});
    setWarnings({});
    setIsItemsTabInitialized(false);
    setTabValue(0);
    setImageEntries([]);
    setValidationErrors({});
  }, [defaultConfigIndex]);

  useEffect(() => {
    revertToDefaultValue();
  }, [rowData, revertToDefaultValue]);

  return (
    <TabsContext.Provider
      value={{
        setupProps,
        setSetupProps,
        configs,
        setConfigs,
        defaultConfigIndex,
        setDefaultConfigIndex,
        setTabValue,
        tabValue,
        initialSetupProps,
        initialConfigs,
        componentData,
        revertToDefaultValue,
        setCurrentVariants,
        currentVariants,
        validationErrors,
        setValidationErrors,
        warnings,
        setWarnings,
        imageEntries,
        setImageEntries,
        isItemsTabInitialized,
        setIsItemsTabInitialized,
        setIsLoading,
        isLoading,
      }}
    >
      {children}
    </TabsContext.Provider>
  );
};
