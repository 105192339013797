import FiltersPanel from "@/components/FiltersPanel";
import ContentContainer from "@/components/contentContainer";

import { Box, Paper, Typography } from "@mui/material";
import { dependentAPIs, selectorsConfig, hiddenTableColumns } from "./consts";
import { useMemo } from "react";
import { GridColDef } from "@mui/x-data-grid-pro";
import MuiGridTable from "@/components/tables/DataGridTable";
import { useTranslation } from "react-i18next";
import { useColumns } from "@/hooks/useColumnsData";
import useData from "@/hooks/auth/useData";

const ProductListings = () => {
  const { t } = useTranslation();
  const { productListingsTableApi, isLoading } = useData();


  const rows = useMemo(() => {
    return (productListingsTableApi.data || [])?.map((row) => ({
      ...row,
    }));
  }, [productListingsTableApi.data]);

  const {
    posLinkColumn,
    productAllBrandColumn,
    boldProductColumn,
    availabilitySizeColumn,
    skuColumn,
    statusColumn,
    pdpColumn,
    manufacturerNameColumn2,
    catColumn,
    kwdColumn,
  } = useColumns();

  const columns: GridColDef[] = [
    posLinkColumn,
    manufacturerNameColumn2,
    productAllBrandColumn,
    boldProductColumn,
    availabilitySizeColumn,
    {...skuColumn, width: 100},
    statusColumn,
    pdpColumn,
    catColumn,
    kwdColumn,
  ];

  return (
    <ContentContainer>
      <FiltersPanel
        selectorsConfig={selectorsConfig}
        dependentAPIs={dependentAPIs}
      />
      <Paper>
        <MuiGridTable
          rows={rows}
          columns={columns}
          loading={isLoading}
          filterBarType={2}
          initialPageSize={100}
          hiddenFields={hiddenTableColumns}
          searchPlaceholder={
            t("tableColumns.searchPlaceholder") || "SKU, Products, ..."
          }
          isError={productListingsTableApi.isError}
          error={productListingsTableApi.error}
          tableInfo={
            t("tableInfo.productListings") || 
            "Does your product have a Product Detail Page at the POS and is it listed within the top 100 of any search result page for categories or keywords?"
          }
        />
      </Paper>
    </ContentContainer>
  );
};

export default ProductListings;
