import React, { useState } from "react";
import { ButtonProps, Button, Typography, useTheme } from "@mui/material";
import {
  GridRenderCellParams,
  useGridApiContext,
  useGridSelector,
  gridFilteredDescendantCountLookupSelector,
} from "@mui/x-data-grid-pro";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Box } from "@mui/material";

const CustomGridTreeDataGroupingCell = (props: GridRenderCellParams & {
  content: string;
}) => {
  const { id, field, rowNode, content } = props;
  const apiRef = useGridApiContext();
  const filteredDescendantCountLookup = useGridSelector(
    apiRef,
    gridFilteredDescendantCountLookupSelector
  );
  const filteredDescendantCount = filteredDescendantCountLookup[rowNode.id] ?? 0;
  const [isTreeToggled, setIsTreeToggled] = useState<boolean>(false);
  const theme = useTheme();

  const handleClick: ButtonProps["onClick"] = (event) => {
    if (rowNode.type !== "group") {
      return;
    }
    setIsTreeToggled(!isTreeToggled);
    apiRef.current.setRowChildrenExpansion(id, !rowNode.childrenExpanded);
    apiRef.current.setCellFocus(id, field);
    event.stopPropagation();
  };

  return (
    <Box>
      <div>
        {filteredDescendantCount > 0 ? (
          <Button onClick={handleClick} tabIndex={-1}  style={{ marginLeft: '-10px', minWidth: "50px", height: "24px" }}>
              <ArrowForwardIosIcon
                style={{ color: theme.palette.text.primary ,transform: isTreeToggled ? "rotate(90deg)" : "", width: 10 }}
              />
          </Button>
          ): <span style={{fontWeight: 700, marginLeft: (filteredDescendantCount < 0 ? '60px' : 40)}}/>}     
        <span style={{fontWeight: 700}}>{content}</span>
      </div>
    </Box>
  );
};

export default CustomGridTreeDataGroupingCell;
