// import "react-app-polyfill/stable";

import React, { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";

import "chart.js/auto";
import "animate.css/animate.min.css";
import * as Sentry from "@sentry/react";

import App from "./App";
import { ThemeProvider } from "./contexts/ThemeContext";
import { LicenseInfo } from '@mui/x-license';

const muiProKey = import.meta.env.VITE_MUI_PRO_KEY;
LicenseInfo.setLicenseKey(muiProKey || '');


const env = import.meta.env.VITE_APP_ENVIRONMENT;
if (env && env !== 'local') {  // Check if env is defined and no local deployment
  Sentry.init({
    dsn: "https://4f653dbb80a10727e3a0ccb1359f4072@o4507928793317377.ingest.de.sentry.io/4507928796004432",
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    // Tracing
    tracesSampleRate: 1.0, // Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost", /^https:\/\/app\.maven\.io/, /^https:\/\/staging\.app\.maven\.io/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // Sample rate at 10%
    replaysOnErrorSampleRate: 1.0,  // 100% sampling for sessions with errors
    environment: env,  // Set the environment based on the env variable
  });
}

const container = document.getElementById("root");
const root = createRoot(container!);

root.render(
  <StrictMode>
    <ThemeProvider>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </ThemeProvider>
  </StrictMode>
);
