export enum ErrorSize {
  small = "small",
  medium = "medium",
  large = "large",
}

export interface BaseErrorProps {
  text?: string;
  size?: ErrorSize;
  hasCustomError?: boolean;
}
