import { createMutationFunction } from "../mutations/createMutationFunction";
import { ShareOfSearchResponse, ShareOfSearchVariables } from "./types";
import { ErrorCallback, useApiMutation } from "../useApiMutation";
import {t} from "i18next";

/**
 * Custom error handling for Share of Search
 * @param {ErrorCallback<ShareOfSearchResponse[]>} params - The error callback parameters
 */
const handleCustomError = ({
  error,
  setError,
  setData,
}: ErrorCallback<ShareOfSearchResponse[]>) => {
  console.log(`${t("general.error")}: ${error.message}`)
  const errorMessage =
    error.response && error.response.status === 422 // Unprocessable Content, label is not selected
      ? t("pages.shareOfShare.errorNoLabel") || "Please select a label to show data"
      : `${t("general.error")}: ${error.message}`;

  setError(errorMessage);
  setData(undefined);
};


export const useShareOfSearch = () => {
  const useShareOfSearch = useApiMutation<ShareOfSearchResponse[]>({
    mutationFn: (variables) => {
      return createMutationFunction<ShareOfSearchResponse[], ShareOfSearchVariables>("app/visibility/share-of-search")(variables);
    },
    onErrorCallback: handleCustomError
  });
  return useShareOfSearch;
};
