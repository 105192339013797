import ContentContainer from "@/components/contentContainer";
import { MuiGridTable } from "@/components/tables/DataGridTable";
import { Paper } from "@mui/material";
import {
  GridColDef,
  GridRowSelectionModel,
  GridSortDirection,
} from "@mui/x-data-grid-pro";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { LabelsResponse } from "@/api/setup/types";
import { useColumns } from "@/hooks/useColumnsData";
import useData from "@/hooks/auth/useData";

const Keywords = () => {
  const { t } = useTranslation();
  const [selectedRows, setSelectedRows] = useState<GridRowSelectionModel>([]);
  const { selectedManufacturers, setupKeywordsApi } = useData();

  const manufacturerIds = useMemo(
    () => selectedManufacturers.map((m) => m.ID),
    [selectedManufacturers]
  );

  const rows = useMemo(() => {
    return (setupKeywordsApi.data || []).map((row) => ({
      ...row,
    }));
  }, [setupKeywordsApi.data]);

  useEffect(() => {
    if (!manufacturerIds.length) return;

    setupKeywordsApi.execute({
      m: manufacturerIds,
    });
  }, [manufacturerIds]);

  const allColumns = useColumns();

  const columns: GridColDef<LabelsResponse>[] = [
    allColumns.kwdTypeColumn,
    allColumns.keywordColumn,
    allColumns.idColumn,
    allColumns.setupLabelColumn,
  ];

  const sortModel = useMemo(
    () => [
      {
        field: "keyword",
        sort: "asc" as GridSortDirection,
      },
    ],
    []
  );

  return (
    <ContentContainer>
      <Paper>
        <MuiGridTable
          rows={rows}
          columns={columns}
          sortModel={sortModel}
          onRowSelectionModelChange={(ids) => setSelectedRows(ids)}
          selectedRows={selectedRows}
          loading={setupKeywordsApi?.isLoading}
          filterBarType={2}
          hasCheckboxes={false}
          hasExport={false}
          opacityTableInfo={t("pages.keywords.title") as string}
          isError={setupKeywordsApi.isError}
          error={setupKeywordsApi.error}
        />
      </Paper>
    </ContentContainer>
  );
};

export default Keywords;
