import { useApiQuery } from "../useApiQuery";
import { createQueryFunction } from "../queries/createQueryFunction";
import { SellerApiType, SellerResponse, SellerParams } from "./types";


export const useSeller = (): SellerApiType => {
    const sellerApi = useApiQuery<SellerResponse[], SellerParams>({
      key: 'seller',
      queryFn: createQueryFunction(`sellers`),
    });
  
    return { sellerApi };
};