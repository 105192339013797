import { AnalyticsRequestBody, DayOption } from "./types";
import { TFunction } from "i18next";

export const getDayOptions = (t: TFunction): DayOption[] => [
  { value: 7, label: `7 ${t("filters.days")}` },
  { value: 30, label: `30 ${t("filters.days")}` },
  { value: 90, label: `90 ${t("filters.days")}` },
  { value: 160, label: `160 ${t("filters.days")}` },
];

export enum SelectorNames {
  Manufacturer = "manufacturers",
  Merchant = "merchants",
  Seller = "sellers",
  ParentBrand = "brands",
  SubBrand = "subbrands",
  Products = "products",
  Source = "utm_sources",
  Medium = "utm_mediums",
  Campaign = "utm_campaigns",
  Term = "utm_terms",
  Content = "utm_contents",
  Device = "devices",
  Focus = "focus",
  Url = "urls",
  UtmParams = "utmParams",
  Market = "markets",
  RevStatus = "rev_status",
  Label = "labels",
  Dimension = "dimensions",
  Availabilities = "availabilities",
  AvStatus = "avStatus",
}

export const defaultSelectors: AnalyticsRequestBody = {
  manufacturers: [],
  products: [],
  brands: [],
  subbrands: [],
  focus: [0],
  urls: [],
  devices: [],
  utm_terms: [],
  utm_sources: [],
  utm_mediums: [],
  utm_campaigns: [],
  utm_contents: [],
  merchants: [],
  sellers: [],
  markets: [0],
  labels: [],
  rev_status: [],
  availabilities: [],
  avStatus: [],
};

export enum Breakpoints {
  XLarge = 1800,
  Large = 1700,
  MediumLarge = 1640,
  Medium = 1500,
  SmallMedium = 1360,
  Small = 1100,
}

export const selectorCountBreakpoints = [
  { value: Breakpoints.XLarge, count: 8 },
  { value: Breakpoints.Large, count: 7 },
  { value: Breakpoints.MediumLarge, count: 7 },
  { value: Breakpoints.Medium, count: 6 },
  { value: Breakpoints.SmallMedium, count: 5 },
  { value: Breakpoints.Small, count: 4 },
];
