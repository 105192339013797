import {createMutationFunction} from "../mutations/createMutationFunction";
import {useApiMutation} from "../useApiMutation";
import {PricingTableResponseLine, PricingTableApiType} from "./types";

export const usePricingTable = (): PricingTableApiType => {
    return useApiMutation<PricingTableResponseLine[]>({
      mutationFn: (variables, signal) =>
          createMutationFunction<PricingTableResponseLine[]>("pricing/get_table_pricing")(
              variables,
              signal
          ),
  });
};
