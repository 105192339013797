import { useApiQuery } from "../useApiQuery";
import { createQueryFunction } from "../queries/createQueryFunction";
import { LabelsApiType, LabelsResponse } from "./types";


export const useLabels = (): LabelsApiType => {
    const labelsApi = useApiQuery<LabelsResponse[]>({
      key: 'labels',
      queryFn: createQueryFunction(`app/setup/labels`),
    });
  
    return { labelsApi };
};