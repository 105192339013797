import { useEffect, useMemo, useState } from "react";
import { MuiGridTable as DataGridTable } from "@/components/tables/DataGridTable";
import { Paper } from "@mui/material";
import { GridColDef, GridRowSelectionModel } from "@mui/x-data-grid-pro";
import useData from "@/hooks/auth/useData";
import { useColumns } from "@/hooks/useColumnsData";
import ContentContainer from "@/components/contentContainer";
import { PropertiesResponse } from "@/api/buynow/types";
import { useTranslation } from "react-i18next";

const BuyNowProperties = (): JSX.Element => {
  const { selectedManufacturers, isLoading, allPropertiesApi } = useData();
  const [selectedRows, setSelectedRows] = useState<GridRowSelectionModel>([]);
  const allColumns = useColumns();
  const { t } = useTranslation();

  const rows: PropertiesResponse[] = useMemo(() => {
    return allPropertiesApi.data || [];
  }, [allPropertiesApi.data]);

  useEffect(() => {
    const selectedManufacturerIDs = selectedManufacturers.map((m) => m.ID);
    if (allPropertiesApi && selectedManufacturerIDs.length > 0) {
      setSelectedRows([]);
      allPropertiesApi.execute({ m: selectedManufacturerIDs });
    }
  }, [selectedManufacturers]);

  const columns: GridColDef<PropertiesResponse>[] = [
    allColumns.bnTypeColumn,
    allColumns.ownerColumn,
    allColumns.propertyColumn,
    allColumns.domainColumn,
    allColumns.connectedColumn,
    allColumns.createdOnlyDateColumn,
    allColumns.updatedOnlyDateColumn,

    allColumns.actionsColumn(),
  ];

  return (
    <ContentContainer>
      <Paper>
        <DataGridTable
          rows={rows}
          columns={columns}
          onRowSelectionModelChange={(ids) => setSelectedRows(ids)}
          selectedRows={selectedRows}
          loading={isLoading}
          filterBarType={2}
          hasCheckboxes={true}
          hasControls={true}
          searchPlaceholder={t("navigation.buyNowProperties") || "Properties"}
          tableInfo={t("tableInfo.buyNowProperties") || ""}
          isError={allPropertiesApi.isError}
          error={allPropertiesApi.error}
        />
      </Paper>
    </ContentContainer>
  );
};

export default BuyNowProperties;
