import { SelectorNames } from "@/components/FiltersPanel/consts";
import { SelectorDef } from "@/components/FiltersPanel/types";
import { DataState } from "@/types/dataContext";

export const selectorsConfig: Array<SelectorDef> = [
  { name: SelectorNames.Merchant, dependentFilters: [SelectorNames.Seller] },
  { name: SelectorNames.Market, disabled: true },
  {
    name: SelectorNames.Focus,
    dependentFilters: [
      SelectorNames.Products,
      SelectorNames.ParentBrand,
      SelectorNames.SubBrand,
    ],
    defaultValue: 0,
  },
  {
    name: SelectorNames.ParentBrand,
    dependentFilters: [SelectorNames.Products, SelectorNames.SubBrand],
  },
  { name: SelectorNames.SubBrand, dependentFilters: [SelectorNames.Products] },
  { name: SelectorNames.Products },
  {
    name: SelectorNames.Availabilities,
    defaultValue: 0,
    hasAllOption: true,
    multiSelect: true,
  },
];

export const dependentAPIs: (keyof DataState)[] = ["availabilityTableApi"];
