export const uiModes = ["light", "dark", "system"];

export const currencies = ["euro", "us_dollar"];

export const numbersFormat = {
    "en-US": "English (en-US)",
    "en-UK": "English (en-UK)",
    "de-DE": "Deutsch (de-DE)",
    "de-CH": "Deutsch (de-CH)",
    "es-ES": "Spanish (es-ES)",
};

export const languageOptions = {
    en: "English (US)",
    de: "Deutsch (GER)",
    es: "Español (ES)",
};