import React from "react";
import { Outlet } from "react-router-dom";
import styled from "@emotion/styled";
import { CssBaseline, Grid, useMediaQuery, useTheme } from "@mui/material";

import Settings from "@mira/components/Settings";
import GlobalStyle from "../components/GlobalStyle";

interface AuthCoverType {
  children?: React.ReactNode;
}

const Root = styled.div`
  background: ${(props) => props.theme.palette.background.default};
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100vh;
`;

// const Brand = styled(Logo)`
//   fill: ${(props) => props.theme.palette.common.white};
//   width: 64px;
//   height: 64px;
//   position: absolute;
//   bottom: 48px;
//   left: 48px;
// `;

const LeftSide = styled.div`
  background-image: url("/static/img/unsplash/unsplash-5.jpg");
  background-size: cover;
  background-position: center;
  border-radius: 20px;
  position: relative;
  height: calc(100% - ${(props) => props.theme.spacing(8)});
  width: calc(100% - ${(props) => props.theme.spacing(8)});
  margin: ${(props) => props.theme.spacing(4)};
`;

const RightSide = styled.div`
  padding: ${(props) => props.theme.spacing(4)};
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  display: flex;
  min-height: 100%;
  flex-direction: column;
  max-width: 480px;
`;

const ImageWrapper = styled.div`
  width: 200px;
  height: 200px;
  display: flex;
  align-items: center;
  padding-left: ${(props) => props.theme.spacing(5)};
`;

const Image = styled.img`
  max-width: 100%;
  max-height: 100%;
`;

const AuthCover: React.FC<AuthCoverType> = ({ children }) => {
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("lg"));

  return (
    <Root>
      <CssBaseline />
      <GlobalStyle />
      <Grid container style={{ minHeight: "100vh" }}>
        {isLargeScreen && (
          <Grid item xs={12} lg={7}>
            <LeftSide>
              {/* <ImageWrapper>
                <Image src={"/static/img/logo.png"} alt={"logo"} style={{opacity: 0.4}}/>
              </ImageWrapper> */}
            </LeftSide>
          </Grid>
        )}
        <Grid item xs={12} lg={5}>
          <RightSide>
            {children}
            <Outlet />
          </RightSide>
        </Grid>
      </Grid>
    </Root>
  );
};

export default AuthCover;
