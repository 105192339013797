import { createQueryFunction } from "../queries/createQueryFunction";
import { useApiQuery } from "../useApiQuery";
import { CategoryResponse, SetupParams } from "./types";

const key = "categories";

export const useCategories = () => {
  return useApiQuery<CategoryResponse[], SetupParams>({
    key: key,
    queryFn: createQueryFunction<CategoryResponse[], SetupParams>(
      "app/setup/categories"
    ),
  });
};
