import { createMutationFunction } from "../mutations/createMutationFunction";
import { useApiMutation } from "../useApiMutation";
import { ProductResponse, SetupParams } from "./types";

export const useSetupProduct = () => {
  const productCatalogApi = useApiMutation<ProductResponse[], SetupParams>({
    mutationFn: createMutationFunction<ProductResponse[], SetupParams>(
      "app/setup/products"
    ),
  });
  return productCatalogApi;
};
