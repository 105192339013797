export const localeDateFormat = {
  "en-US": "MM/DD/YYYY",
  "en-UK": "DD/MM/YYYY",
  "de-DE": "DD.MM.YYYY",
  "de-CH": "DD.MM.YYYY",
  "es-ES": "DD/MM/YYYY",
};

export const localeMapping = {
  "en-US": "en",
  "en-UK": "en",
  "de-DE": "de",
  "de-CH": "de",
  "es-ES": "es",
};

export const defaultTimeRanges = [
  { label: "profile.navBar.dateSelector.yesterday", value: 1 },
  { label: "profile.navBar.dateSelector.last7Days", value: 7 },
  { label: "profile.navBar.dateSelector.last30Days", value: 30 },
  { label: "profile.navBar.dateSelector.last90Days", value: 90 },
  { label: "profile.navBar.dateSelector.last6Months", value: 180 },
  {
    label: "profile.navBar.dateSelector.lastYear",
    value: 365,
    disabled: true,
    upgrade: true,
  },
  {
    label: "profile.navBar.dateSelector.last2Years",
    value: 730,
    disabled: true,
  },
  {
    label: "profile.navBar.dateSelector.last3Years",
    value: 1095,
    disabled: true,
  },
];
