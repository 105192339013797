import { FC } from "react";
import { Box, Typography, useTheme } from "@mui/material";
import { BaseErrorProps, ErrorSize } from "./types";
import { useTranslation } from "react-i18next";
import { getErrorMessage } from "@/utils/getErrorMassge";

const getSizeStyles = (size: ErrorSize) => {
  switch (size) {
    case ErrorSize.small:
      return {
        fontSize: "10px",
        padding: "2px",
      };
    case ErrorSize.large:
      return {
        fontSize: "16px",
        padding: "16px",
      };
    default:
      return {
        fontSize: "14px",
        padding: "16px",
      };
  }
};

const BaseError: FC<BaseErrorProps> = ({
  text = "Error loading data",
  size = ErrorSize.medium, // default size
  hasCustomError = false
}): JSX.Element => {
  const theme = useTheme();
  const { t } = useTranslation();
  const sizeStyles = getSizeStyles(size);

  return (
    <Box
      sx={{
        color: theme.palette.error.main,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        padding: sizeStyles.padding,
      }}
    >
      <Typography
        color="error"
        sx={{
          fontSize: sizeStyles.fontSize,
          alignItems: "center",
        }}
      >
          {hasCustomError ? text
              :t("general.error") || "An error occurred while loading data"}{" "}
              {getErrorMessage(text)}
      </Typography>
    </Box>
  );
};

export default BaseError;
