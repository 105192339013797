import { createQueryFunction } from "./queries/createQueryFunction";
import { ApiQuery, useApiQuery } from "./useApiQuery";
import { UserResponse } from "@/types/user";

export type MeApiType = ApiQuery<UserResponse>;

const me = "me";
export const useMe = (): MeApiType => {
    return useApiQuery<UserResponse>({
      key: me,
      queryFn: createQueryFunction(me),
    });
};
