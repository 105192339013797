import { createMutationFunction } from "../mutations/createMutationFunction";
import { useApiMutation } from "../useApiMutation";
import {
  RatingsAndReviewsTableApiType,
  RatingsAndReviewsResponse,
} from "./types";

export const useRatingsAndReviews = (): RatingsAndReviewsTableApiType => {
  const ratingsAndReviewsApi = useApiMutation<RatingsAndReviewsResponse[]>({
    mutationFn: (variables, signal) =>
      createMutationFunction<RatingsAndReviewsResponse[]>(
        "content/ratings-and-reviews"
      )(variables, signal),
  });
  return ratingsAndReviewsApi;
};
