import { createQueryFunction } from "./queries/createQueryFunction";
import { ApiQuery, useApiQuery } from "./useApiQuery";

/**
 * Interface representing the API response for merchants.
 */
export interface MerchantsResponse {
  BT: boolean;
  CT: boolean;
  ID: number;
  IS_MARKET: boolean | null;
  IS_PRIMARY: number;
  NAME: string;
  STATUS: string;
  URL: string;
  country_code: string;
  name_save: string;
}

/**
 * Type representing the API query for merchants.
 *
 * @template TResponse - The type of the response data.
 */
export interface MerchantsApiType {
  isLoading: boolean;
  merchants: ApiQuery<MerchantsResponse>;
}

const key = "merchants";

/**
 * Custom hook to fetch and manage merchants data.
 *
 * This hook uses the `useApiQuery` hook to create a query for fetching merchants data.
 * It leverages a query function created by `createQueryFunction` to perform the data fetching.
 *
 * @returns {MerchantsApiType} An object containing the loading state and the merchants data query.
 *
 * @example
 * const { isLoading, merchants } = useMerchants();
 *
 * // Fetch merchants data
 * const { data, error, isLoading: isMerchantsLoading } = merchants;
 */
export const useMerchants = (): MerchantsApiType => {
  const merchants = useApiQuery<MerchantsResponse>({
    key,
    queryFn: createQueryFunction(key),
  });

  return {
    isLoading: merchants.isLoading,
    merchants,
  };
};
