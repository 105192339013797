import { createMutationFunction } from "../mutations/createMutationFunction";
import { useApiMutation } from "../useApiMutation";
import { ProductListingsApiType, ProductListingsTableResponse } from "./types";

export const useProductListings = (): ProductListingsApiType => {
  const productListingsTableApi = useApiMutation<ProductListingsTableResponse[]>({
    mutationFn: (variables, signal) =>
      createMutationFunction<ProductListingsTableResponse[]>(
        "availability/product_listings"
      )(variables, signal),
  });
  return { productListingsTableApi };
};
