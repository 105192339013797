import { useEffect, useMemo, useState } from "react";
import { MuiGridTable as DataGridTable } from "@/components/tables/DataGridTable";
import {
  Alert,
  Menu,
  MenuItem,
  Paper as MuiPaper,
  Snackbar,
} from "@mui/material";
import { spacing } from "@mui/system";
import styled from "@emotion/styled";
import {
  GridColDef,
  GridRenderCellParams,
  GridRowSelectionModel,
} from "@mui/x-data-grid";
import useData from "@/hooks/auth/useData";
import { useColumns } from "@/hooks/useColumnsData";
import ContentContainer from "@/components/contentContainer";
import { t } from "i18next";
import { ComponentsResponse, PropertiesResponse } from "@/api/buynow/types";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import EditDrawer from "@/pages/buynow/components/editPanel";
import { TabsProvider } from "@/contexts/TabsContext";
import useCustomTableUtils from "@/hooks/useColumnsData/utils";

const Paper = styled(MuiPaper)(spacing);

export default function BuyNowComponents(): JSX.Element {
  const { selectedManufacturers, componentsApi, user } = useData();
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [selectedRows, setSelectedRows] = useState<GridRowSelectionModel>([]);
  const allColumns = useColumns();
  const snackbarMessage = t("tableColumns.copiedToClipboard");
  const [rows, setRows] = useState<ComponentsResponse[]>([]);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedRow, setSelectedRow] = useState<ComponentsResponse | null>(
    null
  );
  const [drawerOpen, setDrawerOpen] = useState(false);

  const { getTypeImage, renderLinkCell, trimUrl, na } = useCustomTableUtils();

  useEffect(() => {
    if (componentsApi.data) {
      setRows(componentsApi.data);
    }
  }, [componentsApi.data]);

  useEffect(() => {
    const selectedManufacturerIDs = selectedManufacturers.map((m) => m.ID);
    if (componentsApi && selectedManufacturerIDs.length > 0) {
      setSelectedRows([]);
      componentsApi.execute({ m: selectedManufacturerIDs });
    }
  }, [selectedManufacturers]);

  const handleUpdateRow = (updatedRow: ComponentsResponse) => {
    setRows((prevRows) =>
      prevRows.map((row) => (row.id === updatedRow.id ? updatedRow : row))
    );
  };

  const handleCopy = (domain: string) => {
    const divContent = `<div cid=${domain}></div>`;
    navigator.clipboard.writeText(divContent);
    setOpenSnackbar(true);
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const handleMenuClick = (
    event: React.MouseEvent<HTMLElement>,
    row: ComponentsResponse
  ) => {
    setAnchorEl(event.currentTarget);
    setSelectedRow(row);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleEdit = () => {
    setDrawerOpen(true);
    handleMenuClose();
  };

  const handleVisitComponent = () => {
    if (selectedRow?.demo_link) {
      window.open(selectedRow.demo_link, "_blank");
      return;
    }
    handleMenuClose();
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  const columns: GridColDef<ComponentsResponse>[] = [
    {
      ...allColumns.idColumn,
      headerName: "ID",
      width: 80,
      renderCell: (params) => {
        const componentId = params.row.id;
        return (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: 0,
              cursor: "pointer",
            }}
            onClick={() => handleCopy(componentId)}
          >
            <ContentCopyIcon style={{ height: 14 }} />
            <span>{componentId ? componentId : na}</span>
          </div>
        );
      },
    },
    allColumns.manufacturerNameColumn,
    allColumns.productNameColumn,
    {
      ...allColumns.pznColumn,
      align: "center",
      headerAlign: "center",
      width: 90,
    },
    allColumns.connectedStatusColumn,
    {
      ...allColumns.bnTypeColumn,
      width: 54,
      renderCell: (params: GridRenderCellParams) =>
        getTypeImage(params.row.install.type),
    },
    {
      ...allColumns.propertyColumn,
      width: 150,
      renderCell: (params) =>
        renderLinkCell(
          params.row.install?.url,
          trimUrl(params.row.install?.url)
        ),
      valueGetter: (_: any, row: Record<string, any>) => row.install?.url || na,
    },
    allColumns.createdOnlyDateColumn,
    allColumns.updatedOnlyDateColumn,
    allColumns.actionsColumn((params, event) =>
      handleMenuClick(event, params.row)
    ),
  ];

  return (
    <ContentContainer>
      <Paper>
        <DataGridTable
          rows={rows}
          columns={columns}
          onRowSelectionModelChange={(ids) => setSelectedRows(ids)}
          selectedRows={selectedRows}
          loading={componentsApi.isLoading}
          filterBarType={2}
          hasCheckboxes={true}
          searchPlaceholder={
            t("tableColumns.searchPlaceholder") || "SKU, Products, ..."
          }
          tableInfo={t("tableInfo.buyNowComponents") || ""}
          isError={componentsApi?.isError}
          error={componentsApi?.error}
        />
      </Paper>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        {user?.role === "admin" && (
          <MenuItem onClick={handleEdit}>{t("general.edit")}</MenuItem>
        )}
        <MenuItem onClick={handleVisitComponent}>
          {t("general.preview")}
        </MenuItem>
      </Menu>

      <TabsProvider rowData={selectedRow as ComponentsResponse}>
        <EditDrawer
          open={drawerOpen}
          onClose={handleDrawerClose}
          updateRow={handleUpdateRow}
        />
      </TabsProvider>

      <Snackbar
        open={openSnackbar}
        autoHideDuration={2000}
        onClose={handleCloseSnackbar}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity="success"
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </ContentContainer>
  );
}
