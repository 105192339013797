import { FC, useState } from "react";
import { Box, Typography, Dialog, DialogContent, Button, useTheme } from "@mui/material";
import padlockGreen from "../../assets/svg/padlock-green.svg";
import padlockRed from "../../assets/svg/padlock-red.svg";
import padlockYellow from "../../assets/svg/padlock-yellow.svg";
import { GridCellParams } from "@mui/x-data-grid-pro";
import { useTranslation } from "react-i18next";
import useCustomTableUtils from "@/hooks/useColumnsData/utils";

const CollaborationCell: FC<GridCellParams> = (params) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const { na } = useCustomTableUtils();

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const getPadlockImage = () => {
    const { collaboration } = params.row;

    if (collaboration === "company write" || collaboration === "team write") {
      return padlockRed;
    } else if (
      collaboration === "company read" ||
      collaboration === "team read"
    ) {
      return padlockYellow;
    }
    return padlockGreen;
  };

  return params.row.collaboration ? (
    <>
      <Box
        sx={{ width: "100%", height: "100%" }}
        display="flex"
        alignItems="center"
      >
        <Box
          display="flex"
          justifyContent="flex-start"
          alignItems="center"
          sx={{
            width: "100%",
            height: "32px",
            border: `1px solid ${theme.palette.text.primary}`,
            borderRadius: "8px",
            padding: "7px",
            cursor: "pointer",
          }}
          onClick={handleOpen}
        >
          <img
            src={getPadlockImage()}
            alt="Green Padlock"
            style={{ paddingRight: "7px" }}
          />
          <Typography sx={{ fontWeight: 500 }}>
            {params.row.collaboration}
          </Typography>
        </Box>
      </Box>

      <Dialog open={open} onClose={handleClose}>
        <DialogContent>
          <Typography>{t("pages.labels.modalTitle")}</Typography>
          <Button onClick={handleClose} sx={{ mt: 2 }} variant="outlined">
            {t("general.close")}
          </Button>
        </DialogContent>
      </Dialog>
    </>
  ) : (
    na
  );
};

export default CollaborationCell;
