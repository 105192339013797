import { createQueryFunction } from "../queries/createQueryFunction";
import { useApiQuery } from "../useApiQuery";
import { PropertiesApiType, PropertiesResponse, PropertiesParams, ComponentsParams, ComponentsResponse } from "./types";

const basePath = "app/buy-now/"

export const useProperties = (): PropertiesApiType => {
    const allPropertiesApi = useApiQuery<PropertiesResponse[], PropertiesParams>({
      key: "installations",
      queryFn: createQueryFunction(`${basePath}installations`),
    });
    return { allPropertiesApi };
};
