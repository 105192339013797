import { THEMES } from "@/consts/themes";
import React, { useEffect } from "react";

const initialState = {
  theme: THEMES.DEFAULT,
  setTheme: (theme: string) => {},
};

const ThemeContext = React.createContext(initialState);

type ThemeProviderProps = {
  children: React.ReactNode;
};

function ThemeProvider({ children }: ThemeProviderProps) {
  const [theme, _setTheme] = React.useState<string>(initialState.theme);
  
  const setTheme = (theme: string) => {
    if (theme === 'system') {
      handleSystemData();
    } else {
      let newTheme = theme.toUpperCase();
     
      if (newTheme !== THEMES.LIGHT && newTheme !== THEMES.DARK) {
        console.warn('Incorrect theme specified. Use fallback!');
        newTheme = THEMES.LIGHT;
      }
    
      localStorage.setItem("theme", newTheme);
      _setTheme(newTheme);
    }
  };

  const handleSystemData = () => {
    const mediaQuery = window.matchMedia("(prefers-color-scheme: dark)");
    const setSystemMode = () => {
      const isSystemDark = mediaQuery.matches;
      localStorage.setItem("theme", isSystemDark ? THEMES.DARK : THEMES.LIGHT)
      setTheme(isSystemDark ? THEMES.DARK : THEMES.LIGHT);
    };

    setSystemMode();

    mediaQuery.addEventListener("change", setSystemMode);
    return () => {
      mediaQuery.removeEventListener("change", setSystemMode);
    };
  }

  useEffect(() => {
    const storedTheme = localStorage.getItem("theme");
    if (storedTheme) {
      let theme;
      try {
        // for backwards comp.
        theme = JSON.parse(storedTheme);
      } catch {
        theme = storedTheme;
      }
      return setTheme(theme);
    }
    return handleSystemData();
  }, []);

  return (
    <ThemeContext.Provider value={{ theme, setTheme }}>
      {children}
    </ThemeContext.Provider>
  );
}

export { ThemeProvider, ThemeContext };
