import FiltersPanel from "@/components/FiltersPanel";
import ContentContainer from "@/components/contentContainer";

import { Paper } from "@mui/material";
import { dependentAPIs, selectorsConfig } from "./consts";
import { useMemo } from "react";
import { GridColDef } from "@mui/x-data-grid-pro";
import MuiGridTable from "@/components/tables/DataGridTable";
import { useTranslation } from "react-i18next";
import { useColumns } from "@/hooks/useColumnsData";
import useData from "@/hooks/auth/useData";

const Availability = () => {
  const { t } = useTranslation();
  const { availabilityTableApi, isLoading } = useData();

  

  const rows = useMemo(() => {
    return (availabilityTableApi.data || [])?.map((row) => ({
      ...row,
    }));
  }, [availabilityTableApi.data]);

  const {
    posColumn,
    skuColumn,
    availabilityBrandColumn,
    availabilityProductNameColumn,
    availabilitySizeColumn,
    availabilityColumn,
    availabilityOldStatusColumn,
    changedDateColumn,
    lastChangedDateColumn,
  } = useColumns();

  const columns: GridColDef[] = [
    posColumn,
    skuColumn,
    availabilityBrandColumn,
    availabilityProductNameColumn,
    availabilitySizeColumn,
    availabilityColumn,
    availabilityOldStatusColumn,
    changedDateColumn,
    lastChangedDateColumn,
  ];

  return (
    <ContentContainer>
      <FiltersPanel
        selectorsConfig={selectorsConfig}
        dependentAPIs={dependentAPIs}
      />
      <Paper>
        <MuiGridTable
          rows={rows}
          columns={columns}
          loading={isLoading}
          filterBarType={2}
          searchPlaceholder={
            t("tableColumns.searchPlaceholder") || "SKU, Products, ..."
          }
          isError={availabilityTableApi.isError}
          error={availabilityTableApi.error}
        />
      </Paper>
    </ContentContainer>
  );
};

export default Availability;
