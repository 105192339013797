import axios from "axios";
import { refresh } from "../refresh/refreshFunction";

const apiUrl = import.meta.env.VITE_BASE_SERVER_URL;
const requestOptions = {
  headers: { "Content-Type": "application/x-www-form-urlencoded" },
  withCredentials: true,
};

/**
 * Function for reusable delete request
 * 
 * @template TResponse - The type representing the response data.
 * @template TParams - The type representing the response params.
 * @param {string} url - target for request
 * @param {TParams} variables  - body params
 * @returns {Promise<TResponse>} response data
 */
const createRequest = async <TResponse, TParams = undefined>(
  url: string,
  variables: TParams,
): Promise<TResponse> => {
  const response = await axios.delete<TResponse>(url, {
    ...requestOptions,
    data: variables, // Pass the variables in the data field
  });
  if (!response.data) {
    throw new Error("No data found");
  }
  return response.data;
};


/**
 * Function to create a mutation function for deleting data
 * @template TResponse - The type of the response data
 * @template TParams - The type of the mutation parameters
 * @param {string} endpoint - The API endpoint for the DELETE request
 * @returns {function} - The mutation function
 */
export const deleteMutationFunction = <TResponse, TParams>(
  endpoint: string
) => {
  return async (variables?: TParams): Promise<TResponse> => {
    if (!variables) {
      throw new Error("Parameters cannot be undefined");
    }
    const url = `${apiUrl}/${endpoint}`;
    try {
      const response = await createRequest<TResponse, TParams | undefined>(url, variables);
      return response;
    } catch(error: unknown) {
      if(axios.isAxiosError(error) && error.response?.status === 401) {
        try {
          await refresh();
          const response = await createRequest<TResponse, TParams | undefined>(url, variables);
          return response;
        } catch(retryError: unknown) {
          throw retryError;
        }
        
      }
      throw error;
    }
  };
};
