import React from "react";
import { SidebarItemsType } from "@/types/sidebar";
import reduceChildRoutes from "./reduceChildRoutes";

type SidebarNavListProps = {
  depth: number;
  pages: SidebarItemsType[];
  navPath: string;
  setNavPath:  React.Dispatch<React.SetStateAction<string>>;
};

const SidebarNavList: React.FC<SidebarNavListProps> = (props) => {
  const { pages, depth, navPath, setNavPath } = props;

  const childRoutes = pages.reduce(
    (items, page) => reduceChildRoutes({ items, page, depth, navPath, setNavPath }),
    [] as JSX.Element[]
  );

  return <React.Fragment>{childRoutes}</React.Fragment>;
};

export default SidebarNavList;
