import { VisibilityApiType } from "./types";
import { useShareOfSearch } from "./useShareOfSearch";

const useVisibility = (): VisibilityApiType => {
  const shareOfSearchApi = useShareOfSearch();

    return {
      shareOfSearchApi,
    }
};

export default useVisibility;