import { createMutationFunction } from "./mutations/createMutationFunction";
import { ApiMutation, useApiMutation } from "./useApiMutation";

/**
 * Type representing the API mutation for sign out.
 *
 * @template TResponse - The type of the response data.
 */
export type SignOutApiType = ApiMutation<SignOutResponse>;

/**
 * Interface representing the response for sign out.
 */
export interface SignOutResponse {
  role: null;
}

/**
 * Custom hook to handle user logout functionality.
 *
 * This hook uses the `useApiMutation` hook to create a mutation for the logout operation.
 * It leverages a mutation function created by `createMutationFunction` to perform the logout action.
 *
 * @returns {ReturnType<typeof useApiMutation<SignOutResponse>>} An object returned by the `useApiMutation` hook which includes
 * the mutation function and the mutation state (e.g., isLoading, error, data).
 *
 * @example
 * const { execute, isLoading, isError, error, data } = useSignOut();
 *
 * // To trigger logout
 * execute();
 */
export const useSignOut = () => {
  return useApiMutation<SignOutResponse>({
    mutationFn: createMutationFunction("logout"),
  });
};
