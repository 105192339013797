import React from "react";

const WrenchTool = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
      <g>
        <path
          d="M12.2501 5.25043C12.0974 5.40621 12.0119 5.61564 12.0119 5.83376C12.0119 6.05189 12.0974 6.26132 12.2501 6.4171L13.5835 7.75043C13.7392 7.90312 13.9487 7.98865 14.1668 7.98865C14.3849 7.98865 14.5943 7.90312 14.7501 7.75043L17.8918 4.60876C18.3108 5.53476 18.4377 6.56646 18.2555 7.56639C18.0733 8.56631 17.5907 9.48697 16.872 10.2057C16.1533 10.9244 15.2327 11.407 14.2327 11.5891C13.2328 11.7713 12.2011 11.6445 11.2751 11.2254L5.51679 16.9838C5.18527 17.3153 4.73563 17.5015 4.26679 17.5015C3.79795 17.5015 3.34831 17.3153 3.01679 16.9838C2.68527 16.6522 2.49902 16.2026 2.49902 15.7338C2.49902 15.2649 2.68527 14.8153 3.01679 14.4838L8.77512 8.72543C8.35609 7.79944 8.22922 6.76774 8.41141 5.76781C8.5936 4.76788 9.0762 3.84723 9.79489 3.12853C10.5136 2.40984 11.4342 1.92724 12.4342 1.74505C13.4341 1.56286 14.4658 1.68973 15.3918 2.10876L12.2501 5.25043Z"
          stroke="#eeeeee"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
};

export default WrenchTool;
