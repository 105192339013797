import {createQueryFunction} from "../queries/createQueryFunction";
import {useApiQuery} from "../useApiQuery";
import {ComponentsParams, ComponentsResponse} from "./types";
import {ErrorCallback, useApiMutation} from "@/api/useApiMutation";
import {ContentType, createMutationFunction} from "@/api/mutations/createMutationFunction";
import {t} from "i18next";

const basePath = "app/buy-now/"

export interface UpdateComponentParams {
    id: string;
    install: number;
    product: number;
    demo: number;
}

export interface AssignConfigComponentParams {
    instance_id: string;
    config_id: number;
    variants: string[];
    type: number;
    start: string;
    end: string;
}

export interface UpdateAssignmentConfigComponentParams {
    id: string;
    instance_config_id: number;
    config_id: number;
    variants: string[];
    type: number;
    start: string;
    end: string;
}

const handleCustomError = ({
                               error,
                               setError,
                               setData,
                           }: ErrorCallback<ComponentsResponse>) => {
    const errorMessage =
        error.response && error.response.status === 400
            ? t("user.updateError")
            : `${t("errors.globalError")}: ${error.message}`;

    setError(errorMessage);
    setData(undefined);
};


export const useComponents = () => {
    return useApiQuery<ComponentsResponse[], ComponentsParams>({
        key: "instances",
        queryFn: createQueryFunction(`${basePath}instances`),
    });
};


export const useUpdateComponent = () => {
    return useApiMutation<ComponentsResponse, UpdateComponentParams>({
        mutationFn: (variables) =>
            createMutationFunction<ComponentsResponse, UpdateComponentParams>(
                `${basePath}instances/${variables?.id}`,
                ContentType.JSON,
                'PUT'
            )(variables),
        onErrorCallback: handleCustomError,
    });
};


export const useAssignConfigComponent = () => {
    return useApiMutation<ComponentsResponse, AssignConfigComponentParams>({
        mutationFn: (variables) =>
            createMutationFunction<ComponentsResponse, AssignConfigComponentParams>(
                `${basePath}instances/${variables?.instance_id}/configs`
            )(variables),
        onErrorCallback: handleCustomError,
    });
};


export const useUpdateAssignmentConfigComponent = () => {
    return useApiMutation<ComponentsResponse, UpdateAssignmentConfigComponentParams>({
        mutationFn: (variables) =>
            createMutationFunction<ComponentsResponse, UpdateAssignmentConfigComponentParams>(
                `${basePath}instances/${variables?.id}/configs/${variables?.instance_config_id}`,
                ContentType.JSON,
                'PUT'
            )(variables),
        onErrorCallback: handleCustomError,
    });
};
