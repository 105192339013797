import { FC, ReactNode } from "react";
import { Box, useTheme } from '@mui/material';
import * as styles from "./styles";

interface ContentContainerProps {
    children: ReactNode;
}
/**
 * Container for pages. Use this to ensure right gapping
 *
 * @returns {JSX.Element} Container for pages content
 */
const ContentContainer: FC<ContentContainerProps> = ({ children }): JSX.Element => {
    const theme = useTheme();
    return (
        <Box sx={styles.containerStyles} gap={theme.gap}>
            {children}
        </Box>
    );
};

export default ContentContainer;