import { createQueryFunction } from "./queries/createQueryFunction";
import { ApiQuery, useApiQuery } from "./useApiQuery";

export type MerchantProductContentApiType = ApiQuery<MerchantProductContentResponse, MerchantProductContentParams>;

export interface MerchantProductContentParams {
    merchant_id: string;
    product: string;
}

export interface MerchantProductContentResponse {
    MPID: number;
    PID?: number;
    MERCHANT_ID: number;
    SKU: string;
    VARIANT_ID?: number;
    PZN?: string;
    EXTERNAL_ID?: string;
    GTIN?: string;
    CODE_39?: string;
    SKU_LT?: string;
    PRICE?: number;
    PRICE_BEFORE?: number;
    PRICE_CHANGED?: string;  // Assuming datetime fields are returned as strings
    AVAILABILITY?: string;
    AVAILABILITY_BEFORE?: string;
    AVAILABILITY_CHANGED?: string;
    URL?: string;
    CANONICAL?: string;
    TITLE?: string;
    DESCRIPTION?: string;
    INDICATION?: string;
    TEASER?: string;
    RATING?: number;
    RATINGS?: number;
    CREATED?: string;
    UPDATED?: string;
    UPDATED_PP?: string;
    MANUFACTURER?: string;
    LEAFLET?: string;
    STORE_LINK?: string;
    STORE?: string;
    SELLER_ID?: string;
}

const key = "merchant-product-content";

export const useMerchantProductContent = () => {
    return useApiQuery<MerchantProductContentResponse, MerchantProductContentParams>({
        key: key,
        queryFn: createQueryFunction<MerchantProductContentResponse, MerchantProductContentParams>("content/get_merchant_product"),
    });
};
