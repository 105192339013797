import { SelectorDef } from "@/components/FiltersPanel/types";
import { SelectorNames } from "@/components/FiltersPanel/consts";
  
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
export const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export const selectorsConfig: Array<SelectorDef> = [
  { name: SelectorNames.Merchant },
  { name: SelectorNames.Focus, dependentFilters: [SelectorNames.Products, SelectorNames.SubBrand, SelectorNames.ParentBrand], defaultValue: 0},
  { name: SelectorNames.ParentBrand, dependentFilters: [SelectorNames.Products, SelectorNames.SubBrand]},
  { name: SelectorNames.SubBrand, dependentFilters: [SelectorNames.Products]},
  { name: SelectorNames.Products },
  { name: SelectorNames.Label },
];