import { createMutationFunction } from "../mutations/createMutationFunction";
import { useApiMutation } from "../useApiMutation";
import { PackshotsAndVideosApiType, PackshotsAndVideosResponse } from "./types";

export const usePackshotsAndVideos = (): PackshotsAndVideosApiType => {
  const packshotsAndVideosTableApi = useApiMutation<PackshotsAndVideosResponse[]>({
    mutationFn: (variables, signal) =>
      createMutationFunction<PackshotsAndVideosResponse[]>("content/packshots-and-videos")(
        variables,
        signal
      ),
  });
  return  packshotsAndVideosTableApi;
};
