import { createQueryFunction } from "../queries/createQueryFunction";
import { useApiQuery } from "../useApiQuery";
import { KeywordResponse, SetupParams } from "./types";

const key = "keywords";

export const useKeywords = () => {
  return useApiQuery<KeywordResponse[], SetupParams>({
    key: key,
    queryFn: createQueryFunction<KeywordResponse[], SetupParams>(
      "app/setup/keywords"
    ),
  });
};
