import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import {
  Avatar,
  Badge,
  Divider,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
} from "@mui/material";
import { green } from "@mui/material/colors";
import { Box, useTheme } from "@mui/system";
import { t } from "i18next";
import PaymentIcon from "@mui/icons-material/Payment";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import PeopleIcon from "@mui/icons-material/People";
import WorkIcon from "@mui/icons-material/Work";
import SettingsIcon from "@mui/icons-material/Settings";
import CompareArrowsIcon from "@mui/icons-material/CompareArrows";
import LogoutIcon from "@mui/icons-material/Logout";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { NestedMenuItem } from "mui-nested-menu";
import useAuth from "@/hooks/auth/useAuth";
import useData from "@/hooks/auth/useData";
import type { Manufacturer } from "@/api/useManufacturers";
import UserSettingsDialog from "./UserSettingsDialog";

const AvatarBadge = styled(Badge)`
  margin-right: ${(props) => props.theme.spacing(1)};

  span {
    background-color: ${() => green[400]};
    border: 2px solid ${(props) => props.theme.palette.background.paper};
    height: 12px;
    width: 12px;
    border-radius: 50%;
  }
`;

const iconStyle = {
  marginRight: "10px",
  width: "20px",
  height: "20px",
};

const sectionStyle = {
  paddingLeft: "15px",
  paddingTop: "5px",
  paddingBottom: "5px",
};

const MenuItemStyle = {
  padding: "5px, 10px, 5px, 10px",
};

const flyoutWidth = 228;

function NavbarUserDropdown() {
  const [anchorMenu, setAnchorMenu] = useState<null | HTMLElement>(null);

  const { signOut } = useAuth();
  const theme = useTheme();
  const {
    manufacturersApi,
    user,
    setSettings,
    setManufacturer,
    selectedManufacturers,
  } = useData();
  const color = theme.palette.mode === 'dark' ? theme.palette.grey.A100 : theme.palette.grey[600]

  const manufacturersData = manufacturersApi?.manufacturers?.data || [];
  const possibleManufacturers = manufacturersData.filter((m) => m.access !== 'see');
  const allManufacturerIsSelected = (possibleManufacturers.length > 0 && 
    possibleManufacturers.length === selectedManufacturers.length);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const firstName = user?.first_name;
  const lastName = user?.last_name;
  const userName = user?.username;
  const displayName =
    !firstName || !lastName ? userName : `${firstName} ${lastName}`;

  const toggleMenu = (event: React.SyntheticEvent<HTMLElement>) => {
    setAnchorMenu(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorMenu(null);
    setAnchorEl(null);
  };

  const handleSignOut = () => {
    signOut();
  };

  const handleSelectAll = () => {
    if(!allManufacturerIsSelected) {
      setManufacturer(possibleManufacturers);
    } 
  };

  const handleManufacturer = (manufacturer: Manufacturer) => {
    setManufacturer([manufacturer]);
  };

  const handleDialogClick = (event: React.SyntheticEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <React.Fragment>
      <Tooltip title={t("profile.navBar.account")}>
        <Box
          gap={1}
          display={"flex"}
          alignItems={"center"}
          onClick={toggleMenu}
          sx={{ cursor: "pointer", paddingBottom: 1 }}
          aria-owns={anchorMenu ? "menu-appbar" : undefined}
          aria-haspopup="true"
          mx={1}
          width={220}
        >
          <AvatarBadge
            overlap="circular"
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            variant="dot"
          >
            {<Avatar alt="Lucy Lavender" />}
          </AvatarBadge>
          <Box
            display={"flex"}
            flexDirection={"column"}
            gap={1}
            textAlign={"left"}
            width={"100%"}
            sx={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            <Typography
              lineHeight={1}
              fontWeight={"bold"}
              color={theme.palette.text.primary}
            >
              {allManufacturerIsSelected && user?.company_abbr
                ? user?.company_abbr?.toUpperCase()
                : selectedManufacturers[0]?.NAME.toUpperCase()}
            </Typography>
            <Typography
              color={theme.palette.text.primary}
              lineHeight={1}
              sx={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {displayName}
            </Typography>
          </Box>
          <ArrowDropDownIcon />
        </Box>
      </Tooltip>
      <Menu
        id="menu-appbar"
        anchorEl={anchorMenu}
        open={Boolean(anchorMenu)}
        onClose={handleClose}
        sx={{
          "& .MuiPaper-root": {
            width: 200,
          },
        }}
      >
        <Typography style={sectionStyle}>
          {t("profile.navBar.accountSection")}
        </Typography>

        <NestedMenuItem
          style={MenuItemStyle}
          leftIcon={
            <CompareArrowsIcon
              style={{ ...iconStyle, marginLeft: "10px", marginRight: "0px" }}
            />
          }
          rightIcon={null}
          label={t("profile.navBar.selectManufacturers") || "Select Manufactrers"}
          parentMenuOpen={Boolean(anchorMenu)}
          disabled={manufacturersData.length === 1}
          MenuProps={{
            anchorOrigin: {
              vertical: -parseInt(theme.spacing(2)),
              horizontal: -flyoutWidth,
            },
            sx: {
              "&& .Mui-selected": {
                fontWeight: "bold",
              },
            },
          }}
        >
          <MenuItem
            onClick={handleSelectAll}
            selected={allManufacturerIsSelected}
          >
            {t("general.selectAll")}
          </MenuItem>

          {manufacturersData.map((manufacturer: Manufacturer) => {
            const selected = selectedManufacturers.some(
              (m) => m.ID === manufacturer.ID
            );

            const isDisabled = !(
              (manufacturer.access === "write" ||
                manufacturer.access === "read") &&
              manufacturer.access !== null
            );

            return (
              <MenuItem
                key={manufacturer.ID}
                onClick={() => handleManufacturer(manufacturer)}
                selected={selected}
                style={{
                  ...MenuItemStyle,
                  minWidth: "210px",
                  maxWidth: "210px",
                }}
                disabled={isDisabled}
                sx={{
                  color: isDisabled
                    ? theme.palette.grey[500]
                    : theme.palette.text.primary,
                }}
              >
                <PeopleIcon sx={{ color: color}} style={iconStyle} />
                {manufacturer.NAME}
              </MenuItem>
            );
          })}
        </NestedMenuItem>

        <MenuItem onClick={handleDialogClick} style={MenuItemStyle}>
          <SettingsIcon sx={{ color: color}} style={iconStyle} />
          {t("profile.navBar.userSettings.title")}
        </MenuItem>
        <UserSettingsDialog
          open={Boolean(anchorEl)}
          onClose={handleClose}
          setSettings={setSettings}
          user={user}
        />
        <Divider />
        <Typography style={sectionStyle}>
          {t("profile.navBar.companySection")}
        </Typography>
        <MenuItem style={MenuItemStyle}>
          <WorkIcon sx={{ color: color}} style={iconStyle} />
          {t("profile.navBar.manageCompanies")}
        </MenuItem>
        <MenuItem style={MenuItemStyle}>
          <PeopleIcon sx={{ color: color}} style={iconStyle} />
          {t("profile.navBar.manageUsers")}
        </MenuItem>
        <MenuItem style={MenuItemStyle}>
          <SettingsOutlinedIcon sx={{ color: color}} style={iconStyle} />
          {t("profile.navBar.companySettings")}
        </MenuItem>
        <MenuItem style={MenuItemStyle}>
          <PaymentIcon sx={{ color: color}} style={iconStyle} />
          {t("profile.navBar.billing")}
        </MenuItem>
        <Divider />

        <MenuItem onClick={handleSignOut} style={MenuItemStyle}>
          <LogoutIcon sx={{ color: color}} style={iconStyle} />
          {t("profile.navBar.signOut")}
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
}

export default NavbarUserDropdown;
