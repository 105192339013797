import { FC } from "react";
import { 
    Box, 
    Card as MuiCard,
    CardContent,
    CardHeader,
    Tooltip,
} from '@mui/material';
import { spacing } from "@mui/system";
import InfoSvg from "@/assets/img/info.svg";
import styled from "@emotion/styled";
import { ChartWrapperProps } from "./types";

const Card = styled(MuiCard)(spacing);

/**
 * Container for graphs.
 *
 * @returns {JSX.Element} Container for graphs
 */
const ChartWrapper: FC<ChartWrapperProps> = ({ 
    height = 400, 
    graphPadding = '16px',
    headerVisible = false,
    tooltip,
    title, 
    children, 
}): JSX.Element => {
    return (
        <Card sx={{height: height, display: 'flex', flexDirection: 'column'}}>
          {headerVisible && 
            <CardHeader
                action={
                    tooltip && 
                    <Tooltip title={tooltip} sx={{ cursor: "pointer" }} placement="top">
                        <img src={InfoSvg} alt="info" />
                    </Tooltip>
                }
                title={title}
            />
          }
          <CardContent sx={{
            padding: `${graphPadding} !important`,
            flexGrow: 1,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}> 
            {children}
          </CardContent>
        </Card>
      );
};

export default ChartWrapper;