import { useEffect, useRef, DependencyList } from 'react'

/**
 * Actually run only on update, not initial render as useEffect.
 * https://stackoverflow.com/questions/53179075/with-useeffect-how-can-i-skip-applying-an-effect-upon-the-initial-render
 */
export const useDidUpdateEffect = (fn: () => void, inputs: DependencyList) => {
  const didMountRef = useRef(false)

  useEffect(() => {
    if (didMountRef.current) fn()
    else didMountRef.current = true
  }, inputs)
}
