import { createMutationFunction } from "../mutations/createMutationFunction";
import { useApiMutation } from "../useApiMutation";
import { ContentTableApiType, ContentTableResponseLine } from "./types";

export const useContentTable = (): ContentTableApiType => {
  const contentTableApi = useApiMutation<ContentTableResponseLine[]>({
    mutationFn: (variables, signal) =>
      createMutationFunction<ContentTableResponseLine[]>("content/content")(
        variables,
        signal
      ),
  });
  return contentTableApi;
};
