import React, { useState, useEffect, useContext } from "react";
import {
  Dialog,
  Box,
  Typography,
  FormControl,
  Select,
  MenuItem as SelectMenuItem,
  IconButton,
  ToggleButtonGroup,
  ToggleButton,
  FormHelperText,
  Button,
} from "@mui/material";
import { useTheme } from "@mui/system";
import useAppTheme from "@/hooks/useTheme";
import CloseIcon from "@mui/icons-material/Close";
import LightModeIcon from "@mui/icons-material/LightMode";
import DarkModeIcon from "@mui/icons-material/DarkMode";
import SettingsSystemDaydreamIcon from "@mui/icons-material/SettingsSystemDaydream";
import { SaveIcon } from "lucide-react";
import { t } from "i18next";
import { DataContext } from "@/contexts/DataContext";
import { User, Settings } from "@/types/user";
import i18n from "i18next";
import { uiModes, currencies, numbersFormat, languageOptions } from "@/consts/settings";

interface UserSettingsDialogProps {
  open: boolean;
  onClose: () => void;
  user: User | undefined;
  setSettings: (data: Settings) => void;
}

const UserSettingsDialog: React.FC<UserSettingsDialogProps> = ({
  open,
  onClose,
  user,
  setSettings,
}) => {
  const themeTemplate = useTheme();
  const { setTheme } = useAppTheme();


  const [uiMode, setUiMode] = useState(
    user?.settings?.uiMode && uiModes.includes(user?.settings?.uiMode) ?
      user?.settings?.uiMode :
      "light"
  );
  const [language, setLanguage] = useState(
    user?.settings?.language && user?.settings?.language in languageOptions ?
      user?.settings?.language :
      "en"
  );
  const [currency, setCurrency] = useState(
    user?.settings?.currency && currencies.includes(user?.settings?.currency) ?
      user?.settings?.currency :
      "euro"
  );
  const [numberFormat, setNumberFormat] = useState(
    user?.settings?.numberFormat && user?.settings?.numberFormat in numbersFormat ?
      user?.settings?.numberFormat :
      "en-US"
  );

  const { updateUserApi } = useContext(DataContext);

  useEffect(() => {
    if (user?.settings) {
      setUiMode(
        user?.settings?.uiMode && uiModes.includes(user?.settings?.uiMode) ?
          user?.settings?.uiMode :
          "light"
        );
      setCurrency(
        user?.settings?.currency && currencies.includes(user?.settings?.currency) ?
          user?.settings?.currency :
          "euro"
      );
      setLanguage(
        user?.settings?.language && user?.settings?.language in languageOptions ?
          user?.settings?.language :
          "en"
      );
      setNumberFormat(
        user?.settings?.numberFormat && user?.settings?.numberFormat in numbersFormat ?
          user?.settings?.numberFormat :
          "en-US"
      )
    }
  }, [user?.settings]);

  const handleUiModeChange = (
    _event: React.MouseEvent<HTMLElement>,
    newMode: string
  ) => {
    setUiMode(newMode);
  };

  const handleSave = async () => {
    try {
      await updateUserApi?.execute({
        settings: {
          uiMode,
          language,
          currency,
          numberFormat,
        },
      });
      setSettings({
        uiMode,
        language,
        currency,
        numberFormat,
      });
      localStorage.setItem("language", language);
      i18n.changeLanguage(language);
      setTheme(uiMode);
    } catch (error) {
      console.error("Error updating user settings:", error);
    } finally {
      onClose();
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          padding: themeTemplate.spacing(3),
          borderRadius: themeTemplate.shape.borderRadius * 2,
          maxWidth: 300,
          width: "100%",
        },
      }}
    >
      <IconButton
        onClick={onClose}
        sx={{
          position: "absolute",
          top: themeTemplate.spacing(1.5),
          right: themeTemplate.spacing(1.5),
          zIndex: 1,
          color: themeTemplate.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: themeTemplate.spacing(3),
          paddingTop: themeTemplate.spacing(5),
          paddingBottom: themeTemplate.spacing(5),
          flexGrow: 1,
          alignItems: "left",
        }}
      >
        <Typography variant="h5" component="h2" gutterBottom>
          {t("profile.navBar.userSettings.title")}
        </Typography>

        <FormControl variant="standard" fullWidth>
          <FormHelperText>
            {t("profile.navBar.userSettings.numberFormat")}
          </FormHelperText>
          <Select
            labelId="numberFormat-select-label"
            value={numberFormat}
            onChange={(e) => setNumberFormat(e.target.value)}
            label={t("profile.navBar.userSettings.numberFormat")}
            sx={{
              minWidth: 120,
            }}
          >
            {Object.entries(numbersFormat).map(([value, label]) => (
              <SelectMenuItem key={value} value={value}>
                {label}
              </SelectMenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl variant="standard" fullWidth>
          <FormHelperText>
            {t("profile.navBar.userSettings.language")}
          </FormHelperText>
          <Select
            labelId="language-select-label"
            value={language}
            onChange={(e) => setLanguage(e.target.value)}
            label={t("profile.navBar.userSettings.language")}
            sx={{
              minWidth: 120,
            }}
          >
            {Object.entries(languageOptions).map(([value, label]) => (
              <SelectMenuItem key={value} value={value}>
                {label}
              </SelectMenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl variant="standard" fullWidth>
          <FormHelperText>
            {t("profile.navBar.userSettings.currency")}
          </FormHelperText>
          <Select
            labelId="currency-select-label"
            value={currency}
            onChange={(e) => setCurrency(e.target.value)}
            label={t("profile.navBar.userSettings.currency")}
            sx={{
              minWidth: 120,
            }}
          >
            <SelectMenuItem value="us_dollar">US-Dollar (USD)</SelectMenuItem>
            <SelectMenuItem value="euro">Euro (EUR)</SelectMenuItem>
          </Select>
        </FormControl>

        <FormControl variant="standard" fullWidth>
          <FormHelperText>
            {t("profile.navBar.userSettings.mode")}
          </FormHelperText>
          <ToggleButtonGroup
            color="primary"
            value={uiMode}
            exclusive
            onChange={handleUiModeChange}
            aria-label="Mode"
            sx={{
              display: "flex",
              justifyContent: "left",
            }}
          >
            <ToggleButton value="light">
              <LightModeIcon sx={{ marginRight: 1 }} />
              {t("profile.navBar.userSettings.lightMode")}
            </ToggleButton>
            <ToggleButton value="system">
              <SettingsSystemDaydreamIcon sx={{ marginRight: 1 }} />
              {t("profile.navBar.userSettings.systemMode")}
            </ToggleButton>
            <ToggleButton value="dark">
              <DarkModeIcon sx={{ marginRight: 1 }} />
              {t("profile.navBar.userSettings.darkMode")}
            </ToggleButton>
          </ToggleButtonGroup>
        </FormControl>
      </Box>

      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          padding: themeTemplate.spacing(2),
        }}
      >
        <Button
          variant="contained"
          color="primary"
          onClick={handleSave}
          startIcon={<SaveIcon />}
          disabled={updateUserApi?.isLoading}
          sx={{
            minWidth: 150,
            padding: themeTemplate.spacing(1.5),
            borderRadius: themeTemplate.shape.borderRadius,
          }}
        >
          {updateUserApi?.isLoading
            ? t("general.saving")
            : t("general.save")}
        </Button>
      </Box>
    </Dialog>
  );
};

export default UserSettingsDialog;
