import React, { useEffect } from "react";
import {
  TextField,
  Box,
  Typography,
  Button,
  Grid,
  FormControl,
  FormGroup,
} from "@mui/material";
import { useTabsContext } from "@/contexts/TabsContext";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import { Config, ConfigImage, ConfigText } from "@/api/buynow/types";
import { t } from "i18next";
import { v4 as uuidv4 } from "uuid";
import { validationRules } from "../consts";
import { generatePrefilledEntries } from "../utils";

const ItemsTab = () => {
  const {
    configs,
    setConfigs,
    defaultConfigIndex,
    setCurrentVariants,
    currentVariants,
    setValidationErrors,
    warnings,
    setWarnings,
    imageEntries,
    setImageEntries,
    isItemsTabInitialized,
    setIsItemsTabInitialized,
  } = useTabsContext();
  const currentConfig = configs[defaultConfigIndex];
  const currentConfigDetails = currentConfig.config || {};

  useEffect(() => {
    if (isItemsTabInitialized) return;

    const prefilledEntries = generatePrefilledEntries(
      currentConfigDetails as Config,
      currentVariants
    );

    setImageEntries((prevEntries) => {
      const entriesAreSame =
        JSON.stringify(prevEntries) === JSON.stringify(prefilledEntries);
      return entriesAreSame ? prevEntries : prefilledEntries;
    });

    setIsItemsTabInitialized(true);
  }, [
    isItemsTabInitialized,
    currentVariants,
    currentConfigDetails,
    setImageEntries,
    setIsItemsTabInitialized,
  ]);

  const handleInputChange = (
    id: string,
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { name, value } = e.target;
    const updatedEntries = imageEntries.map((entry) =>
      entry.id === id ? { ...entry, [name]: value } : entry
    );

    const updatedWarnings = { ...warnings };
    const fieldKey = `${name}-${id}`;

    if (name === "image") {
      if (value.trim() === "") {
        delete updatedWarnings[fieldKey];
      } else if (!validationRules.url.pattern.test(value)) {
        updatedWarnings[fieldKey] = validationRules.url.message;
      } else {
        delete updatedWarnings[fieldKey];
      }
    } else if (validationRules[name]) {
      const { pattern, message } = validationRules[name];
      if (!pattern.test(value)) {
        updatedWarnings[fieldKey] = message;
      } else {
        delete updatedWarnings[fieldKey];
      }
    }

    setWarnings(updatedWarnings);
    setImageEntries(updatedEntries);

    setValidationErrors((prevErrors) => ({
      ...prevErrors,
      items: { ...updatedWarnings },
    }));
  };

  const deleteImageEntry = (id: string) => {
    const updatedEntries = imageEntries.filter((entry) => entry.id !== id);
    setImageEntries(updatedEntries);

    const updatedWarnings = { ...warnings };
    Object.keys(updatedWarnings).forEach((key) => {
      if (key.includes(id)) {
        delete updatedWarnings[key];
      }
    });
    setWarnings(updatedWarnings);
    setValidationErrors((prevErrors) => ({
      ...prevErrors,
      items: { ...updatedWarnings },
    }));
  };

  const updateConfig = () => {
    const variants: string[] = [];
    const images: Array<ConfigImage> = [];
    const texts: Array<ConfigText> = [];

    imageEntries.forEach((entry) => {
      const { sku, image, width, height, description } = entry;
      const trimmedSku = sku.trim();
      const trimmedImage = image.trim();
      const trimmedDescription = description.trim();

      if (!trimmedSku) return;

      variants.push(trimmedSku);

      if (trimmedImage) {
        images.push({
          sku: trimmedSku,
          image: trimmedImage || "",
          imageFormat: { width: width || "", height: height || "" },
        });
      }

      if (trimmedDescription) {
        texts.push({
          sku: trimmedSku,
          text: `<p>${trimmedDescription}</p>`,
        });
      }
    });

    const updatedConfigDetails = {
      images: images.length > 0 ? images : [],
      texts: texts.length > 0 ? texts : [],
    };
  
    console.log('currentConfig',currentConfig);
    
    setConfigs((prevConfigs) => {
      const newConfigs = [...prevConfigs];
      // if (newConfigs[defaultConfigIndex]?.config?.config?.config) {
      //   delete newConfigs[defaultConfigIndex]?.config?.config?.config; // temporary to avoid false config structure
      // }
      newConfigs[defaultConfigIndex] = {
        // variants level
        ...newConfigs[defaultConfigIndex],
        config: {
          // comments level
          ...newConfigs[defaultConfigIndex].config,
          config: {
            // config details level
            ...newConfigs[defaultConfigIndex].config.config,
            ...updatedConfigDetails,
          },
        },
      };
      console.log(newConfigs[defaultConfigIndex]);
      
      return newConfigs;
    });
    setCurrentVariants(variants);
  };

  const addImageEntry = () => {
    setImageEntries([
      ...imageEntries,
      {
        id: uuidv4(),
        sku: "",
        image: "",
        width: "",
        height: "",
        description: "",
      },
    ]);
  };

  useEffect(() => {
    updateConfig();
  }, [imageEntries]);

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
      {imageEntries.length > 0 ? (
        imageEntries.map((entry, index) => (
          <FormGroup key={entry.id} sx={{ mb: 3, paddingTop: "15px" }}>
            <FormControl required fullWidth>
              <TextField
                label={`${t("pages.buyNowComponent.editModal.items.PZN")} ${
                  index + 1
                }`}
                name="sku"
                value={entry.sku}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  handleInputChange(entry.id, e)
                }
                fullWidth
                error={!!warnings[`sku-${entry.id}`]}
                helperText={warnings[`sku-${entry.id}`] || ""}
              />
            </FormControl>

            <Typography variant="h6" sx={{ padding: "15px 0" }}>
              Packshot
            </Typography>

            <FormControl error={!!warnings[`image-${entry.id}`]} fullWidth>
              <TextField
                label={`${t("pages.buyNowComponent.editModal.items.url")} ${
                  index + 1
                }`}
                name="image"
                value={entry.image}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  handleInputChange(entry.id, e)
                }
                fullWidth
                sx={{ paddingBottom: "15px" }}
                error={!!warnings[`image-${entry.id}`]}
                helperText={warnings[`image-${entry.id}`] || ""}
              />
            </FormControl>

            <Box
              sx={{ justifyContent: "space-between", display: "flex", gap: 3 }}
            >
              <FormControl error={!!warnings[`width-${entry.id}`]} fullWidth>
                <TextField
                  label={t("pages.buyNowComponent.editModal.items.width")}
                  name="width"
                  value={entry.width}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    handleInputChange(entry.id, e)
                  }
                  fullWidth
                  error={!!warnings[`width-${entry.id}`]}
                  helperText={warnings[`width-${entry.id}`] || ""}
                />
              </FormControl>
              <FormControl error={!!warnings[`height-${entry.id}`]} fullWidth>
                <TextField
                  label={t("pages.buyNowComponent.editModal.items.height")}
                  name="height"
                  value={entry.height}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    handleInputChange(entry.id, e)
                  }
                  fullWidth
                  error={!!warnings[`height-${entry.id}`]}
                  helperText={warnings[`height-${entry.id}`] || ""}
                />
              </FormControl>
            </Box>

            <Box sx={{ paddingTop: "15px" }}>
              <FormControl fullWidth>
                <TextField
                  label={t("pages.buyNowComponent.editModal.items.description")}
                  name="description"
                  value={entry.description}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    handleInputChange(entry.id, e)
                  }
                  multiline
                  rows={10}
                  fullWidth
                  variant="outlined"
                  sx={{ "& .MuiInputBase-root": { minHeight: "243px" } }}
                />
              </FormControl>
            </Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                paddingTop: "10px",
              }}
            >
              <Button
                color="error"
                variant="outlined"
                onClick={() => deleteImageEntry(entry.id)}
                sx={{ display: "flex", gap: 1 }}
              >
                <DeleteIcon sx={{ width: 15, height: 15 }} />
                {t("pages.buyNowComponent.editModal.items.delete")}
              </Button>
            </Box>
          </FormGroup>
        ))
      ) : (
        <Typography variant="body1" color="textSecondary">
          {t("pages.buyNowComponent.editModal.items.noVariants")}
        </Typography>
      )}

      <Box>
        <Button
          variant="outlined"
          onClick={addImageEntry}
          sx={{ display: "flex", gap: 1 }}
        >
          <AddIcon sx={{ width: 15, height: 15 }} />{" "}
          {t("pages.buyNowComponent.editModal.items.variant")}
        </Button>
      </Box>
    </Box>
  );
};

export default ItemsTab;
