import {AiReviewSummaryApiType, SummaryResponse} from "@/api/content/types";
import {useApiMutation} from "@/api/useApiMutation";
import {createMutationFunction} from "@/api/mutations/createMutationFunction";

export const useAiReviewSummary = (): AiReviewSummaryApiType => {
    return useApiMutation<SummaryResponse>({
      mutationFn: (variables, signal) =>
          createMutationFunction<SummaryResponse>(
              "content/review-summary"
          )(variables, signal),
  });
};
