import { SelectorDef } from "@/components/FiltersPanel/types";
import { SelectorNames } from "@/components/FiltersPanel/consts";
import { DataState } from "@/types/dataContext";

export const selectorsConfig: SelectorDef[] = [
  { name: SelectorNames.Merchant},
  { name: SelectorNames.Market, disabled: true},
  { name: SelectorNames.ParentBrand, dependentFilters: [SelectorNames.Products, SelectorNames.SubBrand]},
  { name: SelectorNames.SubBrand, dependentFilters: [SelectorNames.Products]},
  { name: SelectorNames.Products },
  { name: SelectorNames.Focus, dependentFilters: [SelectorNames.Products, SelectorNames.ParentBrand, SelectorNames.SubBrand], defaultValue: 0 },
];

export const dependentAPIs: (keyof DataState)[] = [
  "pricingTableApi",
];

export const hiddenTableColumns = [
  'avp',
]