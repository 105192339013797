import markdownit from 'markdown-it';
import DOMPurify from 'dompurify';
import parse from 'html-react-parser';

export const renderText = (text: string | string[]) => {
    const mdi = markdownit({quotes: '„“‚‘'});
    let contentHTML = "";

    if (typeof text === 'string') {
        // Check if the string contains HTML tags
        if (/<\/?[a-z][\s\S]*>/i.test(text)) {
            contentHTML = text;
        } else {
            contentHTML = mdi.render(text);
        }
    } else if (Array.isArray(text)) {
        // Create a string from the array and add a new line for correct structure
        const mdFromArray = text.reduce((acc, cur) => {
            let val = acc + cur;
            if (cur === '' || !cur.endsWith('\n')) {
                val += '\n';
            }
            return val;
        }, '');
        contentHTML = mdi.render(mdFromArray);
    }

    // Sanitize to avoid XSS attacks
    const sanitizedHTML = DOMPurify.sanitize(contentHTML);

    // Parse the sanitized HTML to React elements
    return parse(sanitizedHTML);
};