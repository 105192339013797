import { Box, Typography } from "@mui/material";
import { FC } from "react";
import { BrandCellProps } from "./types";
import useCustomTableUtils from "@/hooks/useColumnsData/utils";

const BrandCell: FC<BrandCellProps> = ({ brand, isSubbrand = false, height = "100%" }) => {

  const { na } = useCustomTableUtils();

  return brand && brand.BRAND ? (
    <Box
      display="flex"
      justifyContent="flex-start"
      alignItems="center"
      sx={{ width: "100%", height: height }}
    >
      <Box
        display="flex"
        alignItems="center"
        sx={{
          mr: "8px",
        }}
      >
        {isSubbrand && brand.COLOR && (
          <Box
            sx={{
              width: 14,
              height: 6,
              backgroundColor: brand.COLOR,
              mr: "4px",
            }}
          />
        )}

        {!isSubbrand && (
          <Box
            sx={{
              width: 14,
              height: 6,
              backgroundColor: brand.COLOR ?? "#E0E0E0",
              mr: "4px",
            }}
          />
        )}

        <Typography fontWeight={400}>{brand.BRAND}</Typography>
      </Box>
    </Box>
  ) : (
    na
  );
};

export default BrandCell;
