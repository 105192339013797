import React, { useMemo } from "react";
import { Paper } from "@mui/material";
import { MuiGridTable } from "@/components/tables/DataGridTable";
import { selectorsConfig } from "./consts";
import { useColumns } from "@/hooks/useColumnsData";
import ContentContainer from "@/components/contentContainer";
import CustomGridTreeDataGroupingCell from "@/components/CustomGridTreeDataGroupingCell";
import { GridColDef, GridRenderEditCellParams } from "@mui/x-data-grid-pro";
import FiltersPanel from "@/components/FiltersPanel";
import useData from "@/hooks/auth/useData";
import useCustomTableUtils from "@/hooks/useColumnsData/utils";

export const VisibilityShareOfSearch = () => {
  const { getTranslatedHeaderName } = useCustomTableUtils();
  const { shareOfSearchApi } = useData();
  const columns = useColumns();

  const nodes = useMemo(() => {
    return shareOfSearchApi?.data || [];
  }, [shareOfSearchApi?.data]);

  const groupingSovColDef: GridColDef = {
    headerName: "",
    headerClassName: "grouping-header",
    field: "name",
    width: 5,
    renderCell: (params: GridRenderEditCellParams) => (
      <CustomGridTreeDataGroupingCell {...params} content={""} />
    ),
    valueGetter: (_: any, row: Record<string, any>) => {
      return row.type === "brand" ? row.name : "";
    },
  };

  const brandColDef: GridColDef = {
    field: "name",
    headerName: getTranslatedHeaderName("tableColumns.brand",  "Brand"),
    width: 141,
    valueGetter: (_: any, row: Record<string, any>) => {
      return row.type === "brand" ? row.name : "";
    },
  };

  const myDataGridColumns = [
    columns.shareColumn,
    brandColDef,
    columns.sovKeywordColumn,
    columns.sovProductColumn,
    columns.sovSkuColumn,
    columns.top3Column,
    columns.top10Column,
    columns.avgSerpColumn,
    columns.avgRatingsColumn,
  ];

  return (
    <ContentContainer>
      <FiltersPanel
        selectorsConfig={selectorsConfig}
        dependentAPIs={["shareOfSearchApi"]}
        hasDaysFilter={true}
      />
      <Paper>
        <MuiGridTable
          filterBarType={2}
          rows={nodes}
          columns={myDataGridColumns}
          loading={shareOfSearchApi?.isLoading}
          getTreeDataPath={(row) => row.hierarchy || []}
          treeData
          groupingColDef={groupingSovColDef}
          getRowClassName={(params) => {
            if (params.row.type === "keyword" || params.row.keyword) {
              return "gray-50-row";
            } else if (
              params.row.type === "product" &&
              !params.row.hierarchy.includes("keyword")
            ) {
              return "gray-100-row";
            }
            return "";
          }}
          isError={shareOfSearchApi.isError}
          error={shareOfSearchApi.error}
          hasCustomError={true}
        />
      </Paper>
    </ContentContainer>
  );
};
