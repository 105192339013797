import { t } from "i18next";
import { createMutationFunction } from "./mutations/createMutationFunction";
import { ApiMutation, ErrorCallback, useApiMutation } from "./useApiMutation";

export type AiOptimizerApiType = ApiMutation<AiOptimizerResponse, AiOptimizerParams>;

/**
 * Interface for AI Optimizer parameters
 */
export interface AiOptimizerParams {
  products: number[];
  merchants: number[];
  labels: number[];
  type: "description" | "teaser";
}

/**
 * Interface for AI Optimizer response
 */
export interface AiOptimizerResponse {
  success: boolean;
  optimizedContent?: string;
  message?: string;
}

/**
 * Custom error handling for AI Optimizer
 * @param {ErrorCallback<AiOptimizerResponse>} params - The error callback parameters
 */
const handleCustomError = ({
  error,
  setError,
  setData,
}: ErrorCallback<AiOptimizerResponse>) => {
  const errorMessage =
    error.response && error.response.status === 400
      ? t("aiOptimizer.optimizationError")
      : `${t("auth.globalError")}: ${error.message}`;

  setError(errorMessage);
  setData(undefined);
};

/**
 * Custom hook to handle the AI Optimizer mutation using react-query
 * @returns {ApiMutation<AiOptimizerResponse, AiOptimizerParams>} - The mutation state and execute function
 */
export const useAiOptimizer = () => {
  return useApiMutation<AiOptimizerResponse, AiOptimizerParams>({
    mutationFn: createMutationFunction<AiOptimizerResponse, AiOptimizerParams>("content/ai-optimizer"),
    onErrorCallback: handleCustomError,
  });
};
