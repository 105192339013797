import { Box, CircularProgress } from "@mui/material";

/**
 * `PreloaderBox` is a React component that renders a centered
 * loading indicator (`CircularProgress`) within a box (`Box` component).
 * The box takes up the full height of the viewport and centers the
 * loading indicator both horizontally and vertically.
 *
 **/
export const PreloaderBox = () => (
  <Box
    display="flex"
    justifyContent="center"
    alignItems="center"
    height="100vh"
  >
    <CircularProgress />
  </Box>
);
