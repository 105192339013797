import { createQueryFunction } from "../queries/createQueryFunction";
import { useApiQuery } from "../useApiQuery";
import { DefJsonResponse } from "./types";

const basePath = "bn-social/default_json.json"

export const useDefSocialJson = () => {
  const defSocialJsonApi = useApiQuery<DefJsonResponse>({
    key: "social-json",
    queryFn: createQueryFunction(basePath),
  });
  return defSocialJsonApi;
};