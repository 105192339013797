export const tabsOptions = ['setup', 'items', 'look', 'listing', 'pos'];

export const validationRules: {
  [key: string]: { pattern: RegExp; message: string };
} = {
  sku: { pattern: /^\d{8}$/, message: "PZN must be exactly 8 numeric digits." },
  width: { pattern: /^\d*$/, message: "Width must contain only digits." },
  height: { pattern: /^\d*$/, message: "Height must contain only digits." },
  url: {
    pattern:
      /^(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/,
    message: "Please enter a valid URL.",
  },
  color: {
    pattern: /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/,
    message: "Enter a valid hex color",
  },
};

export interface DefLocalConfig {
  posBranding: number;
  layoutOffline: number;
  price: boolean;
  ratings: boolean;
  stock: boolean;
  sorting: number[];
  sortingPreferred: number;
  label: {
    grouping: number;
  };
}

export const defLocalContentConfig: DefLocalConfig = {
  posBranding: 1,
  layoutOffline: 1,
  price: true,
  ratings: true,
  stock: true,
  sorting: [1],
  sortingPreferred: 2,
  label: {
    grouping: 1,
  },
};

export const defLocalSocialConfig: DefLocalConfig = {
  posBranding: 2,
  layoutOffline: 2,
  price: true,
  ratings: true,
  stock: true,
  sorting: [1],
  sortingPreferred: 2,
  label: {
    grouping: 1,
  },
};
