import { Manufacturer } from "./useManufacturers";
import { t } from "i18next";
import { createMutationFunction } from "./mutations/createMutationFunction";
import { ErrorCallback, useApiMutation } from "./useApiMutation";
import { useApiQuery } from "./useApiQuery";
import { createQueryFunction } from "./queries/createQueryFunction";
import {
  AnalyticsRequestBody,
  AnalyticsResponse,
  BrandPerformanceResponse,
  BrandsResponse,
  KpiBrandPerformanceResponse,
  KpiMerchantsLeadsResponse,
  KpiTransactionsResponse,
  MerchantLeadsResponse,
  MerchantsTransactionsResponse,
  ProductLeadsResponse,
  ProductVisitsResponse,
  ProductsResponse,
  UTMParams,
} from "@/pages/analytics/types";

export type BrandsApiType = ReturnType<typeof useAnalyticsQuery>["brandsApi"];
export type ProductsApiType = ReturnType<
  typeof useAnalyticsQuery
>["productsApi"];
export type UtmParamsApiType = ReturnType<
  typeof useAnalyticsQuery
>["utmParamsApi"];
export type AnalyticsGraphApiType = ReturnType<
  typeof useAnalyticsQuery
>["analyticsGraphApi"];
export type ProductLeadsApiType = ReturnType<
  typeof useAnalyticsQuery
>["productLeadsApi"];
export type ProductsVisitsApiType = ReturnType<
  typeof useAnalyticsQuery
>["productsVisitsApi"];
export type MerchantsLeadsApiType = ReturnType<
  typeof useAnalyticsQuery
>["merchantsLeadsApi"];
export type MerchantsTransactionsApiType = ReturnType<
  typeof useAnalyticsQuery
>["merchantsTransactionsApi"];
export type BrandPagePerformanceApiType = ReturnType<
  typeof useAnalyticsQuery
>["brandPagePerformanceApi"];
export type KpiTransactionsApiType = ReturnType<
  typeof useAnalyticsQuery
>["kpiTransactionsApi"];
export type KpiBrandPerformanceApiType = ReturnType<
  typeof useAnalyticsQuery
>["kpiBrandPerformanceApi"];
export type KpiMerchantsLeadsApiType = ReturnType<
  typeof useAnalyticsQuery
>["kpiMerchantsLeadsApi"];

const handleError = <T>({ error, setError, setData }: ErrorCallback<T>) => {
  const errorMessage =
    error.response && error.response.status === 400
      ? t("errors.fetchError")
      : `${t("errors.globalError")}: ${error.message}`;

  setError(errorMessage);
  setData(undefined);
};

export const useAnalyticsQuery = () => {
  const brandsApi = useApiQuery<BrandsResponse[], { m: number[] }>({
    key: "brands",
    queryFn: createQueryFunction(`brands`),
  });
  const productsApi = useApiQuery<
    ProductsResponse[],
    {
      m: number[]
      //search: string[];
      b?: number[];
      sb?: number[];
      f?: number[];
    }
  >({
    key: "products",
    queryFn: createQueryFunction("products"),
  });
  const utmParamsApi = useApiQuery<UTMParams, { m: number[] }>({
    key: "utmParams",
    queryFn: createQueryFunction("promotions/utm_params"),
  });
  const analyticsGraphApi = useApiMutation<
    AnalyticsResponse,
    AnalyticsRequestBody
  >({
    mutationFn: createMutationFunction<AnalyticsResponse, AnalyticsRequestBody>(
      "promotions/visits_leads_conversions_graph"
    ),
    onErrorCallback: handleError,
  });

  const brandPagePerformanceApi = useApiMutation<
    BrandPerformanceResponse[],
    { manufacturers: number[] }
  >({
    mutationFn: createMutationFunction<
      BrandPerformanceResponse[],
      { manufacturers: number[] }
    >("promotions/brand_page_performance"),
    onErrorCallback: handleError,
  });
  const productLeadsApi = useApiMutation<
    ProductLeadsResponse[],
    AnalyticsRequestBody
  >({
    mutationFn: createMutationFunction<
      ProductLeadsResponse[],
      AnalyticsRequestBody
    >("promotions/product_leads_performance"),
    onErrorCallback: handleError,
  });

  const merchantsLeadsApi = useApiMutation<
    MerchantLeadsResponse[],
    AnalyticsRequestBody
  >({
    mutationFn: createMutationFunction<
      MerchantLeadsResponse[],
      AnalyticsRequestBody
    >("promotions/merchants_leads"),
    onErrorCallback: handleError,
  });

  const merchantsTransactionsApi = useApiMutation<
    MerchantsTransactionsResponse[],
    AnalyticsRequestBody
  >({
    mutationFn: createMutationFunction<
      MerchantsTransactionsResponse[],
      AnalyticsRequestBody
    >("promotions/merchants_transactions"),
    onErrorCallback: handleError,
  });

  const productsVisitsApi = useApiMutation<
    ProductVisitsResponse[],
    AnalyticsRequestBody
  >({
    mutationFn: createMutationFunction<
      ProductVisitsResponse[],
      AnalyticsRequestBody
    >("promotions/products_visits"),
    onErrorCallback: handleError,
  });
  const kpiTransactionsApi = useApiMutation<
    KpiTransactionsResponse,
    AnalyticsRequestBody
  >({
    mutationFn: createMutationFunction<
      KpiTransactionsResponse,
      AnalyticsRequestBody
    >("promotions/kpi_transactions"),
    onErrorCallback: handleError,
  });
  const kpiBrandPerformanceApi = useApiMutation<
    KpiBrandPerformanceResponse,
    AnalyticsRequestBody
  >({
    mutationFn: createMutationFunction<
      KpiBrandPerformanceResponse,
      AnalyticsRequestBody
    >("promotions/kpi_brand_page_performance"),
    onErrorCallback: handleError,
  });
  const kpiMerchantsLeadsApi = useApiMutation<
    KpiMerchantsLeadsResponse,
    AnalyticsRequestBody
  >({
    mutationFn: createMutationFunction<
      KpiMerchantsLeadsResponse,
      AnalyticsRequestBody
    >("promotions/kpi_merchants_leads"),
    onErrorCallback: handleError,
  });

  return {
    brandsApi,
    productsApi,
    utmParamsApi,
    analyticsGraphApi,
    productLeadsApi,
    productsVisitsApi,
    merchantsLeadsApi,
    merchantsTransactionsApi,
    brandPagePerformanceApi,
    kpiTransactionsApi,
    kpiBrandPerformanceApi,
    kpiMerchantsLeadsApi,
  };
};
