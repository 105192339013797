import { createMutationFunction } from "../mutations/createMutationFunction";
import { useApiMutation } from "../useApiMutation";
import { AvailabilityTableApiType, ProductAvailabilityResponse } from "./types";

export const useAvailabilityTable = (): AvailabilityTableApiType=> {
  const availabilityTableApi = useApiMutation<ProductAvailabilityResponse[]>({
    mutationFn: (variables, signal) =>
      createMutationFunction<ProductAvailabilityResponse[]>(
        "availability/product_availability"
      )(variables, signal),
  });
  return availabilityTableApi;
};
