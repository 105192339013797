import { FC, ReactNode, useState } from "react";
import AnalyticsTable, { HeadCell, RowType } from "./Table";
import {
  Box,
  Grid,
  SxProps,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import InfoSvg from "@/assets/img/info.svg";
import FilterSvg from "@/assets/img/filter.svg";

interface KpiListSectionProps {
  title: string;
  tooltip: string | ReactNode;
  headCells: HeadCell[];
  rows: RowType[];
  initialSortBy: string;
  progressBar: string;
  isLoading: boolean;
  sx?: SxProps;
  filterColumn: string;
  xs?: number;
  sm?: number;
  md?: number;
  lg?: number;
  xl?: number;
  isError: boolean;
  error: string | null;
}

export const KpiListSection: FC<KpiListSectionProps> = ({
  title,
  tooltip,
  headCells,
  rows,
  initialSortBy,
  progressBar,
  isLoading,
  sx,
  filterColumn,
  xs,
  sm,
  md,
  lg,
  xl,
  isError,
  error,
}) => {
  const [filterOpen, setFilterOpen] = useState<boolean>(false);
  const theme = useTheme();

  return (
    <Grid
      item
      xs={xs}
      sm={sm}
      md={md}
      lg={lg}
      xl={xl}
      sx={{
        position: "relative",
        ...sx,
      }}
    >
      <Typography
        variant="h6"
        sx={{
          backgroundColor: theme.palette.background.paper,
          pt: "16px",
          pl: "8px",
        }}
      >
        {title}
      </Typography>
      <Tooltip
        title={tooltip}
        style={{
          cursor: "pointer",
          position: "absolute",
          top: "16px",
          right: "16px",
        }}
        placement="top"
      >
        <img src={InfoSvg} alt="info" />
      </Tooltip>
      <Box
        sx={{
          position: "absolute",
          top: "46px",
          right: "16px",
          cursor: "pointer",
          zIndex: 10,
        }}
        onMouseDown={(e) => {
          e.stopPropagation();
          setFilterOpen((prev) => !prev);
        }}
      >
        <img src={FilterSvg} alt="filter" width={18} height={18} />
      </Box>

      <AnalyticsTable
        headCells={headCells}
        rows={rows}
        initialSortBy={initialSortBy}
        progressBar={progressBar}
        itemCount={8}
        simplePagination
        isLoading={isLoading}
        filterOpen={filterOpen}
        filterColumn={filterColumn}
        setFilterOpen={setFilterOpen}
        isError={isError}
        error={error}
      />
    </Grid>
  );
};
